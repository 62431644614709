import * as React from "react";
import { FaTrashAlt } from "react-icons/fa";
import uuid from "uuid";
import shortid from "shortid";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../../../mobx/components";
import { SettingsWebhookForm } from "./webhooks.form";
import {UI} from "../../../../../../../core/ui";
import { cloneDeepSafe } from "@lib/common";
import { Button, RotateLoader, LinkTag, ItemListItem, ItemListContent, ItemListWrapper, Tag, ConnectionIndicatorCircle, Tooltip, Modal, ModalContent } from "@lib/components";

interface Props {}
interface State {
  panel_id: string | null;
  copy_index: number | null;
  copy_loading: boolean;
  remove_index: number | null;
  remove_loading: boolean;
}

@inject("store") @observer
export class SettingsWebhooks extends MobxComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      panel_id: null,
      copy_index: null,
      copy_loading: false,
      remove_index: null,
      remove_loading: false,
    };
  }

  setPanelID = (panel_id: string | null) => {
    this.setState({ panel_id });
  }
  create = (item: T.Models.Restaurant.Webhooks.Schema | null): T.Models.Restaurant.Webhooks.Schema => {
    if (item === null) {
      const _id = shortid.generate();
      return {
        _id: _id,
        secret: uuid.v4(),
        url: "",
        enabled: true,
        events: {
          order_new: true,
          order_update_status: true,
          order_update_ready_time: true,
          booking_new: true,
          booking_update_status: true,
        },
      };
    }
    return item;
  }
  copy = async (index: number | null) => {
    if (index === null) return;
    await this.saveRestaurant({
      successMsg: "Webhook copied",
      process: (r) => {
        const webhook = cloneDeepSafe(r.webhooks![index]);
        webhook._id = shortid.generate();
        webhook.secret = uuid.v4();
        r.webhooks!.push(webhook);
        return {
          update: {
            $set: {
              "webhooks": r.webhooks,
            },
          },
        };
      },
      before: () => this.setState({ copy_loading: true }),
      onSuccess: () => this.setState({ copy_loading: false, copy_index: null }),
      onFail: () => this.setState({ copy_loading: false }),
      onError: () => this.setState({ copy_loading: false }),
    });
  }
  remove = async (index: number | null) => {
    if (index === null) return;
    await this.saveRestaurant({
      successMsg: "Webhook deleted",
      process: (r) => {
        r.webhooks!.splice(index, 1);
        return {
          update: { $set: { "webhooks": r.webhooks } },
        };
      },
      before: () => this.setState({ remove_loading: true }),
      onSuccess: () => this.setState({ remove_loading: false, remove_index: null }),
      onFail: () => this.setState({ remove_loading: false }),
      onError: () => this.setState({ remove_loading: false }),
    });
  }

  createButton = () => {
    const r = this.injected.store.restaurant!;
    const item_id = this.state.panel_id;
    const active = item_id !== null;

    if (r.webhooks && r.webhooks.length > 1) {
      UI.notification.error("Maximum of 2 webhooks");
      return;
    }

    if (active) {
      this.setState({ panel_id: null });
    }
    else {
      this.setState({ panel_id: "" });
    }
  }

  list = () => {

    const r = this.injected.store.restaurant!;

    if (!r.webhooks || r.webhooks.length === 0)
      return null;

    return (
      <div className="m-t-4">
        <ItemListWrapper>
          <ItemListContent className="border-white-10">
            {r.webhooks.map((item, i) => (
              <ItemListItem key={i} onClick={() => this.setPanelID(item._id)}>
                <div className="p-lr-4 p-tb-2 flex-line centered">
                  <ConnectionIndicatorCircle status={item.enabled ? "connected" : "disconnected"}/>
                  <div className="m-l-3">
                    <p className="big font-semi-bold m-t-1 m-b-2">{item.url}</p>
                    <p className="m-b-1">
                      Secret Key:
                      <Tag className="cursor-text m-l-1" onClick={(e) => {
                        e.stopPropagation();
                        const s = window.getSelection();
                        if (s) {
                          s.selectAllChildren( e.currentTarget );
                        }
                      }}>
                        {item.secret}
                      </Tag>
                    </p>
                  </div>
                </div>
                <div>
                  <Tooltip text="Delete" width={65} position="top">
                    <Button
                      type="button"
                      color="transparent"
                      className="no-shadow"
                      paddinglr={20}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ remove_index: i });
                      }}>
                      <FaTrashAlt/>
                    </Button>
                  </Tooltip>
                </div>
              </ItemListItem>
            ))}
          </ItemListContent>
        </ItemListWrapper>
      </div>
    );

  }
  panel = () => {
    const r = this.injected.store.restaurant!;
    const item_id = this.state.panel_id;
    const active = item_id !== null;

    let initialValues = null;
    if (active) {
      if (item_id === "") {
        initialValues = this.create(null);
      }
      else {
        const item = (r.webhooks || []).find((p) => p._id === item_id);
        initialValues = this.create(item || null);
      }
    }

    return (
      <SettingsWebhookForm
        type={item_id === "" ? "create" : "edit"}
        initialValues={initialValues}
        close={() => this.setPanelID(null)}
      />
    );

  }
  modal_copy = () => {
    const r = this.injected.store.restaurant!;
    const { copy_index, copy_loading } = this.state;
    const item = copy_index !== null ? (r.webhooks || [])[copy_index] : null;
    return (
      <Modal
        width="sm"
        close={() => this.setState({copy_index: null})}
        closeButton={false}
        active={!!item}>
        <ModalContent className="flex-l-r-center">
          <h4 className="">Copy Webhook</h4>
          <p className="big underline">{item && item.url}</p>
        </ModalContent>
        <ModalContent className="flex-right">
          <Button type="button" className="m-r-2 width100 max100px" onClick={() => this.setState({copy_index: null})}>Cancel</Button>
          <Button type="button" className="width100 max100px" color="primary" onClick={() => this.copy(copy_index)}>
            {copy_loading && <RotateLoader size={2} color="white"/>}
            {!copy_loading && "Copy"}
          </Button>
        </ModalContent>
      </Modal>
    );
  }
  modal_delete = () => {
    const r = this.injected.store.restaurant!;
    const { remove_index, remove_loading } = this.state;
    const item = remove_index !== null ? (r.webhooks || [])[remove_index] : null;
    return (
      <Modal
        width="sm"
        close={() => this.setState({remove_index: null})}
        closeButton={false}
        active={remove_index !== null}>
        <ModalContent className="flex-l-r-center">
          <h4 className="">Delete Webhook</h4>
          <p className="big underline">{item && item.url}</p>
        </ModalContent>
        <ModalContent>
          <p className="lhp big">Are you sure you want to delete this webhook. Once deleted, it cannot be restored</p>
        </ModalContent>
        <ModalContent className="flex-right">
          <Button type="button" className="m-r-2 width100 max100px" onClick={() => this.setState({remove_index: null})}>Cancel</Button>
          <Button type="button" className="width100 max100px" color="primary" onClick={() => this.remove(remove_index)}>
            {remove_loading && <RotateLoader size={2} color="white"/>}
            {!remove_loading && "Delete"}
          </Button>
        </ModalContent>
      </Modal>
    );
  }

  render() {
    return (
      <div className="p-4">
        <Button
          color="primary"
          size="xs"
          onClick={this.createButton}>
          Create Webhook
        </Button>
        {this.list()}
        <p className="block m-t-4 small1">Limit of 2 webhooks per restaurant</p>
        {this.panel()}
        {this.modal_copy()}
        {this.modal_delete()}
      </div>
    );
  }

}
