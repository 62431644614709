import * as React from "react";
import { FastField } from "formik";
import { FormGroup, Button, RotateLoader, Input } from "@lib/components";
import {MobxComponent} from "../../../../../../mobx/components";
import {inject, observer} from "mobx-react";
import {WebsiteForm} from "../../../../../../mobx/components/website-form";
import {UI} from "../../../../../../core/ui";

interface Props {}
interface State {}
type FormValues = T.Models.Website.Schema["seo"];

@inject("store") @observer
export class WebsiteFormSEO extends MobxComponent<Props, State> {

  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = { error: null };
    const w = this.injected.store.website!;
    this.initialValues = w.seo;
  }

  render() {

    return (
      <WebsiteForm<FormValues>
        submit={async (w, values) => {
          w.seo = values;
          const update = { $set: { "seo": w.seo } };
          return { w, update };
        }}
        validators={{}}
        initialValues={this.initialValues}
        onSuccess={() => {}}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
          const { isSubmitting, setFieldValue, setFieldTouched } = form;
          return (
            <div className="p-4">

              <FastField
                name="title"
                render={({ field }: any) => (
                  <FormGroup
                    title="Website Title"
                    help="The document title for your website">
                    <Input {...field}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="meta"
                render={({ field }: any) => (
                  <FormGroup
                    title="Website Meta Description"
                    help="The page description / meta description for your website">
                    <Input {...field}/>
                  </FormGroup>
                )}
              />

              {error && <FormGroup error={error}/>}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white"/>}
                {!isSubmitting && "Save"}
              </Button>

            </div>
          );
        }}
      </WebsiteForm>
    );

  }

}
