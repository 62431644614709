import * as React from "react";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../mobx/components";
import { Input, Button, BoxSection } from "@lib/components";
import {UI} from "../../../../core/ui";
import {Untrusive} from "../../../../core/libs/untrusive";

interface Props {}
interface State {
  name: string;
}

@inject("store") @observer
export class AuthSSO extends MobxComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
    };
  }

  submit = async (e: any) => {
    e.preventDefault();
    try {

      const { router } = this.injected.store;
      const { name } = this.state;

      if (!name) {
        UI.notification.error("Please enter a display name");
        return;
      }

      const { returnUrl } = router.s.query;

      window.location.href = `https://cloudwaitress.nolt.io/${""}?returnUrl=${returnUrl}`;

    }
    catch (e) {
      Untrusive.stop();
      UI.notification.error("Something went wrong, try again or contact us");
    }
  }

  render() {
    return (
      <div className="flex-center" style={{ minHeight: "80vh" }}>
        <BoxSection className="p-2" style={{ minWidth: "300px" }}>
          <form onSubmit={this.submit} className="text-center" style={{ display: "flex", flexDirection: "column" }}>
            <h3 className="m-b-6">Login To External Application</h3>
            <Input
              type="text"
              placeholder="Display Name"
              required={true}
              style={{ width: "360px" }}
              className="text-center m-b-4 block center"
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
            />
            <Button color="primary" style={{ width: "140px" }} className="block center">Proceed</Button>
          </form>
        </BoxSection>
      </div>
    );
  }

}
