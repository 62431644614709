import * as React from "react";
import { Link } from "../../../components/router/Link";
import { AuthLayout } from "../layout";
import { FormGroup, FormGroupIcon, BoxSection, BoxHeading, Button, Input, Checkbox, LinkTag } from "@lib/components";
import { UI } from "../../../../core/ui";
import { logger, validators } from "@lib/common";
import {inject, observer} from "mobx-react";
import { MobxComponent } from "../../../../mobx/components";
import { FaEnvelope, FaLock } from "react-icons/fa";

interface Props {}
interface State {
  email: string;
  password: string;
  confirm_password: string;
  accept_terms: boolean;
  loading: boolean;
  email_confirmation_code: string;
  email_confirmation_visible: boolean;
}

const GENERIC_ERROR = "Something went wrong, try again or contact us";

@inject("store") @observer
export class AuthRegister extends MobxComponent<Props, State> {

  last_sent_confirmation: number | null;

  constructor(props: Props) {
    super(props);
    this.state = this.initial_state();
    this.last_sent_confirmation = null;
  }

  initial_state = () => {
    return {
      email: "",
      password: "",
      confirm_password: "",
      accept_terms: true, // IF TERMS EXISTS SET ACCEPT TO FALSE SO THEY MUST ACCEPT
      loading: false,
      email_confirmation_code: "",
      email_confirmation_visible: false,
    };
  }
  reset_state = () => {
    this.setState(this.initial_state());
  }

  load = (v: boolean) => {
    this.setState({ loading: v });
  }

  register_commence = async (e: React.FormEvent<HTMLFormElement>) => {

    const { store } = this.injected;
    // SET VARS
    e.preventDefault();
    const { email, password, confirm_password, accept_terms } = this.state;

    // CHECK FOR ERRORS
    if (!email || !password || !confirm_password) {
      return UI.notification.error("Please fill out all the fields");
    }
    else if (!accept_terms) {
      return UI.notification.error("Please accept the terms & conditions");
    }
    else if (validators.password(password).error) {
      return UI.notification.error("Minimum password length is 5 characters");
    }
    else if (password !== confirm_password) {
      return UI.notification.error("Passwords do not match");
    }

    try {

      // API CALL
      this.load(true);
      const data = await store.api.user_register_commence({ email });

      // CHECK ERRORS
      if (data.outcome) {
        this.load(false);
        return UI.notification.error(data.message);
      }

      this.setState({ loading: false, email_confirmation_visible: true });

    }
    catch (e) {
      logger.captureException(e);
      UI.notification.error(GENERIC_ERROR, { timeout: 6000 });
      this.setState({ loading: false });
    }

    return null;

  }
  register_complete = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { store } = this.injected;
    const { email, password, email_confirmation_code } = this.state;
    if (!email_confirmation_code) {
      return UI.notification.error("Please enter the confirmation code received via e-mail", { timeout: 6000 });
    }
    try {
      this.load(true);
      const data = await store.api.user_register_complete({ email_confirmation_code, email, password });
      this.load(false);
      if (data.outcome) {
        UI.notification.error(data.message);
      }
      else {
        await store.service.login(data.token, "/");
      }
    }
    catch (e) {
      logger.captureException(e);
      UI.notification.error(GENERIC_ERROR, { timeout: 6000 });
      this.load(false);
    }
    return null;
  }
  resend_email_confirmation = async () => {
    const { store } = this.injected;
    try {
      const time_elapsed = Date.now() - (this.last_sent_confirmation || 0);
      if (time_elapsed < (1000 * 10)) {
        const seconds_left = Math.round( (time_elapsed - (1000 * 10)) / 1000 );
        return UI.notification.error(`Please wait ${-seconds_left} seconds before re-sending`);
      }

      this.load(true);
      const data = await store.api.user_register_commence({
        email: this.state.email,
      });
      if (data.outcome) {
        UI.notification.error(data.message);
      }
      else {
        UI.notification.success(`Confirmation code re-sent to ${this.state.email}`);
        this.last_sent_confirmation = Date.now();
      }
      this.load(false);
    }
    catch (e) {
      logger.captureException(e);
      UI.notification.error(GENERIC_ERROR, { timeout: 6000 });
      this.load(false);
    }
    return null;
  }

  onChange = <T extends keyof State>(e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = UI.helpers.handleChange(e);
    this.setState(newState as { [P in T]: State[P]; });
  }

  render_commence = () => {
    const { onChange } = this;
    return (
      <div>

        <BoxHeading className="text-center">
          <h3>Register</h3>
        </BoxHeading>

        <form onSubmit={this.register_commence}>

          <BoxSection>

            <FormGroupIcon icon={<FaEnvelope/>} no_border={true}>
              <Input
                type="email"
                name="email"
                placeholder="E-Mail"
                value={this.state.email}
                onChange={onChange}
                required={true}
              />
            </FormGroupIcon>

            <FormGroupIcon icon={<FaLock/>} no_border={true}>
              <Input
                type="password"
                name="password"
                placeholder="Password"
                value={this.state.password}
                onChange={onChange}
                required={true}
              />
            </FormGroupIcon>

            <FormGroupIcon icon={<FaLock/>}no_border={true}>
              <Input
                type="password"
                name="confirm_password"
                placeholder="Confirm Password"
                value={this.state.confirm_password}
                onChange={onChange}
                required={true}
              />
            </FormGroupIcon>

            <FormGroup
              no_border={true}
              className="flex-center"
              hide={true}>
              <Checkbox
                id="accept-terms"
                name="accept_terms"
                checked={this.state.accept_terms}
                onChange={onChange}
                label={<p className="inline-block">Accept Terms & Conditions - <LinkTag target="_blank" href="">View</LinkTag></p>}/>
            </FormGroup>

            <Button color="primary" type="submit" full={true}>
              Continue
            </Button>

          </BoxSection>

          <BoxSection className="text-center">
            <p className="m-b-2">Already have an account? <Link to="/login">Login</Link></p>
          </BoxSection>

        </form>

      </div>
    );
  }
  render_complete = () => {
    return (
      <div>

        <BoxHeading className="text-center">
          <h3>Confirm E-Mail</h3>
        </BoxHeading>

        <form onSubmit={this.register_complete}>

          <BoxSection>

            <FormGroup className="text-center" no_border={true}>
              <p className="lhp">A 5-6 digit code was sent to your e-mail.<br/>Enter it below to proceed</p>
            </FormGroup>

            <FormGroupIcon icon={<FaEnvelope/>} no_border={true}>
              <Input
                type="text"
                name="email_confirmation_code"
                placeholder="E-Mail Confirmation Code"
                value={this.state.email_confirmation_code}
                onChange={this.onChange}
                required={true}
              />
            </FormGroupIcon>

            <Button color="primary" type="submit" full={true}>
              Complete Registration
            </Button>

          </BoxSection>

          <BoxSection className="text-center">
            <p className="">
              Didn't receive the code?<br/>
              <LinkTag onClick={this.resend_email_confirmation}>Resend e-mail confirmation code</LinkTag> | <LinkTag className="link" onClick={this.reset_state}>Start again</LinkTag>
            </p>
          </BoxSection>

        </form>

      </div>
    );
  }

  render() {
    const { email_confirmation_visible } = this.state;
    return (
      <AuthLayout loading={this.state.loading}>
        {email_confirmation_visible ? this.render_complete() : this.render_commence()}
      </AuthLayout>
    );
  }

}
