import * as React from "react";
import {FastField} from "formik";
import { FormGroup, Input, Button, RotateLoader, Switch, Modal, ModalContent, TabSelect, FieldOptionSetOptions, FormTreeSelect } from "@lib/components";
import shortid from "shortid";
import {MobxComponent} from "../../../../../../mobx/components";
import {inject, observer} from "mobx-react";
import {RestaurantForm} from "../../../../../../mobx/components/restaurant-form";
import {RestaurantUtils} from "@lib/common";

type FormValues = T.Models.Restaurant.Menus.OptionSet;
interface Props {
  type: "edit" | "create";
  initialValues: FormValues | null;
  close: () => void;
}
interface State {
  tab: string;
  add_to: string[];
}

@inject("store") @observer
export class RestaurantFormOptionSet extends MobxComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      tab: "0",
      add_to: [],
    };
  }

  setTab = (tab: string) => {
    this.setState({ tab });
  }

  dishes_in = (id: string) => {
    const r = this.injected.store.restaurant!;
    return RestaurantUtils.menu
      .filterDishes(r, (dish) => (dish.type !== "combo" && dish.option_sets.indexOf(id) !== -1))
      .map((dish) => dish._id);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.initialValues && !prevProps.initialValues) {
      this.setTab("0");
      this.setState({
        add_to: this.dishes_in(this.props.initialValues._id),
      });
    }
  }

  render() {
    const { type, initialValues, close } = this.props;
    const { tab, add_to } = this.state;
    const restaurant = this.injected.store.restaurant!;

    return (
      <Modal
        width="md"
        alignTop={true}
        active={!!initialValues}
        close={close}>

        <ModalContent>
          <h3 className="m-b-1">{type === "create" ? "Create Option Set" : "Edit Option Set"}</h3>
          {(type === "edit" && initialValues) && <p>{initialValues.name}</p>}
        </ModalContent>

        <TabSelect
          id="option-set-tab-options"
          className="border-white-tb-15"
          hasBorder={true}
          onChange={(v) => this.setTab(v.value)}
          screenWidth={this.injected.store.view.screen_width}
          value={tab}
          values={[
            { label: "General", value: "0" },
            { label: "Options", value: "1" },
            { label: "Conditions", value: "2" },
            { label: "Add / Remove From Dishes", value: "3" },
          ]}/>

        <RestaurantForm<FormValues>
          submit={async (r, values) => {

            const existing = r.option_sets.findIndex((p) => p._id === values._id);

            if (existing === -1) {
              r.option_sets.push(values);
            }
            else {
              r.option_sets[existing] = values;
            }

            for (const [i, m] of r.menus.entries()) {
              for (const [k, c] of m.categories.entries()) {
                for (const [z, d] of c.dishes.entries()) {
                  if (add_to.indexOf(d._id) === -1) {
                    const index = d.option_sets.indexOf(values._id);
                    if (index !== -1) {
                      r.menus[i].categories[k].dishes[z].option_sets.splice(index, 1);
                    }
                  }
                  else {
                    const index = d.option_sets.indexOf(values._id);
                    if (index === -1) {
                      r.menus[i].categories[k].dishes[z].option_sets.push(values._id);
                    }
                  }
                }
              }
            }

            const update = {
              $set: {
                menus: r.menus,
                option_sets: r.option_sets,
              },
            };

            return { r, update };
          }}
          validators={{
            name: (values) => {
              if (!values.name)
                return { name: "This field is required" };
            },
          }}
          initialValues={initialValues}
          onSuccess={close}
          onSuccessMessage={type === "edit" ? "Option set edited" : "Option set created"}
          onErrorMessage="">
          {({ form, error, getFieldError }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
              <div>

                {tab === "0" && (
                  <ModalContent>
                    <FastField
                      name="name"
                      render={({ field }: any) => (
                        <FormGroup
                          title="Name"
                          help="A unique name for your option set"
                          error={getFieldError(form, "name")}>
                          <Input type="text" {...field} required={true}/>
                        </FormGroup>
                      )}
                    />
                    <FastField
                      name="display_name"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Display Name"
                          help="Will override the unique name in your store"
                          error={getFieldError(form, "display_name")}>
                          <Input type="text" {...field}/>
                        </FormGroup>
                      )}
                    />
                    <FastField
                      name="show_in_menu"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Show In Menu"
                          help="If enabled, the option set will display itself your on your menu list. If disabled, it will only show in the dish popup">
                          <Switch
                            id="show_in_menu-switch"
                            checked={field.value}
                            onChange={(e) => setFieldValue("show_in_menu", e.target.checked)}/>
                        </FormGroup>
                      )}
                    />
                    <FastField
                      name="inc_price_free_qty_promo"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Inc. Price in Free Quantity Promos"
                          help="By default, option set prices are not counted towards promos such as buy 1 get 1 free. For example, if the base dish price is $10 but the customer added an option costing $5 extra, total $15, if they buy 2 units, the second will be discounted for the base cost of $10. If this setting is enabled, the discount would be valid for up to $15">
                          <Switch
                            id="inc_price_free_qty_promo-switch"
                            checked={field.value || false}
                            onChange={(e) => setFieldValue("inc_price_free_qty_promo", e.target.checked)}/>
                        </FormGroup>
                      )}
                    />
                  </ModalContent>
                )}

                {tab === "1" && (
                  <ModalContent>
                    <FastField
                      name="options"
                      render={({ field }: any) => (
                        <FormGroup
                          help="Define a list of options for this option set"
                          error={getFieldError(form, "options")}>

                          <Button type="button" color="primary-inverse" size="xs" onClick={() => {
                            setFieldValue("options", [ ...field.value, {
                              _id: shortid.generate(),
                              name: "",
                              price: "",
                              quantity: 0,
                            } as T.Models.Restaurant.Menus.OptionSetOption]);
                          }}>
                            Add Option
                          </Button>

                          {field.value.length !== 0 && <div className="m-tb-3"/>}

                          { field.value.length !== 0 && (
                            <FieldOptionSetOptions
                              values={field.value}
                              onChange={(options) => setFieldValue("options", options)}
                              defaultEnabled={form.values.conditions.required && !form.values.conditions.multi_select}
                            />
                          )}

                        </FormGroup>
                      )}
                    />
                  </ModalContent>
                )}

                {tab === "2" && (
                  <ModalContent>
                    <FastField
                      name="conditions.required"
                      render={({ field }: any) => (
                        <FormGroup
                          title="Required"
                          help="If enabled, a customer must make a choice from this option set">
                          <Switch
                            id="conditions.required-switch"
                            checked={field.value}
                            onChange={(e) => {
                              if (!e.target.checked) {
                                setFieldValue("options", form.values.options.map((option) => ({
                                  ...option,
                                  quantity: 0,
                                })));
                              }
                              setFieldValue("conditions.required", e.target.checked);
                            }}/>
                        </FormGroup>
                      )}
                    />
                    <FastField
                      name="conditions.multi_select"
                      render={({ field }: any) => (
                        <FormGroup
                          title="Select Multiple"
                          help="If enabled, a customer can select multiple options">
                          <Switch
                            id="conditions.multi_select-switch"
                            checked={field.value}
                            onChange={(e) => setFieldValue("conditions.multi_select", e.target.checked)}/>
                        </FormGroup>
                      )}
                    />
                    <FastField
                      name="conditions.quantity_select"
                      render={({ field }: any) => (
                        <FormGroup
                          title="Enable Option Quantity"
                          help="If enabled, customers can select the quantity of a particular option">
                          <Switch
                            id="conditions.quantity_select-switch"
                            checked={field.value}
                            onChange={(e) => setFieldValue("conditions.quantity_select", e.target.checked)}/>
                        </FormGroup>
                      )}
                    />

                    <FastField
                      name="conditions.min_options"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Min Options Required"
                          help="The minimum number of options that must be selected. Minimum is 1">
                          <Input type="number" step={1} min={0} {...field}/>
                        </FormGroup>
                      )}/>
                    <FastField
                      name="conditions.max_options"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Max Options Allowed"
                          help="The maximum number of options that can be selected. Leave empty for no limit">
                          <Input type="number" step={1} min={0} {...field}/>
                        </FormGroup>
                      )}/>
                    <FastField
                      name="conditions.free_amount"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Free Quantity"
                          help="The number of options that can be selected for free. NOTE: If enabled, ensure all your options are the same price otherwise the free options will be credited top to bottom">
                          <Input type="number" step={1} min={0} {...field}/>
                        </FormGroup>
                      )}/>
                  </ModalContent>
                )}

                {tab === "3" && (
                  <ModalContent>
                    <FormGroup help="Easily add or remove this option set to/from your dishes">
                      <FormTreeSelect
                        id={`add-to-dishes`}
                        selected={add_to}
                        nodes={RestaurantUtils.menu.getTreeFilter(restaurant, (d) => d.type !== "combo")}
                        onChange={(add_to_dishes) => {
                          this.setState({ add_to: add_to_dishes });
                        }}
                      />
                    </FormGroup>
                  </ModalContent>
                )}

                <ModalContent>
                  {error && <FormGroup error={error}/>}
                  <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white"/>}
                    {!isSubmitting && "Save"}
                  </Button>
                </ModalContent>

              </div>
            );
          }}
        </RestaurantForm>

      </Modal>
    );

  }

}
