import * as React from "react";
import {FastField} from "formik";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../../mobx/components";
import { Modal, ModalContent, FormGroup, Input, Button, RotateLoader } from "@lib/components";
import {RestaurantForm} from "../../../../../../mobx/components/restaurant-form";

type FormValues = T.Models.Restaurant.Menus.Category;

interface Props {
  type: "edit" | "create";
  menuIndex: number;
  initialValues: FormValues | null;
  close: () => void;
}
interface State {}

@inject("store") @observer
export class RestaurantFormCategory extends MobxComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { type, initialValues, close } = this.props;
    return (
      <Modal
        width="sm"
        alignTop={true}
        active={!!initialValues}
        close={close}>
        <ModalContent>
          <h3 className="">{type === "create" ? "Create Category" : "Edit Category"}</h3>
          {(type === "edit" && initialValues) && <p className="m-t-1">{initialValues.name}</p>}
        </ModalContent>
        <RestaurantForm<FormValues>
          submit={async (r, values) => {
            const { menuIndex } = this.props;
            const existing = r.menus[menuIndex].categories.findIndex((p) => p._id === values._id);
            if (existing === -1) {
              r.menus[menuIndex].categories.push(values);
            }
            else {
              r.menus[menuIndex].categories[existing] = values;
            }
            const update = { $set: { menus: r.menus } };
            return { r, update };
          }}
          validators={{
            name: (values) => {
              if (!values.name)
                return { name: "This field is required" };
            },
          }}
          initialValues={initialValues}
          onSuccess={close}
          onSuccessMessage={type === "edit" ? "Category edited" : "Category created"}
          onErrorMessage="">
          {({ form, error, getFieldError }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
              <div>
                <ModalContent>
                  <FastField
                    name="name"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Name"
                        help="A unique name for your category"
                        error={getFieldError(form, "name")}>
                        <Input type="text" {...field} required={true}/>
                      </FormGroup>
                    )}
                  />
                  <FastField
                    name="display_name"
                    render={({ field }: any) => (
                      <FormGroup
                        optional={true}
                        title="Display Name"
                        help="Will override the unique name in your store"
                        error={getFieldError(form, "display_name")}>
                        <Input type="text" {...field}/>
                      </FormGroup>
                    )}
                  />
                </ModalContent>
                <ModalContent>
                  {error && <FormGroup error={error}/>}
                  <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white"/>}
                    {!isSubmitting && "Save"}
                  </Button>
                </ModalContent>
              </div>
            );
          }}
        </RestaurantForm>
      </Modal>
    );

  }

}
