import * as React from "react";
import {FastField} from "formik";
import { FormGroup, Input, Button, RotateLoader, DishTag, RestaurantIconSelect, TabSelect, FormTreeSelect, Modal, ModalContent, ButtonGroup, ColorPicker } from "@lib/components";
import {MobxComponent} from "../../../../../../mobx/components";
import {inject, observer} from "mobx-react";
import {RestaurantForm} from "../../../../../../mobx/components/restaurant-form";
import {RestaurantUtils} from "@lib/common";

type FormValues = T.Models.Restaurant.Menus.DishTag;
interface Props {
  type: "edit" | "create";
  initialValues: FormValues | null;
  close: () => void;
}
interface State {
  tab: string;
  add_to: string[];
}

@inject("store") @observer
export class RestaurantFormDishTags extends MobxComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      tab: "0",
      add_to: [],
    };
  }

  setTab = (tab: string) => {
    this.setState({ tab });
  }

  dishes_in = (id: string) => {
    const r = this.injected.store.restaurant!;
    return RestaurantUtils.menu
      .filterDishes(r, (dish) => (dish.tags.indexOf(id) !== -1))
      .map((dish) => dish._id);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.initialValues && !prevProps.initialValues) {
      this.setTab("0");
      this.setState({
        add_to: this.dishes_in(this.props.initialValues._id),
      });
    }
  }

  render() {
    const {type, initialValues, close} = this.props;
    const { tab, add_to } = this.state;
    const restaurant = this.injected.store.restaurant!;

    return (
      <Modal
        width="md"
        alignTop={true}
        active={!!initialValues}
        close={close}>

        <RestaurantForm<FormValues>
          submit={async (r, values) => {

            const existing = r.dish_tags.findIndex((p) => p._id === values._id);

            if (existing === -1) {
              r.dish_tags.push(values);
            }
            else {
              r.dish_tags[existing] = values;
            }

            for (const [i, m] of r.menus.entries()) {
              for (const [k, c] of m.categories.entries()) {
                for (const [z, d] of c.dishes.entries()) {
                  if (add_to.indexOf(d._id) === -1) {
                    const index = d.tags.indexOf(values._id);
                    if (index !== -1) {
                      r.menus[i].categories[k].dishes[z].tags.splice(index, 1);
                    }
                  }
                  else {
                    const index = d.tags.indexOf(values._id);
                    if (index === -1) {
                      r.menus[i].categories[k].dishes[z].tags.push(values._id);
                    }
                  }
                }
              }
            }

            const update = {
              $set: {
                menus: r.menus,
                dish_tags: r.dish_tags,
              },
            };

            return {r, update};
          }}
          validators={{
            name: (values) => {
              if (!values.name)
                return { name: "This field is required" };
            },
          }}
          initialValues={initialValues}
          onSuccess={close}
          onSuccessMessage={type === "edit" ? "Tag edited" : "Tag created"}
          onErrorMessage="">
          {({form, error, getFieldError}) => {
            const {isSubmitting, setFieldValue, setFieldTouched, setFieldError, submitCount, values} = form;
            return (
              <div>

                <ModalContent>
                  <h3 className="m-b-2">{type === "create" ? "Create Dish Tag" : "Edit Dish Tag"}</h3>
                  <DishTag {...values}/>
                </ModalContent>

                <TabSelect
                  id="tag-tab-options"
                  className="border-white-tb-15"
                  hasBorder={true}
                  onChange={(v) => this.setTab(v.value)}
                  screenWidth={this.injected.store.view.screen_width}
                  value={tab}
                  values={[
                    { label: "General", value: "0" },
                    { label: "Add / Remove From Dishes", value: "1" },
                  ]}/>

                {tab === "0" && (
                  <ModalContent>

                    <FastField
                      name="name"
                      render={({field}: any) => (
                        <FormGroup
                          title="Name"
                          help="A unique name for your dish tag"
                          error={getFieldError(form, "name")}>
                          <Input type="text" {...field} required={true}/>
                        </FormGroup>
                      )}
                    />

                    <FastField
                      name="text"
                      render={({field}: any) => (
                        <FormGroup title="Tag Text" help="The text to be displayed beside the tag icon">
                          <Input type="text" {...field}/>
                        </FormGroup>
                      )}
                    />

                    <FastField
                      name="type"
                      render={({field}: any) => (
                        <FormGroup title="Icon Type" help="The type of icon to be used for the tag">
                          <ButtonGroup
                            size="sm"
                            width={100}
                            selected={field.value}
                            options={[
                              {value: "text", name: "Text"},
                              {value: "icon", name: "Icon"},
                              {value: "none", name: "None"},
                            ]}
                            onSelect={(selection) => {
                              setFieldValue("type", selection.value);
                              setFieldValue("icon", "");
                            }}
                          />
                        </FormGroup>
                      )}
                    />

                    {form.values.type === "icon" && (
                      <FastField
                        name="icon"
                        render={({field}: any) => (
                          <FormGroup title="Select An Icon">
                            <RestaurantIconSelect selected={field.value}
                                                  onChange={(icon) => setFieldValue("icon", icon)}/>
                          </FormGroup>
                        )}
                      />
                    )}

                    {form.values.type === "text" && (
                      <FastField
                        name="icon"
                        render={({field}: any) => (
                          <FormGroup title="Icon Text" help="The text to be displayed inside the icon on the dish tag">
                            <Input {...field}/>
                          </FormGroup>
                        )}
                      />
                    )}

                    <FormGroup
                      title="Tag Color"
                      help="This determines the main background and text color of the dish tag">
                      <div className="flex-line">
                        <FastField
                          name="color.wrapper.background"
                          render={({field}: any) => (
                            <FormGroup no_border={true} className="m-b-0 m-r-6" contentClassName="flex-line centered">
                              <p className="m-r-3 font-semi-bold">Background</p>
                              <ColorPicker
                                color={field.value}
                                onChange={(color) => setFieldValue("color.wrapper.background", color)}/>
                            </FormGroup>
                          )}
                        />
                        <FastField
                          name="color.wrapper.text"
                          render={({field}: any) => (
                            <FormGroup no_border={true} className="m-b-0" contentClassName="flex-line centered">
                              <p className="m-r-3 font-semi-bold">Text</p>
                              <ColorPicker
                                color={field.value}
                                onChange={(color) => setFieldValue("color.wrapper.text", color)}/>
                            </FormGroup>
                          )}
                        />
                      </div>
                    </FormGroup>

                    <FormGroup
                      title="Icon Color"
                      help="This determines the background and text/icon color of the icon component of the dish tag">
                      <div className="flex-line">
                        <FastField
                          name="color.icon.background"
                          render={({field}: any) => (
                            <FormGroup no_border={true} className="m-b-0 m-r-6" contentClassName="flex-line centered">
                              <p className="m-r-3 font-semi-bold">Background</p>
                              <ColorPicker
                                color={field.value}
                                onChange={(color) => setFieldValue("color.icon.background", color)}/>
                            </FormGroup>
                          )}
                        />
                        <FastField
                          name="color.icon.text"
                          render={({field}: any) => (
                            <FormGroup no_border={true} className="m-b-0" contentClassName="flex-line centered">
                              <p className="m-r-3 font-semi-bold">Text / Icon</p>
                              <ColorPicker
                                color={field.value}
                                onChange={(color) => setFieldValue("color.icon.text", color)}/>
                            </FormGroup>
                          )}
                        />
                      </div>
                    </FormGroup>

                  </ModalContent>
                )}

                {tab === "1" && (
                  <ModalContent>
                    <FormGroup help="Easily add or remove this tag to/from your dishes">
                      <FormTreeSelect
                        id={`add-to-dishes`}
                        selected={add_to}
                        nodes={RestaurantUtils.menu.getTreeFilter(restaurant, () => true)}
                        onChange={(add) => {
                          this.setState({ add_to: add });
                        }}
                      />
                    </FormGroup>
                  </ModalContent>
                )}

                <ModalContent>
                  {error && <FormGroup error={error}/>}
                  <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white"/>}
                    {!isSubmitting && "Save"}
                  </Button>
                </ModalContent>

              </div>
            );
          }}
        </RestaurantForm>

      </Modal>
    );

  }

}
