import * as React from "react";
import { FastField} from "formik";
import { FormGroup, Button, RotateLoader, Input } from "@lib/components";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../../mobx/components";
import {WebsiteForm} from "../../../../../../mobx/components/website-form";
import {UI} from "../../../../../../core/ui";

interface Props {}
interface State {}
type FormValues = T.Models.Website.Schema["sections"]["locations"];

@inject("store") @observer
export class WebsiteFormLocations extends MobxComponent<Props, State> {

  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    const w = this.injected.store.website!;
    this.state = {};
    this.initialValues = w.sections.locations || {
      map_zoom: 12,
    };
  }

  render() {
    return (
      <WebsiteForm<FormValues>
        submit={async (w, values) => {
          w.sections.locations = values;
          const update = { $set: { "sections.locations": values } };
          return { w, update };
        }}
        validators={{}}
        initialValues={this.initialValues}
        onSuccess={() => {}}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
          const { isSubmitting, setFieldValue, setFieldTouched } = form;
          return (
            <div className="p-4">

              <FastField
                name="map_zoom"
                render={({ field }: any) => (
                  <FormGroup title="Map Zoom" help="The zoom level of your embedded store map. Higher value is more zoomed in. Default is 12 if left empty">
                    <Input type="number" {...field}/>
                  </FormGroup>
                )}
              />

              {error && <FormGroup error={error}/>}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white"/>}
                {!isSubmitting && "Save"}
              </Button>

            </div>
          );
        }}
      </WebsiteForm>
    );
  }
}
