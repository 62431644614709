import * as React from "react";
import { FastField} from "formik";
import { FormGroup, Button, RotateLoader, Switch, FormUpload } from "@lib/components";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../../mobx/components";
import {WebsiteForm} from "../../../../../../mobx/components/website-form";
import {UI} from "../../../../../../core/ui";
import {toJS} from "mobx";

interface Props {}
interface State {}
type FormValues = T.Models.Website.Schema["sections"]["gallery"];

@inject("store") @observer
export class WebsiteFormGallery extends MobxComponent<Props, State> {

  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    const w = this.injected.store.website!;
    this.state = {};
    this.initialValues = toJS(w.sections.gallery);
  }

  render() {
    return (
      <WebsiteForm<FormValues>
        submit={async (w, values) => {

          w.sections.gallery = values;

          const update = { $set: { "sections.gallery": values } };

          return { w, update };

        }}
        validators={{}}
        initialValues={this.initialValues}
        onSuccess={() => {}}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <div className="p-4">

              <FastField
                name="hidden"
                render={({ field }: any) => (
                  <FormGroup title="Hide Section">
                    <Switch
                      id="section.hidden"
                      checked={field.value}
                      onChange={(e) => setFieldValue("hidden", e.target.checked)}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="images"
                render={({ field }: any) => (
                  <FormGroup
                    title="Gallery Images"
                    help="Upload up to 6 images for your gallery">

                    <FormUpload
                      multi={true}
                      multiAmount={6 - field.value.length}
                      maxSize={1000}
                      imagesOnly={true}
                      value={field.value || []}
                      onRemove={(i) => {
                        if (typeof i !== "undefined") {
                          const images = [...(field.value || [])];
                          images.splice(i, 1);
                          setFieldValue("images", images);
                        }
                      }}
                      onChange={(files) => setFieldValue("images", [ ...field.value, ...files ])}/>
                  </FormGroup>
                )}
              />

              {error && <FormGroup error={error}/>}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white"/>}
                {!isSubmitting && "Save"}
              </Button>

            </div>
          );
        }}
      </WebsiteForm>
    );
  }

}
