import * as React from "react";
import { FastField} from "formik";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../../../mobx/components";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {UI} from "../../../../../../../core/ui";
import { FormGroup, Button, RotateLoader, ColorPicker, FormUpload } from "@lib/components";

interface Props {}
interface State {}
type FormValues = T.Models.Restaurant.Schema["website"]["sections"]["footer"];

@inject("store") @observer
export class SettingsFormDesignFooter extends MobxComponent<Props, State> {
  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    const r = this.injected.store.restaurant!;
    this.state = {};
    this.initialValues = r.website.sections.footer;
  }

  render() {
    const col_props = {
      className: "col",
      small_title: true,
      no_border: true,
    };
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.website.sections.footer = values;
          const update = { $set: { "website.sections.footer": values } };
          return { r, update };
        }}
        validators={{}}
        initialValues={this.initialValues}
        onSuccess={() => {}}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <div className="p-4">

              <FormGroup
                title="Colors"
                contentClassName="grid-2 sm sm-gap max300">
                <FastField
                  name="colors.background"
                  render={({ field }: any) => (
                    <FormGroup title="Background" {...col_props}>
                      <ColorPicker
                        color={field.value}
                        onChange={(color) => setFieldValue("colors.background", color)}/>
                    </FormGroup>
                  )}
                />
                <FastField
                  name="colors.text"
                  render={({ field }: any) => (
                    <FormGroup title="Text" {...col_props}>
                      <ColorPicker
                        color={field.value}
                        onChange={(color) => setFieldValue("colors.text", color)}/>
                    </FormGroup>
                  )}
                />
              </FormGroup>

              <FastField
                name="images.background"
                render={({ field }: any) => (
                  <FormGroup
                    title="Footer Background Image"
                    help="This image will be stretched across your users screen. It's recommend to use a an image at least 1900x500 pixels. This will override the footer background color. Max file size is 1mb">
                    <FormUpload
                      maxSize={1000}
                      imagesOnly={true}
                      stockImages={true}
                      value={[field.value]}
                      onRemove={() => setFieldValue("images.background", null)}
                      onChange={(files) => setFieldValue("images.background", files[0])}
                      stockImageQuery="?fit=min&w=1920&h=800&q=60"/>
                  </FormGroup>
                )}
              />

              <FastField
                name="colors.tint"
                render={({ field }: any) => (
                  <FormGroup
                    optional={true}
                    title="Footer Background Image Tint"
                    help={<span>Add a tint over your background image to darken or lighten it to increase text legibility. Ensure your <span className="font-semi-bold underline">tint is transparent</span> to avoid covering the background image</span>}>
                    <div className="flex-line centered">
                      <ColorPicker
                        color={field.value}
                        onChange={(color) => setFieldValue("colors.tint", color)}/>
                      <Button
                        type="button"
                        className="m-l-3"
                        size="xxs"
                        onClick={() => setFieldValue("colors.tint", "")}>
                        Clear
                      </Button>
                    </div>
                  </FormGroup>
                )}
              />

              {error && <FormGroup error={error}/>}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white"/>}
                {!isSubmitting && "Save"}
              </Button>

            </div>
          );
        }}
      </RestaurantForm>
    );
  }
}
