import * as React from "react";
import { FastField } from "formik";
import { ListServicesOptions } from "@lib/common";
import { FormGroup, Input, SelectAdv } from "@lib/components";

interface Props {
  setFieldValue: any;
  defaultLabel?: string;
  requireLabel?: boolean;
}

export const PaymentBaseFields = (props: Props) => {
  return (
    <>
      <FastField
        name="services"
        render={({ field }: any) => (
          <FormGroup
            optional={true}
            title="Services"
            help="Select which services this payment method will be restricted to. Leave empty to be usable by all services">
            <SelectAdv
              type="multi"
              value={field.value}
              onChange={(options: string[]) => {
                props.setFieldValue("services", options);
              }}
              options={ListServicesOptions}/>
          </FormGroup>
        )}
      />

      <FastField
        name="label"
        render={({ field }: any) => (
          <FormGroup
            optional={!props.requireLabel}
            title="Label"
            help={
              props.defaultLabel ?
                `Override the default label for this payment method during order checkout. Defaults to '${props.defaultLabel}'` :
                `Set the default label for this payment method`
            }>
            <Input {...field} type="text" value={field.value || ""} required={!!props.requireLabel}/>
          </FormGroup>
        )}
      />

      <FastField
        name="label_delivery"
        render={({ field }: any) => (
          <FormGroup
            optional={true}
            title="Delivery Label"
            help={
              props.defaultLabel ?
                `Override the label for this payment method during order checkout specifically for delivery orders. Defaults to '${props.defaultLabel}'` :
                `Override the label for this payment method during order checkout specifically for delivery orders`
            }>
            <Input {...field} type="text" value={field.value || ""}/>
          </FormGroup>
        )}
      />

      <FastField
        name="label_print"
        render={({ field }: any) => (
          <FormGroup
            optional={true}
            title="Print Label"
            help="Set the label for this payment method on printed or PDF receipts. This will override other labels">
            <Input {...field} type="text" value={field.value || ""}/>
          </FormGroup>
        )}
      />

      <FastField
        name="max_order"
        render={({ field }: any) => (
          <FormGroup
            optional={true}
            title="Maximum Order Value ($)"
            help="Hide this payment method if the customers order total exceeds this value. Useful for disabling in-store payment methods for high priced orders to combat spam">
            <Input {...field} type="number" value={field.value || ""} step="0.01"/>
          </FormGroup>
        )}
      />

    </>
  );
};
