import * as React from "react";
import { MenuList } from "./layout/list";
import { MenuListButton } from "./layout/button";
import { MenuListItem } from "./layout/item";
import { RestaurantFormMenu } from "./forms/menu";
import { RestaurantFormCategory } from "./forms/category";
import { RestaurantFormDish } from "./forms/dish";
import shortid from "shortid";
import cloneDeep from "lodash/cloneDeep";
import { Button, RotateLoader, Checkbox, Tag, Modal, ModalContent } from "@lib/components";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { arrayMove } from "react-sortable-hoc";
import { RestaurantUtils } from "@lib/common";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../mobx/components";
import { MenuListFloatingButtonWrapper, MenuListFloatingTitle, MenuListFloatingContent } from "./layout/floating-button";

type MenuComponents = "menu" | "category" | "dish";

interface Props {}
interface State {
  panel: MenuComponents | null;
  panel_index: number[];
  copy_type: MenuComponents | null;
  copy_index: number[];
  copy_loading: boolean;
  remove_type: MenuComponents | null;
  remove_index: number[];
  remove_loading: boolean;
  reorder: boolean;
  reorder_loading: boolean;
  selected_dishes: string[];
  selected_loading: boolean;
  error: string | null;
}

@inject("store") @observer
export class RestaurantMenuList extends MobxComponent<Props, State> {

  menu_backup: T.Models.Restaurant.Schema["menus"] | null;

  constructor(props: Props) {
    super(props);
    this.state = {
      panel: null,
      panel_index: [],
      copy_type: null,
      copy_index: [],
      copy_loading: false,
      remove_type: null,
      remove_index: [],
      remove_loading: false,
      reorder: false,
      reorder_loading: false,
      selected_dishes: [],
      selected_loading: false,
      error: null,
    };
    this.menu_backup = null;
  }

  componentDidMount() {

  }

  setPanel = (panel: MenuComponents | null, panel_index: number[]) => {
    this.setState({ panel, panel_index });
  }

  create_menu = (): T.Models.Restaurant.Menus.Menu => {
    const _id = shortid.generate();
    return {
      _id: _id,
      name: "",
      display_name: "",
      description: "",
      conditions: {
        services: [],
        times: [],
        hours: [],
        pre_order: {
          enabled: false,
          days_in_advance: "",
          cutoff_time: "",
        },
        age_restricted: false,
      },
      categories: [],
    };
  }
  create_category(menu_id: string): T.Models.Restaurant.Menus.Category {
    return {
      _id: shortid.generate(),
      menu_id: menu_id,
      name: "",
      display_name: "",
      dishes: [],
    };
  }
  create_dish(menu_id: string, category_id: string): T.Models.Restaurant.Menus.Dish {
    return {
      type: "standard",
      _id: shortid.generate(),
      menu_id: menu_id,
      category_id: category_id,
      name: "",
      display_name: "",
      description: "",
      subtitle: "",
      price: "" as any,
      tags: [],
      image: null,
      status: null,
      // STANDARD
      ingredients: [],
      option_sets: [],
      // COMBO
      choices: [],
      price_type: "standard",
    };
  }

  list_menu = () => {
    const { reorder, reorder_loading } = this.state;
    const r = this.injected.store.restaurant!;
    const { menus } = r;
    return (
      <MenuList id="menu-list" type="MENU">
        {menus.map((menu, i) => {
          return (
            <MenuListItem
              key={i}
              type="MENU"
              id={menu._id}
              dragDisabled={!reorder}
              name={menu.name}
              index={i}
              onEdit={() => this.setPanel("menu", [i])}
              onRemove={() => this.modal_remove_open("menu", [i])}
              onCopy={() => this.modal_copy_open("menu", [i])}>
              {this.list_category(i)}
            </MenuListItem>
          );
        })}
        {!reorder && [
          <MenuListButton key="btn-create" onClick={() => this.setPanel("menu", [-1])}>
            Create New Menu
          </MenuListButton>,
          menus.length > 0 ? (
            <MenuListButton key="btn-arrange" onClick={this.reorder_start}>
              Re-arrange
            </MenuListButton>
          ) : null,
        ]}
        {reorder && (
          <div>
            <div className="grid-2 xs xs-gap">
              <div className="col">
                <MenuListButton onClick={this.reorder_save}>
                  {reorder_loading && <RotateLoader size={2} color="white"/>}
                  {!reorder_loading && "Save"}
                </MenuListButton>
              </div>
              <div className="col">
                <MenuListButton onClick={this.reorder_cancel}>
                  Cancel
                </MenuListButton>
              </div>
            </div>
            <div className="text-center m-t-3">
              <p>Click + hold to drag and drop items to re-order them</p>
            </div>
          </div>
        )}
      </MenuList>
    );
  }
  list_category = (menuIndex: number) => {
    const { reorder } = this.state;
    const r = this.injected.store.restaurant!;
    const menu = r.menus[menuIndex];
    return (
      <MenuList id={menu._id} type="CATEGORY">
        {menu.categories.map((category, i) => {
          return (
            <MenuListItem
              key={i}
              id={category._id}
              type="CATEGORY"
              dragDisabled={!reorder}
              name={category.name}
              index={i}
              onEdit={() => this.setPanel("category", [menuIndex, i])}
              onRemove={() => this.modal_remove_open("category", [menuIndex, i])}
              onCopy={() => this.modal_copy_open("category", [menuIndex, i])}>
              {this.list_dish(menuIndex, i)}
            </MenuListItem>
          );
        })}
        {!reorder && (
          <MenuListButton onClick={() => this.setPanel("category", [menuIndex, -1])}>
            Create New Category
          </MenuListButton>
        )}
      </MenuList>
    );
  }
  list_dish = (menuIndex: number, categoryIndex: number) => {
    const { selected_dishes } = this.state;
    const { reorder } = this.state;
    const r = this.injected.store.restaurant!;
    const menu = r.menus[menuIndex];
    const category = menu.categories[categoryIndex];
    return (
      <MenuList id={category._id} type="DISH">
        {category.dishes.map((item, i) => {
          return (
            <MenuListItem
              key={i}
              id={item._id}
              type="DISH"
              dragDisabled={!reorder}
              name={(
                <div className="flex-l-r-center width100">
                  <div className="flex-line centered">
                    <Checkbox
                      id={`dish-${item._id}`}
                      checked={selected_dishes.indexOf(item._id) !== -1}
                      onChange={(e) => {
                        e.stopPropagation();
                        this.toggle_selected_dish(item._id);
                      }}
                    />
                    <p className="m-l-3">{item.name}</p>
                  </div>
                  {item.status && (
                    <Tag className="m-r-2">
                      {item.status === "hidden" && "Hidden"}
                      {item.status === "no-stock" && "No Stock"}
                    </Tag>
                  )}
                </div>
              )}
              index={i}
              onEdit={() => this.setPanel("dish", [menuIndex, categoryIndex, i])}
              onCopy={() => this.modal_copy_open("dish", [menuIndex, categoryIndex, i])}
            />
          );
        })}
        {!reorder && (
          <MenuListButton onClick={() => this.setPanel("dish", [menuIndex, categoryIndex, -1])}>
            Create New Dish
          </MenuListButton>
        )}
      </MenuList>
    );
  }

  panel_menu = () => {
    const r = this.injected.store.restaurant!;
    const { panel, panel_index } = this.state;
    const [menuIndex, categoryIndex, dishIndex] = panel_index;
    const active = panel === "menu";
    let initialValues = null;
    if (active) {
      if (menuIndex === -1) {
        initialValues = this.create_menu();
      }
      else {
        const item = r.menus[menuIndex];
        initialValues = cloneDeep(item);
      }
    }
    return (
      <RestaurantFormMenu
        type={menuIndex === -1 ? "create" : "edit"}
        initialValues={initialValues}
        close={() => this.setPanel(null, [])}/>
    );
  }
  panel_category = () => {
    const r = this.injected.store.restaurant!;
    const { panel, panel_index } = this.state;
    const [menuIndex, categoryIndex, dishIndex] = panel_index;
    const active = panel === "category";
    let initialValues = null;
    if (active) {
      const menu = r.menus[menuIndex];
      if (categoryIndex === -1) {
        initialValues = this.create_category(menu._id);
      }
      else {
        const item = r.menus[menuIndex].categories[categoryIndex];
        initialValues = cloneDeep(item);
      }
    }

    return (
      <RestaurantFormCategory
        type={categoryIndex === -1 ? "create" : "edit"}
        menuIndex={menuIndex}
        initialValues={initialValues}
        close={() => this.setPanel(null, [])}/>
    );

  }
  panel_dish = () => {
    const r = this.injected.store.restaurant!;
    const { panel, panel_index } = this.state;
    const [menuIndex, categoryIndex, dishIndex] = panel_index;
    const active = panel === "dish";
    let initialValues = null;
    if (active) {
      const menu = r.menus[menuIndex];
      const category = menu.categories[categoryIndex];
      if (dishIndex === -1) {
        initialValues = this.create_dish(menu._id, category._id);
      }
      else {
        const item = r.menus[menuIndex].categories[categoryIndex].dishes[dishIndex];
        initialValues = cloneDeep(item);
      }
    }
    return (
      <RestaurantFormDish
        type={dishIndex === -1 ? "create" : "edit"}
        menuIndex={menuIndex}
        categoryIndex={categoryIndex}
        initialValues={initialValues}
        close={() => this.setPanel(null, [])}/>
    );
  }

  modal_copy = () => {
    const r = this.injected.store.restaurant!;
    const { copy_type, copy_index, copy_loading } = this.state;

    const [menuIndex, categoryIndex, dishIndex] = copy_index;

    let item;
    let copyFn;
    if (copy_type === "menu") {
      item = r.menus[menuIndex];
      copyFn = () => this.menu_copy(menuIndex);
    }
    else if (copy_type === "category") {
      item = r.menus[menuIndex].categories[categoryIndex];
      copyFn = () => this.category_copy(menuIndex, categoryIndex);
    }
    else if (copy_type === "dish") {
      item = r.menus[menuIndex].categories[categoryIndex].dishes[dishIndex];
      copyFn = () => this.dish_copy(menuIndex, categoryIndex, dishIndex);
    }

    return (
      <Modal
        width="sm"
        close={this.modal_copy_close}
        closeButton={false}
        active={!!item}>
        <ModalContent className="flex-l-r-center">
          <h4 className="">Copy {copy_type}</h4>
          <p className="big underline">{item && item.name}</p>
        </ModalContent>
        <ModalContent className="flex-right">
          <Button type="button" className="m-r-2 width100 max100px" onClick={this.modal_copy_close}>Cancel</Button>
          <Button type="button" className="width100 max100px" color="primary" onClick={copyFn}>
            {copy_loading && <RotateLoader size={2} color="white"/>}
            {!copy_loading && "Copy"}
          </Button>
        </ModalContent>
      </Modal>
    );

  }
  modal_remove = () => {
    const r = this.injected.store.restaurant!;
    const { remove_type, remove_index, remove_loading } = this.state;

    const [menuIndex, categoryIndex, dishIndex] = remove_index;

    let item;
    let removeFn;
    if (remove_type === "menu") {
      item = r.menus[menuIndex];
      removeFn = () => this.menu_remove(menuIndex);
    }
    else if (remove_type === "category") {
      item = r.menus[menuIndex].categories[categoryIndex];
      removeFn = () => this.category_remove(menuIndex, categoryIndex);
    }
    else if (remove_type === "dish") {
      item = r.menus[menuIndex].categories[categoryIndex].dishes[dishIndex];
      removeFn = () => this.dish_remove(menuIndex, categoryIndex, dishIndex);
    }

    return (
      <Modal
        width="sm"
        close={this.modal_remove_close}
        closeButton={false}
        active={!!item}>
        <ModalContent className="flex-l-r-center">
          <h4 className="">Delete {remove_type}</h4>
          <p className="big underline">{item && item.name}</p>
        </ModalContent>
        <ModalContent className="flex-right">
          <Button type="button" className="m-r-2 width100 max100px" onClick={this.modal_remove_close}>Cancel</Button>
          <Button type="button" className="width100 max100px" color="primary" onClick={removeFn}>
            {remove_loading && <RotateLoader size={2} color="white"/>}
            {!remove_loading && "Delete"}
          </Button>
        </ModalContent>
      </Modal>
    );
  }

  menu_copy = async (menuIndex: number) => {
    await this.copy({
      successMsg: "Menu copied",
      process: (r) => {
        const menu = cloneDeep(r.menus[menuIndex]);
        const menu_id = shortid.generate();
        menu._id = menu_id;
        menu.name = `${menu.name} - Copy`;
        for (let i = 0; i < menu.categories.length; i++) {
          const category_id = shortid.generate();
          menu.categories[i]._id = category_id;
          menu.categories[i].menu_id = menu_id;
          for (let k = 0; k < menu.categories[i].dishes.length; k++) {
            menu.categories[i].dishes[k]._id = shortid.generate();
            menu.categories[i].dishes[k].menu_id = menu_id;
            menu.categories[i].dishes[k].category_id = category_id;
          }
        }
        r.menus.splice(menuIndex + 1, 0, menu);
      },
    });
  }
  menu_remove = async (menuIndex: number) => {
    await this.remove({
      successMsg: "Menu deleted",
      process: (r) => {
        r.menus.splice(menuIndex, 1);
      },
    });
  }
  category_copy = async (menuIndex: number, categoryIndex: number) => {
    await this.copy({
      successMsg: "Category copied",
      process: (r) => {
        const menu = r.menus[menuIndex];
        const category = cloneDeep(menu.categories[categoryIndex]);
        const category_id = shortid.generate();
        category._id = category_id;
        category.name = `${category.name} - Copy`;
        for (let i = 0; i < category.dishes.length; i++) {
          category.dishes[i]._id = shortid.generate();
          category.dishes[i].category_id = category_id;
        }
        r.menus[menuIndex].categories.splice(categoryIndex + 1, 0, category);
      },
    });
  }
  category_remove = async (menuIndex: number, categoryIndex: number) => {
    await this.remove({
      successMsg: "Category deleted",
      process: (r) => {
        r.menus[menuIndex].categories.splice(categoryIndex, 1);
      },
    });
  }
  dish_copy = async (menuIndex: number, categoryIndex: number, dishIndex: number) => {
    await this.copy({
      successMsg: "Dish copied",
      process: (r) => {
        const item = cloneDeep(r.menus[menuIndex].categories[categoryIndex].dishes[dishIndex]);
        item._id = shortid.generate();
        item.name = `${item.name} - Copy`;
        r.menus[menuIndex].categories[categoryIndex].dishes.splice(categoryIndex + 1, 0, item);
      },
    });
  }
  dish_remove = async (menuIndex: number, categoryIndex: number, dishIndex: number) => {
    await this.remove({
      successMsg: "Dish deleted",
      process: (r) => {
        r.menus[menuIndex].categories[categoryIndex].dishes.splice(dishIndex, 1);
      },
    });
  }

  copy = async (opts: { process: (r: T.Models.Restaurant.Schema) => void; successMsg: string; }) => {
    await this.saveRestaurant({
      successMsg: opts.successMsg,
      process: (r) => {
        opts.process(r);
        return {
          update: { $set: { menus: r.menus } },
        };
      },
      before: () => this.setState({ copy_loading: true }),
      onSuccess: () => this.modal_copy_close(),
      onFail: () => this.setState({ copy_loading: false }),
      onError: () => this.setState({ copy_loading: false }),
    });
  }
  remove = async (opts: { process: (r: T.Models.Restaurant.Schema) => void; successMsg: string; }) => {
    await this.saveRestaurant({
      successMsg: opts.successMsg,
      process: (r) => {
        opts.process(r);
        return {
          update: { $set: { menus: r.menus } },
        };
      },
      before: () => this.setState({ remove_loading: true }),
      onSuccess: () => this.modal_remove_close(),
      onFail: () => this.setState({ remove_loading: false }),
      onError: () => this.setState({ remove_loading: false }),
    });
  }

  modal_copy_open = (type: MenuComponents, index: number[]) => {
    this.setState({
      copy_type: type,
      copy_index: index,
      copy_loading: false,
    });
  }
  modal_copy_close = () => {
    this.setState({
      copy_type: null,
      copy_index: [],
      copy_loading: false,
    });
  }
  modal_remove_open = (type: MenuComponents, index: number[]) => {
    this.setState({
      remove_type: type,
      remove_index: index,
      remove_loading: false,
    });
  }
  modal_remove_close = () => {
    this.setState({
      remove_type: null,
      remove_index: [],
      remove_loading: false,
    });
  }

  toggle_selected_dish = (dish_id: string) => {
    const selected_dishes = [ ...this.state.selected_dishes ];
    const index = selected_dishes.indexOf(dish_id);
    if (index === -1)
      selected_dishes.push(dish_id);
    else
      selected_dishes.splice(index, 1);
    this.setState({ selected_dishes });
  }
  float_button_cancel = () => {
    this.setState({ selected_dishes: [] });
  }
  float_button_delete = async () => {
    await this.float_button_process({
      successMsg: "Dishes deleted",
      process: (r, selected_dishes) => {
        for (const [i, menu] of r.menus.entries()) {
          for (const [k, category] of menu.categories.entries()) {
            r.menus[i].categories[k].dishes = category.dishes.filter((dish) => selected_dishes.indexOf(dish._id) === -1);
          }
        }
      },
    });
  }
  float_button_no_stock = async () => {
    await this.float_button_process({
      successMsg: "Dish status set",
      process: (r, selected_dishes) => {
        for (const [i, menu] of r.menus.entries()) {
          for (const [k, category] of menu.categories.entries()) {
            for (const [z, dish] of category.dishes.entries()) {
              if (selected_dishes.indexOf(dish._id) !== -1) {
                r.menus[i].categories[k].dishes[z].status = "no-stock";
              }
            }
          }
        }
      },
    });
  }
  float_button_hidden = async () => {
    await this.float_button_process({
      successMsg: "Dish status set",
      process: (r, selected_dishes) => {
        for (const [i, menu] of r.menus.entries()) {
          for (const [k, category] of menu.categories.entries()) {
            for (const [z, dish] of category.dishes.entries()) {
              if (selected_dishes.indexOf(dish._id) !== -1) {
                r.menus[i].categories[k].dishes[z].status = "hidden";
              }
            }
          }
        }
      },
    });
  }
  float_button_available = async () => {
    await this.float_button_process({
      successMsg: "Dish status set",
      process: (r, selected_dishes) => {
        for (const [i, menu] of r.menus.entries()) {
          for (const [k, category] of menu.categories.entries()) {
            for (const [z, dish] of category.dishes.entries()) {
              if (selected_dishes.indexOf(dish._id) !== -1) {
                r.menus[i].categories[k].dishes[z].status = null;
              }
            }
          }
        }
      },
    });
  }
  float_button_process = async (opts: { process: (r: T.Models.Restaurant.Schema, selected_dishes: string[]) => void, successMsg: string }) => {
    const { selected_dishes } = this.state;
    await this.saveRestaurant({
      successMsg: opts.successMsg,
      process: (r) => {
        opts.process(r, selected_dishes);
        return {
          update: { $set: { menus: r.menus } },
        };
      },
      before: () => this.setState({ selected_loading: true }),
      onSuccess: () => this.setState({ selected_loading: false, selected_dishes: [] }),
      onFail: () => this.setState({ selected_loading: false }),
      onError: () => this.setState({ selected_loading: false }),
    });
  }

  reorder_start = () => {
    this.setState({ reorder: true });
    const r = this.injected.store.restaurant!;
    this.menu_backup = cloneDeep(r.menus);
  }
  reorder_cancel = () => {
    const r = { ...this.injected.store.restaurant! };
    r.menus = this.menu_backup ? this.menu_backup : [];
    this.injected.store.setRestaurant(r);
    this.setState({ reorder: false });
  }
  reorder_save = async () => {
    const r = this.injected.store.restaurant!;
    const backup = cloneDeep(r);
    if (this.menu_backup)
      backup.menus = this.menu_backup;
    await this.saveRestaurantSilent({
      backup: backup,
      update: { $set: { menus: r.menus } },
      successMsg: "Menu re-arranged",
      before: () => this.setState({ reorder_loading: true }),
      onSuccess: () => {
        this.setState({ reorder: false, reorder_loading: false });
        this.menu_backup = null;
      },
      onFail: () => this.setState({ reorder_loading: false }),
      onError: () => this.setState({ reorder_loading: false }),
    });
  }

  onDragStart = () => {

  }
  onDragUpdate = () => {

  }
  onDragEnd = (result: DropResult) => {
    const { draggableId, destination, source, type, reason} = result;
    if (!destination) return;
    const r = { ...this.injected.store.restaurant! };
    if (type === "MENU") {
      if (destination.index !== source.index) {
        r.menus = arrayMove(r.menus, source.index, destination.index);
        this.injected.store.setRestaurant(r);
      }
    }
    else if (type === "CATEGORY") {
      const menus = cloneDeep(r.menus);
      if (destination.droppableId !== source.droppableId) {
        const sourceMenu = source.droppableId;
        const destinationMenu = destination.droppableId;
        const sourceMenuIndex = menus.findIndex((m) => m._id === sourceMenu);
        const destinationMenuIndex = menus.findIndex((m) => m._id === destinationMenu);
        // CLONE CATEGORY
        const item = cloneDeep(menus[sourceMenuIndex].categories[source.index]);
        item.menu_id = destinationMenu;
        for (let i = 0; i < item.dishes.length; i++) {
          item.dishes[i].menu_id = destinationMenu;
        }
        // REMOVE FROM OLD MENU
        menus[sourceMenuIndex].categories.splice(source.index, 1);
        // ADD TO NEW MENU
        menus[destinationMenuIndex].categories.splice(destination.index, 0, item);
      }
      else if (destination.index !== source.index) {
        const menu_id = source.droppableId;
        const menu_index = menus.findIndex((m) => m._id === menu_id);
        menus[menu_index].categories = arrayMove(menus[menu_index].categories, source.index, destination.index);
      }
      r.menus = menus;
      this.injected.store.setRestaurant(r);
    }
    else if (type === "DISH") {
      const menus = cloneDeep(r.menus);
      if (destination.droppableId !== source.droppableId) {

        const sourceCategory = source.droppableId;
        const destinationCategory = destination.droppableId;

        const s = RestaurantUtils.menu.findCategoryById(r, sourceCategory);
        const d = RestaurantUtils.menu.findCategoryById(r, destinationCategory);

        if (!s || !d) return;

        // CLONE CATEGORY
        const item = cloneDeep(menus[s.menuIndex].categories[s.categoryIndex].dishes[source.index]);
        item.menu_id = d.menu._id;
        item.category_id = d.category._id;

        // REMOVE FROM CATEGORY
        menus[s.menuIndex].categories[s.categoryIndex].dishes.splice(source.index, 1);
        // ADD TO NEW CATEGORY
        menus[d.menuIndex].categories[d.categoryIndex].dishes.splice(destination.index, 0, item);

      }
      else if (destination.index !== source.index) {
        const category_id = source.droppableId;
        const query = RestaurantUtils.menu.findCategoryById(r, category_id);
        if (!query) throw new Error("Category not found by id");
        const { menu, category, menuIndex, categoryIndex } = query;
        const dishes = menus[menuIndex].categories[categoryIndex].dishes;
        menus[menuIndex].categories[categoryIndex].dishes = arrayMove(dishes, source.index, destination.index);
      }
      r.menus = menus;
      this.injected.store.setRestaurant(r);
    }

  }

  render() {
    const { selected_dishes, selected_loading } = this.state;
    return (
     <div className="relative">

       {selected_dishes.length > 0 && (
         <MenuListFloatingButtonWrapper>
           <MenuListFloatingContent>
             <MenuListFloatingTitle>
               <p className="font-semi-bold big">Actions</p>
               <p className="font-semi-bold small m-t-1">{selected_dishes.length} Selected</p>
             </MenuListFloatingTitle>
             <Button size="xs" onClick={this.float_button_cancel}>Cancel</Button>
             <Button size="xs" onClick={this.float_button_available}>Available</Button>
             <Button size="xs" onClick={this.float_button_no_stock}>No Stock</Button>
             <Button size="xs" onClick={this.float_button_hidden}>Hide</Button>
             <Button size="xs" onClick={this.float_button_delete}>Delete</Button>
             {selected_loading && (
               <div className="text-center p-1">
                 <RotateLoader size={2}/>
               </div>
             )}
           </MenuListFloatingContent>
         </MenuListFloatingButtonWrapper>
       )}

       <DragDropContext
         onDragStart={this.onDragStart}
         onDragUpdate={this.onDragUpdate}
         onDragEnd={this.onDragEnd}>
         {this.list_menu()}
         {this.modal_copy()}
         {this.modal_remove()}
         {this.panel_menu()}
         {this.panel_category()}
         {this.panel_dish()}
       </DragDropContext>

     </div>
    );
  }

}
