import * as React from "react";
import { darken, lighten } from "polished";
import { styled } from "@lib/components";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import shortid from "shortid";

const Block = styled("div")<{ active?: boolean; }>`
  width: 100%;
  background: white;
  border-bottom: ${({active}) => active ? `1px solid ${darken(0.1, "white")}` : "none"};
`;

export const Header = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.26s all;
  background: white;
  border-bottom: 1px solid ${() => darken(0.1, "white")};
  &:hover {
    background: ${() => darken(0.05, "white")};
  }
  > .icon-arrow {
    color: ${() => lighten(0.36, "black")};
  }
`;

const Content = styled("div")``;

interface SettingsSectionBlockProps {
  name: React.ReactNode;
  active?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
  headerClass?: string;
}

export class SettingsSectionBlock extends React.Component<SettingsSectionBlockProps> {

  id: string = shortid.generate();

  onClick = () => {

    if (this.props.onClick)
      this.props.onClick();

    setTimeout(() => {
      const el = document.getElementById(this.id);
      console.log("SCROLL INTO VIEW");
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 120);

  }

  render() {
    const { props } = this;
    return (
      <Block active={props.active}>
        <Header id={this.id} onClick={this.onClick} className={props.headerClass}>
          <div className="flex-grow">{props.name}</div>
          <div className="icon-arrow">
            {props.active ? <FaChevronUp/> : <FaChevronDown/>}
          </div>
        </Header>
        {props.active &&
          <Content>
            {props.children}
          </Content>
        }
      </Block>
    );
  }

}
