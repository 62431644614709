import * as React from "react";
import { FastField } from "formik";
import {MobxComponent} from "../../../../../../../mobx/components";
import {inject, observer} from "mobx-react";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {UI} from "../../../../../../../core/ui";
import { FormGroup, Button, RotateLoader, Textarea } from "@lib/components";

interface Props {}
interface State {}
type FormValues = T.Models.Restaurant.Schema["website"]["inject"];

@inject("store") @observer
export class SettingsFormInjections extends MobxComponent<Props, State> {

  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = { error: null };
    const r = this.injected.store.restaurant!;
    this.initialValues = r.website.inject || {
      html_head: "",
      html_body: "",
    };
  }

  render() {

    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.website.inject = values;
          const update = { $set: { "website.inject": r.website.inject } };
          return { r, update };
        }}
        validators={{}}
        initialValues={this.initialValues}
        onSuccess={() => {}}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <div className="p-4">

              <FormGroup>
                <p className="lhp">Use this section to add custom HTML to your website. Custom CSS should be added to the HTML head</p>
              </FormGroup>

              <FastField
                name="html_head"
                render={({ field }: any) => (
                  <FormGroup optional={true} title="HTML Head Code" help="Code that will be inserted at the end of the HEAD tag">
                    <Textarea
                      onChange={(e) => setFieldValue("html_head", e.target.value)}
                      value={field.value || ""}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="html_body"
                render={({ field }: any) => (
                  <FormGroup optional={true} title="HTML Body Code" help="Code that will be inserted at the end of the BODY tag">
                    <Textarea
                      onChange={(e) => setFieldValue("html_body", e.target.value)}
                      value={field.value || ""}
                    />
                  </FormGroup>
                )}
              />

              {error && <FormGroup error={error}/>}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white"/>}
                {!isSubmitting && "Save"}
              </Button>

            </div>
          );
        }}
      </RestaurantForm>
    );

  }

}
