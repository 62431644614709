import * as React from "react";
import { FastField } from "formik";
import {MobxComponent} from "../../../../../../../mobx/components";
import {inject, observer} from "mobx-react";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {UI} from "../../../../../../../core/ui";
import { FormGroup, Button, RotateLoader, FormUpload } from "@lib/components";

interface Props {}
interface State {}
type FormValues = T.Models.Restaurant.Schema["website"]["favicon"];

@inject("store") @observer
export class SettingsFormWebsiteFavicon extends MobxComponent<Props, State> {

  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.website.favicon;
  }

  render() {
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.website.favicon = values;
          const update = {
            $set: { "website.favicon": values },
          };
          return { r, update };
        }}
        validators={{}}
        initialValues={this.initialValues}
        onSuccess={() => {}}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <div className="p-4">

              <FastField
                name="image"
                render={({ field }: any) => (
                  <FormGroup help="A square icon that is typically used as an site icon in various contexts. If your image is not square, it will be cropped from the center. We recommend a 350x350 pixel square">
                    <FormUpload
                      maxSize={400}
                      imagesOnly={true}
                      inputAcceptTypes="image/png"
                      value={[field.value]}
                      onRemove={() => setFieldValue("image", null)}
                      onChange={(files) => setFieldValue("image", files[0])}/>
                  </FormGroup>
                )}
              />

              {error && <FormGroup error={error}/>}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white"/>}
                {!isSubmitting && "Save"}
              </Button>

            </div>
          );
        }}
      </RestaurantForm>
    );
  }

}
