import * as React from "react";
import {logger, cc} from "@lib/common";
import { UI } from "../../../../../core/ui";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components";
import {withTranslation, WithTranslation} from "react-i18next";
import { Untrusive } from "../../../../../core/libs/untrusive";
import {Button, Tag, ItemListItem, ItemListContent, ItemListWrapper } from "@lib/components";


interface Props extends WithTranslation {}
interface State {}

const DetailTag = (props: { text: string; tag: string }) => (
  <div className="flex-line centered flex-no-wrap p-tb-1">
    <p className="">{props.text}</p>
    <Tag className="m-l-2">{props.tag}</Tag>
  </div>
);

function ChargebeeDetails({ t, r }: { t: any, r: T.API.DashboardRestaurantsResponseItem }) {
  const cb = r.billing.cb;
  return (
    <>
      <DetailTag
        text="Total orders"
        tag={t("number", { value: r.counts.services.total })}
      />
      <DetailTag
        text="Orders this cycle"
        tag={t("number", { value: cb.outstanding })}
      />
      <DetailTag
        text="Next billing"
        tag={t("dateFromTimestamp", { value: cb.next_billing_at })}
      />
    </>
  );
}

@inject("store") @observer
class DashboardRestaurantsListClass extends MobxComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  view = async (index: number | null) => {
    if (index === null) return;
    const { store } = this.injected;
    const { restrictions } = store;

    if (!restrictions._.restaurantView) {
      return;
    }

    const rr = restrictions.restaurant;
    const r = store.restaurants.items[index];
    let path = `/restaurant/${r._id}`;

    if (rr.dashboard) {
      path = `/restaurant/${r._id}`;
    }
    else if (restrictions._.restaurantOrderViews.length > 0) {
      path = `/restaurant/${r._id}/orders`;
    }
    else if (rr.bookings) {
      path = `/restaurant/${r._id}/bookings`;
    }
    else if (rr.customers) {
      path = `/restaurant/${r._id}/customers`;
    }
    else if (rr.menus) {
      path = `/restaurant/${r._id}/menus`;
    }
    else if (restrictions._.restaurantSettingsEnabled) {
      path = `/restaurant/${r._id}/settings`;
    }

    store.router.push(path);
  }

  delete = async (index: number | null) => {
    if (index === null) return;
    const { store } = this.injected;
    try {

      const p = prompt("Are you sure you want to delete this restaurant. Once deleted, none of it's data can be recovered. Enter 'delete' into the field below to proceed with delation");

      if (p === null) { return; }

      if (p.toLowerCase() !== "delete") {
        UI.notification.error("Enter 'delete' to delete the restaurant");
        return;
      }

      Untrusive.start();

      const r = store.restaurants.items[index];
      const res = await this.injected.store.api.restaurant_delete({ _id: r._id });

      Untrusive.stop();

      if (res.outcome) {
        UI.notification.error(res.message);
        return;
      }

      const restaurants = [ ...store.restaurants.items ];
      restaurants.splice(index, 1);

      this.setState({ delete_modal_active: null });
      store.updateRestaurants({ items: restaurants });

      UI.notification.success("Restaurant deleted");

    }
    catch (e) {
      logger.captureException(e);
      UI.notification.error("Something went wrong, try again or contact us");
      Untrusive.stop();
    }
  }

  render() {
    const { store, t } = this.injected;
    const { restrictions } = store;
    const canViewRestaurant = restrictions._.restaurantView;
    return (
      <ItemListWrapper>
        <ItemListContent>
          {store.restaurants.items.map((r, k) => {
            const cb = r.billing.cb;
            const store_url = r.domain ? `https://${r.domain}` : `https://${r.subdomain}.${cc.hosts.stores}`;
            return (
              <ItemListItem
                key={k}
                id={`restaurant-list-item-${k}`}
                style={{ cursor: canViewRestaurant ? "cursor" : "inherit" }}
                onClick={() => this.view(k)}>
                <div className="p-lr-6 p-t-5 p-b-4 flex-grow">

                  <div className="flex-l-r-center">
                    <h4 className="big m-r-4">{r.name}</h4>
                    {!!cb && (
                      <Tag
                        text="white"
                        style={{ padding: "4px 8px", borderRadius: "30px" }}
                        background={cb.status === "cancelled" ? store.theme.s.colors.error : store.theme.s.colors.success}>
                        {cb.status === "cancelled" ? "Cancelled" : "Active"}
                      </Tag>
                    )}
                  </div>

                  {!!cb && (
                    <div className="flex-line centered flex-wrap child-mr-20 m-t-2">
                      <ChargebeeDetails t={t} r={r}/>
                    </div>
                  )}

                  <div className="flex-line centered child-mr-10 m-t-2">

                    {canViewRestaurant && (
                      <Button
                        id={`restaurant-list-item-view-store-${k}`}
                        size="xxs"
                        color="primary-inverse"
                        className="p-lr-2"
                        disableHoverShadow={true}
                        onClick={() => this.view(k)}>
                        Manage
                      </Button>
                    )}

                    <Button
                      id={`restaurant-list-item-view-store-${k}`}
                      size="xxs"
                      color="primary-inverse"
                      className="p-lr-2"
                      disableHoverShadow={true}
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(store_url);
                      }}>
                      View Store
                    </Button>

                    {(restrictions.restaurant.billing) && (
                      <Button
                        id={`restaurant-list-item-billing-${k}`}
                        size="xxs"
                        color="primary-inverse"
                        className="p-lr-2"
                        disableHoverShadow={true}
                        onClick={(e) => {
                          e.stopPropagation();
                          store.billing.initChargebeeSession(r._id);
                        }}>
                        Billing
                      </Button>
                    )}

                  </div>

                </div>
              </ItemListItem>
            );
          })}
        </ItemListContent>
      </ItemListWrapper>
    );
  }

}

export const DashboardRestaurantsList = withTranslation()(DashboardRestaurantsListClass);
