import autobind from "autobind-decorator";
import throttle from "lodash/throttle";
import { computed, observable, action } from "mobx";
import { RouterStore, RouterState } from "@lib/router";
import { ThemeStore } from "./stores/theme";
import { BillingStore } from "./stores/billing";
import { ServiceStore } from "./stores/service";
import { NotificationsStore } from "./stores/notifications";
import { APIStore } from "./stores/api";
import {routeIsMatch} from "../routes";
import {generateStaffRestrictions} from "../react/ui/dashboard/views/staff";
import { GenericListBoardCollection } from "@lib/components";
import {PaymentMethods, cloneDeepSafe, logger, CoreUtils, OrderUtils, cc} from "@lib/common";
import localStore from "store";
import {IntlStore} from "@lib/intl";
import {ViewState, AblyState, AuthState, LoaderState} from "./types.d";

interface GenericQueryCollection<T> {
  items: T[];
  count: number;
  page: number;
}
interface GenericAsyncListCollection<T> {
  loading: boolean;
  error: string;
  items: T[];
}

type RestaurantsStore = GenericAsyncListCollection<T.API.DashboardRestaurantsResponseItem>;
type StaffStore = GenericAsyncListCollection<T.Models.User.Schema>;
type APISStore = GenericAsyncListCollection<T.Models.API.Schema>;

interface OrdersView {
  layout: 0 | 1;
  boardSize: 2 | 3 | 4 | 5;
  hideUnconfirmed: boolean;
}

@autobind
export class RootStore {

  theme: ThemeStore;
  router: RouterStore;
  intl: IntlStore;
  api: APIStore;
  service: ServiceStore;
  notifications: NotificationsStore;
  billing: BillingStore;

  routeChangeCount: number = 0;

  @observable auth: AuthState;
  @observable loader: LoaderState;
  @observable view: ViewState;
  @observable ably: AblyState;

  @observable organisation: T.Models.Organisation.Schema | null;
  @observable website: T.Models.Website.Schema | null;
  @observable restaurants: RestaurantsStore;
  @observable staff: StaffStore;
  @observable apis: APISStore;

  @observable restaurant: T.Models.Restaurant.Schema | null;

  @observable customers: GenericQueryCollection<T.Models.Customer.Schema>;
  @observable customer: T.Models.Customer.Schema | null;

  @observable ordersView: OrdersView;
  @observable ordersBoard: GenericListBoardCollection<T.Models.Order.Schema>;
  @observable orders: GenericQueryCollection<T.Models.Order.Schema>;
  @observable order: T.Models.Order.Schema | null;

  @observable bookings: GenericQueryCollection<T.Models.Booking.Schema>;
  @observable booking: T.Models.Booking.Schema | null;

  // simply pass a parsed serialized state, the reason it's not auto parsed is to enable type safe construction if needed
  constructor() {

    this.auth = {
      type: null,
      item: null,
      token: null,
      decoded: null,
      fetching: false,
      error: null,
    };

    this.view = {
      breakpoint: "md",
      screen_width: 720,
      scroll_top: 0,
    };

    this.loader = {
      active: true,
      opacity: 1,
      title: "Loading...",
      message: "This can take up to one minute the first time or if an update has been released",
    };

    this.ably = {
      status: "disconnected",
      connected_once: false,
      printers: [],
    };

    this.organisation = null;
    this.website = null;
    this.restaurants = observable({
      loading: false,
      error: "",
      items: [],
    });
    this.staff = observable({
      loading: false,
      error: "",
      items: [],
    });
    this.apis = observable({
      loading: false,
      error: "",
      items: [],
    });

    this.restaurant = null;

    this.customers = {
      items: [],
      count: 0,
      page: 0,
    };
    this.customer = null;

    const orderViewSettings = localStore.get("store-ordersView") || {};
    this.ordersView = {
      layout: orderViewSettings.layout ? parseInt(orderViewSettings.layout, 10) as 0 | 1 : 0,
      boardSize: orderViewSettings.boardSize ? parseInt(orderViewSettings.boardSize, 10) as 2 | 3 | 4 | 5 : 3,
      hideUnconfirmed: orderViewSettings.hideUnconfirmed !== undefined ? orderViewSettings.hideUnconfirmed : false,
    };
    this.ordersBoard = {
      loading: false,
      error: "",
      lists: {},
    };
    this.orders = {
      items: [],
      count: 0,
      page: 0,
    };
    this.order = null;

    this.bookings = {
      items: [],
      count: 0,
      page: 0,
    };
    this.booking = null;

    this.theme = new ThemeStore(this);
    this.router = new RouterStore(undefined, this.routeOnChange);

    this.intl = new IntlStore({
      useReactModule: true,
    });

    this.notifications = new NotificationsStore(this);
    this.service = new ServiceStore(this);
    this.billing = new BillingStore(this);
    this.api = new APIStore(this, {
      auth_token_error: this.service.handle_auth_token_error,
    });

    this.routeOnChange(this.router.s);
    this.windowResize();
    this.windowScroll();
    window.addEventListener("resize", throttle(this.windowResize, 100));
    document.addEventListener("scroll", throttle(this.windowScroll, 50));
  }

  // LOGIN
  @action routeOnChange = (s: RouterState) => {
    try {

      const { restrictions, auth } = this;

      // INC
      this.routeChangeCount++;

      // GET ROUTE
      const route = routeIsMatch(s.path);

      if (!route) { // NOT FOUND
        this.router.set404(true);
        document.title = "404 - Not Found";
      }
      else { // FOUND

        this.router.set404(false);

        document.title = route.title;

        if (this.routeChangeCount > 1 && route.auth && !auth.token) { // NOT AUTHENTICATED
          logger.info("ROUTE TAKE TO LOGIN");
          this.router.push("/login");
        }
        else if (
          auth.item &&
          auth.item.type === "staff" &&
          route.match &&
          route.match.rid &&
          restrictions.restaurants.indexOf(route.match.rid) === -1
        ) {
          logger.info("ROUTE TAKE TO / RID");
          this.router.push("/");
        }
        else if (route.restriction_keys) {

          logger.info("ROUTE RESTRICTIONS");

          let is_restricted = true;

          for (const restriction_key of route.restriction_keys) {
            let available;
            const access_keys = restriction_key.split(".");
            if (access_keys.length === 3) {
              // @ts-ignore
              available = restrictions[access_keys[0]][access_keys[1]][access_keys[2]];
            }
            else {
              // @ts-ignore
              available = restrictions[access_keys[0]][access_keys[1]];
            }
            if (available) {
              is_restricted = false;
              break;
            }
          }

          if (is_restricted) {
            logger.info("ROUTE RESTRICTIONS TAKE TO /");
            this.router.push("/");
          }

        }

        // SCROLL TOP
        const sr = document.getElementById("scroll-root");
        const noScrollPages = ["restaurant_bookings", "restaurant_orders", "restaurant_customers"];
        if (sr && sr.scroll && noScrollPages.indexOf(route.key) === -1) {
          sr.scroll({ top: 0, left: 0, behavior: "auto" });
        }

      }

    }
    catch (err) {
      logger.captureException(err, "ROUTE CHANGE ERROR");
    }
  }

  @computed get showMainUserSupport() {
    const user = this.auth.item;
    return user && user.type !== "staff";
  }

  @computed get isStaff() {
    if (this.auth.item) {
      return this.auth.item.type === "staff";
    }
    return false;
  }

  @computed get restrictions() {

    let re;
    if (this.auth.item) {
      re = cloneDeepSafe(this.auth.item.restrictions) || generateStaffRestrictions();
    }
    else {
      re = generateStaffRestrictions();
    }

    const rr = re.restaurant;

    let restaurantSettingsEnabled = false;
    if (re.restaurant.settings_detail) {
      const sd = re.restaurant.settings_detail;
      if (sd.system || sd.services || sd.payments || sd.website || sd.integrations) {
        restaurantSettingsEnabled = true;
      }
    }
    else if (re.restaurant.settings) {
      restaurantSettingsEnabled = true;
    }

    const restaurantOrderViews: string[] = [];
    if (typeof re.restaurant.orders_list === "undefined") {
      if (re.restaurant.orders) {
        restaurantOrderViews.push("board");
        restaurantOrderViews.push("list");
      }
    }
    else {
      if (re.restaurant.orders_board)
        restaurantOrderViews.push("board");
      if (re.restaurant.orders_list)
        restaurantOrderViews.push("list");
    }

    const restaurantView = rr.dashboard || restaurantOrderViews.length > 0 || rr.bookings || rr.menus || rr.customers || restaurantSettingsEnabled;

    const restaurantNotificationsEnabled = restaurantOrderViews.length > 0 || rr.bookings || rr.customers || restaurantSettingsEnabled;

    return {
      ...re,
      _: {
        restaurantView,
        restaurantSettingsEnabled,
        restaurantOrderViews,
        restaurantNotificationsEnabled,
      },
    };

  }

  @computed get isMapped() {
    const r = this.restaurant;
    if (!r) return false;
    return r.location.map_data.type === "google_maps" || r.location.map_data.type === "osm";
  }

  @computed get storeURL() {
    const r = this.restaurant;
    if (!r) {
      return "";
    }
    if (!cc.production) {
      return "http://localhost:3000";
    }
    return r.domain ? `https://${r.domain}` : `https://${r.subdomain}.${cc.hosts.stores}`;
  }

  // UTILS
  getPaymentMethodName = (method: string) => {

    const r = this.restaurant;

    const isBaseMethod = PaymentMethods.indexOf(method as T.Models.Restaurant.Payments.TypesBase) !== -1;
    let paymentName = this.intl.i18n.t(`constants.payment.backend_method.${method}`);

    if (!r) {
      return isBaseMethod ? paymentName : method;
    }

    const paymentMethod = r.settings.payments[method];
    if (!isBaseMethod) {
      if (paymentMethod) {
        paymentName = paymentMethod.label || method;
      }
      else {
        paymentName = method;
      }
    }

    return paymentName;

  }

  // UPDATERS
  @action setAuth = (data: AuthState) => {
    this.auth = data;
  }
  @action updateAuth = (data: Partial<AuthState>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof AuthState];
        if (value !== undefined) {
          // @ts-ignore
          this.auth[key as keyof AuthState] = value;
        }
      }
    }
  }

  @action setView = (data: ViewState) => {
    this.view = data;
  }
  @action updateView = (data: Partial<ViewState>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof ViewState];
        if (value !== undefined) {
          // @ts-ignore
          this.view[key as keyof ViewState] = value;
        }
      }
    }
  }

  @action updateAbly = (data: Partial<AblyState>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof AblyState];
        if (value !== undefined) {
          // @ts-ignore
          this.ably[key as keyof AblyState] = value;
        }
      }
    }
  }

  @action setLoader = (data: LoaderState) => {
    this.loader = data;
  }
  @action updateLoader = (data: Partial<LoaderState>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof LoaderState];
        if (value !== undefined) {
          // @ts-ignore
          this.loader[key as keyof LoaderState] = value;
        }
      }
    }
  }
  @action toggleLoader = (data: boolean) => {
    this.loader.active = data;
  }

  @action setOrganisation = (obj: T.Models.Organisation.Schema | null) => {
    this.organisation = obj;
  }
  @action updateOrganisation = (data: Partial<T.Models.Organisation.Schema>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof T.Models.Organisation.Schema];
        if (value !== undefined && this.organisation) {
          // @ts-ignore
          this.organisation[key as keyof T.Models.Organisation.Schema] = value;
        }
      }
    }
  }

  @action setWebsite = (obj: T.Models.Website.Schema | null) => {
    this.website = obj;
  }
  @action updateWebsite = (data: Partial<T.Models.Website.Schema>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof T.Models.Website.Schema];
        if (value !== undefined && this.website) {
          // @ts-ignore
          this.website[key as keyof T.Models.Website.Schema] = value;
        }
      }
    }
  }

  @action setRestaurant = (r: T.Models.Restaurant.Schema | null) => {
    this.restaurant = r;
    if (r) {
      this.intl.set({
        lng: "en",
        currency: {
          ...r.settings.region.currency,
          step: CoreUtils.currency.precision_to_step(r.settings.region.currency.precision),
        },
        tz: r.settings.region.timezone,
        locale: r.settings.region.locale,
        formats: r.settings.region.formats,
      });
    }
  }
  @action updateRestaurant = (data: Partial<T.Models.Restaurant.Schema>) => {

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof T.Models.Restaurant.Schema];
        if (value !== undefined && this.restaurant) {
          // @ts-ignore
          this.restaurant[key as keyof T.Models.Restaurant.Schema] = value;
        }
      }
    }

    if (data && data.settings && data.settings.region) {
      if (data.settings.region.timezone) {
        this.intl.update({ tz: data.settings.region.timezone });
      }
      if (data.settings.region.locale) {
        this.intl.update({ locale: data.settings.region.locale });
      }
      if (data.settings.region.currency) {
        this.intl.update({
          currency: {
            ...data.settings.region.currency,
            step: CoreUtils.currency.precision_to_step(data.settings.region.currency.precision),
          },
        });
      }
      if (data.settings.region.formats && data.settings.region.currency) {
        this.intl.update({
          formats: data.settings.region.formats,
        });
      }
    }

  }
  @action updateRestaurants = (data: Partial<RestaurantsStore>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof RestaurantsStore];
        if (value !== undefined && this.restaurants) {
          // @ts-ignore
          this.restaurants[key as keyof RestaurantsStore] = value;
        }
      }
    }
  }
  @action updateRestaurantComplete = (_id: string, update: Partial<T.Models.Restaurant.Schema>) => {

    // UPDATE SINGLE
    if (this.restaurant && this.restaurant._id === _id) {
      this.updateRestaurant(update);
    }

    // UPDATE COLLECTION
    const items = [ ...this.restaurants.items ];
    for (const [i, o] of items.entries()) {
      if (o._id === _id) {
        items[i] = { ...items[i], ...update };
        this.updateRestaurants({ items });
        break;
      }
    }

  }

  @action updateStaff = (data: Partial<StaffStore>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof StaffStore];
        if (value !== undefined && this.staff) {
          // @ts-ignore
          this.staff[key as keyof StaffStore] = value;
        }
      }
    }
  }
  @action updateApis = (data: Partial<APISStore>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof APISStore];
        if (value !== undefined && this.apis) {
          // @ts-ignore
          this.apis[key as keyof APISStore] = value;
        }
      }
    }
  }

  @action setOrder = (data: T.Models.Order.Schema | null) => {
    this.order = data;
  }
  @action updateOrder = (data: Partial<T.Models.Order.Schema>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof T.Models.Order.Schema];
        if (value !== undefined && this.order) {
          // @ts-ignore
          this.order[key as keyof T.Models.Order.Schema] = value;
        }
      }
    }
  }
  @action updateOrders = (data: Partial<GenericQueryCollection<T.Models.Order.Schema>>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof GenericQueryCollection<T.Models.Order.Schema>];
        if (value !== undefined && this.orders) {
          // @ts-ignore
          this.orders[key as keyof GenericQueryCollection<T.Models.Order.Schema>] = value;
        }
      }
    }
  }
  @action updateOrdersBoard = (data: Partial<GenericListBoardCollection<T.Models.Order.Schema>>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof GenericListBoardCollection<T.Models.Order.Schema>];
        if (value !== undefined && this.ordersBoard) {
          // @ts-ignore
          this.ordersBoard[key as keyof GenericListBoardCollection<T.Models.Order.Schema>] = value;
        }
      }
    }
  }
  @action updateOrderComplete = (order: T.Models.Order.Schema) => {

    const r = this.restaurant;

    if (r) {

      const tz = r.settings.region.timezone;

      // UPDATE SINGLE
      if (this.order && this.order._id === order._id) {
        this.order = order;
      }

      // UPDATE COLLECTION
      if (this.orders.items.length > 0) {
        for (const [i, o] of this.orders.items.entries()) {
          if (o._id === order._id) {
            this.orders.items[i] = order;
            break;
          }
        }
      }

      // UPDATE LISTBOARD
      const nextListId = OrderUtils.getOrderManagementStatus(order, tz);

      let listId = "";
      for (const key in this.ordersBoard.lists) {
        if (this.ordersBoard.lists[key]) {
          let index = -1;
          index = this.ordersBoard.lists[key].items.findIndex((o) => o._id === order._id);
          if (index !== -1) {
            listId = key;
            break;
          }
        }
      }

      if (listId) {

        // SPLICE
        const itemIndex = this.ordersBoard.lists[listId].items.findIndex((o) => o._id === order._id);
        this.ordersBoard.lists[listId].items.splice(itemIndex, 1);

        // PUSH
        if (nextListId === "complete" || nextListId === "cancelled") {
          if (this.ordersBoard.lists[nextListId].items.length >= 5) {
            this.ordersBoard.lists[nextListId].items.pop();
          }
          this.ordersBoard.lists[nextListId].items.unshift(order);
        }
        else {
          this.ordersBoard.lists[nextListId].items.push(order);
          // tslint:disable-next-line
          this.ordersBoard.lists[nextListId].items = this.ordersBoard.lists[nextListId].items.slice().sort(OrderUtils.sortFunctionByStatus(nextListId, tz));
        }

      }

    }

  }
  @action removeOrder = (_id: string) => {
    // UPDATE SINGLE
    if (this.order && this.order._id === _id) {
      this.setOrder(null);
    }
    // UPDATE COLLECTION
    const orders = [ ...this.orders.items ];
    for (const [i, o] of orders.entries()) {
      if (o._id === _id) {
        orders.splice(i, 1);
        this.updateOrders({ items: orders });
        break;
      }
    }
    // UPDATE BOARD
    for (const key in this.ordersBoard.lists) {
      if (this.ordersBoard.lists[key]) {
        const index = this.ordersBoard.lists[key].items.findIndex((o) => o._id === _id);
        if (index !== -1) {
          this.ordersBoard.lists[key].items.splice(index, 1);
          break;
        }
      }
    }
  }

  @action setBooking = (data: T.Models.Booking.Schema | null) => {
    this.booking = data;
  }
  @action updateBooking = (data: Partial<T.Models.Booking.Schema>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof T.Models.Booking.Schema];
        if (value !== undefined && this.booking) {
          // @ts-ignore
          this.booking[key as keyof T.Models.Booking.Schema] = value;
        }
      }
    }
  }
  @action updateBookingComplete = (item: T.Models.Booking.Schema) => {
    // UPDATE SINGLE
    this.setBooking(item);
    // UPDATE COLLECTION
    const items = [ ...this.bookings.items ];
    for (const [i, o] of items.entries()) {
      if (o._id === item._id) {
        items[i] = item;
        this.updateBookings({ items });
        break;
      }
    }
  }
  @action updateBookings = (data: Partial<GenericQueryCollection<T.Models.Booking.Schema>>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof GenericQueryCollection<T.Models.Booking.Schema>];
        if (value !== undefined && this.bookings) {
          // @ts-ignore
          this.bookings[key as keyof GenericQueryCollection<T.Models.Booking.Schema>] = value;
        }
      }
    }
  }
  @action removeBooking = (_id: string) => {
    // UPDATE SINGLE
    if (this.booking && this.booking._id === _id) {
      this.setOrder(null);
    }
    // UPDATE COLLECTION
    const items = [ ...this.bookings.items ];
    for (const [i, o] of items.entries()) {
      if (o._id === _id) {
        items.splice(i, 1);
        this.updateBookings({ items });
        break;
      }
    }
  }

  @action updateCustomers = (data: Partial<GenericQueryCollection<T.Models.Customer.Schema>>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof GenericQueryCollection<T.Models.Customer.Schema>];
        if (value !== undefined && this.customers) {
          // @ts-ignore
          this.customers[key as keyof GenericQueryCollection<T.Models.Customer.Schema>] = value;
        }
      }
    }
  }
  @action setCustomer = (data: T.Models.Customer.Schema | null) => {
    this.customer = data;
  }
  @action updateCustomer = (data: Partial<T.Models.Customer.Schema>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof T.Models.Customer.Schema];
        if (value !== undefined && this.customer) {
          // @ts-ignore
          this.customer[key as keyof T.Models.Customer.Schema] = value;
        }
      }
    }
  }
  @action updateCustomerComplete = (item: T.Models.Customer.Schema) => {
    // UPDATE SINGLE
    this.setCustomer(item);
    // UPDATE COLLECTION
    const items = [ ...this.customers.items ];
    for (const [i, o] of items.entries()) {
      if (o._id === item._id) {
        items[i] = item;
        this.updateCustomers({ items });
        break;
      }
    }
  }
  @action removeCustomer = (_id: string) => {
    // UPDATE SINGLE
    if (this.customer && this.customer._id === _id) {
      this.setCustomer(null);
    }
    // UPDATE COLLECTION
    const items = [ ...this.customers.items ];
    for (const [i, o] of items.entries()) {
      if (o._id === _id) {
        items.splice(i, 1);
        this.updateCustomers({ items });
        break;
      }
    }
  }

  @action windowResize = () => {
    const width = window.innerWidth;
    const breakpoint = CoreUtils.ui.breakpoint(width);
    this.view.screen_width = width;
    this.view.breakpoint = breakpoint;
  }
  @action windowScroll = () => {
    try {
      const h1 = window.pageYOffset;
      const h2 = document.documentElement ? document.documentElement.scrollTop : 0;
      const h3 = document.body.scrollTop;
      this.view.scroll_top = Math.max(h1, h2, h3);
    }
    catch (e) {
      logger.captureException(e, "SCROLL FUNCTION ERROR");
    }
  }

}
