import * as React from "react";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../mobx/components";
import Avatar from "react-avatar";
import {withTranslation, WithTranslation} from "react-i18next";
import {RestaurantCustomerModal} from "./modal";
import {RestaurantCustomerCreateModal} from "./create";
import {cc} from "@lib/common";
import { Button, Tooltip, Protable } from "@lib/components";
import { FaPlus } from "react-icons/fa";

interface Props extends WithTranslation {}
interface State {
  createActive: boolean;
}

@inject("store") @observer
export class RestaurantCustomersClass extends MobxComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      createActive: false,
    };
  }

  componentWillUnmount() {
    this.injected.store.updateCustomers({
      items: [],
      count: 0,
    });
  }

  createModalToggle = (createActive: boolean) => this.setState({ createActive });

  render() {
    if (!this.state) return;
    const { store, t } = this.injected;
    const { restrictions } = store;
    const customers = store.customers;
    const r = store.restaurant!;
    const rid = r._id;
    return (
      <div>

        <div className="flex-l-r-center m-b-6">
          <h1 className="m-r-2">Customers</h1>
          <div className="flex-line centered">
            {restrictions.restaurant.customers_actions && (
              <Tooltip text={"Create Customer"} width={120} position={"top"}>
                <a onClick={() => this.createModalToggle(true)}>
                  <Button size="xxs" color="primary-inverse" className="p-lr-1">
                    <FaPlus/>
                  </Button>
                </a>
              </Tooltip>
            )}
          </div>
        </div>

        <Protable<T.Models.Customer.Schema>
          region={r.settings.region}
          items={customers.items}
          count={customers.count}
          exportUrl={`${cc.api.url}/exports/dashboard/customers?jwt=${store.auth.token}&rid=${store.restaurant!._id}`}
          screenWidth={store.view.screen_width}
          query={{
            sortKeys: [
              { label: "Created", value: "created" },
              { label: "Last Seen", value: "meta.last_seen" },
              { label: "Name", value: "details.name" },
              { label: "Orders", value: "stats.orders" },
              { label: "Sales", value: "stats.sales" },
            ],
            filters: [
              {
                label: "Type",
                key: "type",
                values: [
                  { label: "Guest", value: "guest" },
                  { label: "E-Mail", value: "email" },
                  { label: "Facebook", value: "facebook" },
                  { label: "Google", value: "google" },
                ],
              },
            ],
          }}
          onClick={(customer) => {
            store.router.push(`/restaurant/${rid}/customers?_id=${customer._id}`);
            store.notifications.mark_read_object("customer", customer._id);
          }}
          fetch={async (data: T.API.RestaurantQueryRequest) => {
            data.query.restaurants = store.restaurant!._id;
            const response = await store.api.customers_find(data);
            if (response.outcome)
              throw new Error(response.message);
            store.updateCustomers({
              items: response.items,
              count: response.count,
            });
          }}
          rowAlert={(customer) => customer.age_verification.status === "pending"}
          columns={[
            {
              title: "",
              width: 60,
              render: (row) => {
                if (row.avatar) {
                  return (
                    <div className="text-center line-height-0">
                      <img alt="Customer avatar" src={row.avatar} className="thumbnail-32"/>
                    </div>
                  );
                }
                else {
                  return <div className="text-center line-height-0"><Avatar name={row.details.name} email={row.details.email} size="32"/></div>;
                }
              },
            },
            {
              title: "Type",
              breakpoint: 440,
              render: (row) => <span>{row.type.charAt(0).toUpperCase() + row.type.slice(1)}</span>,
            },
            {
              title: "Name",
              render: (row) => <span>{row.details.name || "N/A"}</span>,
            },
            {
              title: "Email",
              breakpoint: 750,
              render: (row) => <span>{row.details.email || "N/A"}</span>,
            },
            {
              title: "Verified",
              breakpoint: 1100,
              render: (row) => <span>{row.verified ? "Yes" : "No"}</span>,
            },
            {
              title: "Created",
              breakpoint: 1250,
              render: (row) => <span>{t("dateFromTimestamp", { value: row.created })}</span>,
            },
            {
              title: "Last Seen",
              breakpoint: 1350,
              render: (row) => (
                <span>
                  {row.meta.last_seen !== 0 ? t("datetimeFromTimestamp", { value: row.meta.last_seen }) : "N/A"}
                </span>
              ),
            },
            {
              title: "Sessions",
              breakpoint: 900,
              render: (row) => <span>{row.stats.sessions}</span>,
            },
            {
              title: "Orders",
              breakpoint: 600,
              render: (row) => <span>{row.stats.orders}</span>,
            },
            {
              title: "Sales",
              breakpoint: 500,
              render: (row) => <span>{t("currency", { value: row.stats.sales })}</span>,
            },
            {
              title: "Promos",
              breakpoint: 1000,
              render: (row) => <span>{row.stats.promos_count}</span>,
            },
          ]}
        />

        <RestaurantCustomerModal/>

        <RestaurantCustomerCreateModal
          active={this.state.createActive}
          close={() => this.createModalToggle(false)}
        />

      </div>
    );
  }

}

export const RestaurantCustomers = withTranslation()(RestaurantCustomersClass);
