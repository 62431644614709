import * as React from "react";
import { MenuList } from "./layout/list";
import { MenuListButton } from "./layout/button";
import { MenuListItem } from "./layout/item";
import shortid from "shortid";
import cloneDeep from "lodash/cloneDeep";
import { Button, RotateLoader, Modal, ModalContent } from "@lib/components";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { arrayMove } from "react-sortable-hoc";
import { RestaurantFormDishTags } from "./forms/tags";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../mobx/components";

interface Props {}
interface State {

  reorder: boolean;
  index_copy: number | null;
  index_panel: number | null;
  index_remove: number | null;

  loading_copy: boolean;
  loading_remove: boolean;
  loading_reorder: boolean;

}

@inject("store") @observer
export class RestaurantDishTags extends MobxComponent<Props, State> {

  backup: T.Models.Restaurant.Schema["dish_tags"] | null;

  constructor(props: Props) {
    super(props);
    this.state = {
      reorder: false,
      index_copy: null,
      index_panel: null,
      index_remove: null,
      loading_copy: false,
      loading_remove: false,
      loading_reorder: false,
    };
    this.backup = null;
  }

  create_item = (): T.Models.Restaurant.Menus.DishTag => {
    return {
      _id: shortid.generate(),
      name: "",
      type: "none",
      text: "Example",
      icon: "",
      color: {
        icon: {
          background: "#fff",
          text: "#ff7d4b",
        },
        wrapper: {
          background: "#ff7d4b",
          text: "#fff",
        },
      },
    };
  }

  list_item = () => {
    const { reorder, loading_reorder } = this.state;
    const r = this.injected.store.restaurant!;
    const { dish_tags } = r;
    return (
      <MenuList id="dish-tag-list" type="DISHTAG">

        {dish_tags.map((item, i) => {
          return (
            <MenuListItem
              key={i}
              type="DISHTAG"
              id={item._id}
              dragDisabled={!reorder}
              name={item.name}
              index={i}
              onEdit={() => this.panel_set(i)}
              onRemove={() => this.modal_remove_open(i)}
              onCopy={() => this.modal_copy_open(i)}
            />
          );
        })}

        {!reorder && [
          <MenuListButton key="btn-create" onClick={() => this.panel_set(-1)}>
            Create New Dish Tag
          </MenuListButton>,
          <MenuListButton key="btn-arrange" onClick={this.reorder_start}>
            Re-arrange
          </MenuListButton>,
        ]}

        {reorder && (
          <div>
            <div className="grid-2 xs xs-gap">
              <div className="col">
                <MenuListButton onClick={this.reorder_save}>
                  {loading_reorder && <RotateLoader size={2} color="white"/>}
                  {!loading_reorder && "Save"}
                </MenuListButton>
              </div>
              <div className="col">
                <MenuListButton onClick={this.reorder_cancel}>
                  Cancel
                </MenuListButton>
              </div>
            </div>
            <div className="text-center m-t-3">
              <p>Click + hold to drag and drop items to re-order them</p>
            </div>
          </div>
        )}

      </MenuList>
    );
  }

  panel_set = (index_panel: number | null) => {
    this.setState({ index_panel });
  }
  panel_create = () => {
    const r = this.injected.store.restaurant!;
    const { index_panel } = this.state;

    let initialValues = null;
    if (index_panel !== null) {
      if (index_panel === -1) {
        initialValues = this.create_item();
      }
      else {
        const item = r.dish_tags[index_panel];
        initialValues = cloneDeep(item);
      }
    }

    return (
      <RestaurantFormDishTags
        type={index_panel === -1 ? "create" : "edit"}
        initialValues={initialValues}
        close={() =>  this.panel_set(null)}/>
    );
  }

  copy = async (index: number) => {
    await this.saveRestaurant({
      successMsg: "Tag copied",
      process: (r) => {
        const dish_tag = cloneDeep(r.dish_tags[index]);
        dish_tag._id = shortid.generate();
        dish_tag.name = `${dish_tag.name} - Copy`;
        r.dish_tags.splice(index + 1, 0, dish_tag);
        return {
          update: { $set: { dish_tags: r.dish_tags } },
        };
      },
      before: () => this.setState({ loading_copy: true }),
      onSuccess: () => this.modal_copy_close(),
      onFail: () => this.setState({ loading_copy: false }),
      onError: () => this.setState({ loading_copy: false }),
    });
  }
  remove = async (index: number) => {
    await this.saveRestaurant({
      successMsg: "Tag deleted",
      process: (r) => {
        r.dish_tags.splice(index, 1);
        return {
          update: { $set: { dish_tags: r.dish_tags } },
        };
      },
      before: () => this.setState({ loading_remove: true }),
      onSuccess: () => this.modal_remove_close(),
      onFail: () => this.setState({ loading_remove: false }),
      onError: () => this.setState({ loading_remove: false }),
    });
  }

  modal_copy_open = (index: number | null) => {
    this.setState({
      index_copy: index,
      loading_copy: false,
    });
  }
  modal_copy_close = () => {
    this.setState({
      index_copy: null,
      loading_copy: false,
    });
  }
  modal_remove_open = (index: number | null) => {
    this.setState({
      index_remove: index,
      loading_remove: false,
    });
  }
  modal_remove_close = () => {
    this.setState({
      index_remove: null,
      loading_remove: false,
    });
  }

  modal_copy = () => {
    const r = this.injected.store.restaurant!;
    const { index_copy, loading_copy } = this.state;

    let item;
    let fn;
    if (index_copy !== null) {
      item = r.dish_tags[index_copy];
      fn = () => this.copy(index_copy);
    }

    return (
      <Modal
        width="sm"
        close={this.modal_copy_close}
        closeButton={false}
        active={!!item}>
        <ModalContent className="flex-l-r-center">
          <h4 className="">Copy Dish Tag</h4>
          <p className="big underline">{item && item.name}</p>
        </ModalContent>
        <ModalContent className="flex-right">
          <Button type="button" className="m-r-2 width100 max100px" onClick={this.modal_copy_close}>Cancel</Button>
          <Button type="button" className="width100 max100px" color="primary" onClick={fn}>
            {loading_copy && <RotateLoader size={2} color="white"/>}
            {!loading_copy && "Copy"}
          </Button>
        </ModalContent>
      </Modal>
    );
  }
  modal_remove = () => {
    const r = this.injected.store.restaurant!;
    const { index_remove, loading_remove } = this.state;

    let item;
    let fn;
    if (index_remove !== null) {
      item = r.dish_tags[index_remove];
      fn = () => this.remove(index_remove);
    }

    return (
      <Modal
        width="sm"
        close={this.modal_remove_close}
        closeButton={false}
        active={!!item}>
        <ModalContent className="flex-l-r-center">
          <h4 className="">Delete Dish Tag</h4>
          <p className="big underline">{item && item.name}</p>
        </ModalContent>
        <ModalContent className="flex-right">
          <Button type="button" className="m-r-2 width100 max100px" onClick={this.modal_remove_close}>Cancel</Button>
          <Button type="button" className="width100 max100px" color="primary" onClick={fn}>
            {loading_remove && <RotateLoader size={2} color="white"/>}
            {!loading_remove && "Delete"}
          </Button>
        </ModalContent>
      </Modal>
    );
  }

  reorder_start = () => {
    this.setState({ reorder: true });
    this.backup = cloneDeep(this.injected.store.restaurant!.dish_tags);
  }
  reorder_cancel = () => {
    this.injected.store.updateRestaurant({
      dish_tags: this.backup ? this.backup : [],
    });
    this.setState({ reorder: false });
  }
  reorder_save = async () => {
    const r = this.injected.store.restaurant!;
    const backup = cloneDeep(r);
    if (this.backup)
      backup.dish_tags = this.backup;
    await this.saveRestaurantSilent({
      backup: backup,
      update: { $set: { dish_tags: r.dish_tags } },
      successMsg: "Tags re-arranged",
      before: () => this.setState({ loading_reorder: true }),
      onSuccess: () => {
        this.setState({ reorder: false, loading_reorder: false });
        this.backup = null;
      },
      onFail: () => this.setState({ loading_reorder: false }),
      onError: () => this.setState({ loading_reorder: false }),
    });
  }

  onDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination) return;
    const r = { ...this.injected.store.restaurant! };
    if (destination.index !== source.index) {
      r.dish_tags = arrayMove(r.dish_tags, source.index, destination.index);
      this.injected.store.setRestaurant(r);
    }
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        {this.list_item()}
        {this.panel_create()}
        {this.modal_copy()}
        {this.modal_remove()}
      </DragDropContext>
    );
  }

}
