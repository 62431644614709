import * as React from "react";
import { FastField} from "formik";
import {MobxComponent} from "../../../../../../../mobx/components";
import {inject, observer} from "mobx-react";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {UI} from "../../../../../../../core/ui";
import {PaymentBaseFields} from "./base";
import { CurrenciesList } from "@lib/common";
import { FormGroup, Button, RotateLoader, Input, Switch, SelectAdv, LinkTag } from "@lib/components";

interface Props {}
interface State {}
type FormValues = T.Models.Restaurant.Payments.Stripe;

@inject("store") @observer
export class SettingsFormPaymentsStripe extends MobxComponent<Props, State> {

  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.payments.stripe || {
      enabled: false,
      label: "",
      services: [],
      secret_key: "",
      publishable_key: "",
      currency: "",
      disable_email_receipt: false,
      custom_payment_email: "",
    };
  }

  render() {
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.payments.stripe = values;
          const update = { $set: { "settings.payments.stripe": r.settings.payments.stripe } };
          return { r, update };
        }}
        validators={{}}
        initialValues={this.initialValues}
        onSuccess={() => {
        }}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({form, error}) => {
          const {isSubmitting, setFieldValue} = form;
          return (
            <div className="p-4">

              <FastField
                name="enabled"
                render={({field}: any) => (
                  <FormGroup
                    title="Enabled"
                    help="Allows customers to pay using this method">
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={(e) => setFieldValue("enabled", e.target.checked)}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="secret_key"
                render={({field}: any) => (
                  <FormGroup
                    title="Stripe Secret Key"
                    help="Your Stripe secret API key, do not to share this with anyone">
                    <Input type="text" {...field} autoSave="false" autoCorrect="false"/>
                  </FormGroup>
                )}
              />

              <FastField
                name="publishable_key"
                render={({field}: any) => (
                  <FormGroup
                    title="Stripe Publishable Key"
                    help="Your Stripe publishable API key that will be used to initiate online charges">
                    <Input type="text" {...field} autoSave="false" autoCorrect="false"/>
                  </FormGroup>
                )}
              />

              <FastField
                name="currency"
                render={({field}: any) => (
                  <FormGroup
                    title="Currency"
                    help="Select the currency you would like to accept Stripe charges in. Make sure you are authorized to accept charges in that currency and that it is supported by Stripe">
                    <SelectAdv
                      type="single"
                      options={CurrenciesList}
                      value={field.value}
                      onChange={(code: string) => setFieldValue("currency", code)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="disable_email_receipt"
                render={({field}: any) => (
                  <FormGroup
                    title="Disable E-Mail Receipt"
                    help="Toggle this to disable Stripe payment receipts from being sent to your customer's e-mail">
                    <Switch
                      id="disable_email_receipt-switch"
                      checked={field.value || false}
                      onChange={(e) => setFieldValue("disable_email_receipt", e.target.checked)}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="custom_payment_email"
                render={({field}: any) => (
                  <FormGroup
                    optional={true}
                    title="Enable Custom Payment Form"
                    help="Entering an e-mail address will enable the custom payment form at your-store-domain.com/online-payment">
                    <Input
                      {...field}
                      type="email"
                      placeholder="Enter E-Mail Address For Payment Notification"
                      value={field.value || ""}
                    />
                  </FormGroup>
                )}
              />

              <PaymentBaseFields
                defaultLabel="Credit Card"
                setFieldValue={setFieldValue}
              />

              {error && <FormGroup error={error}/>}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white"/>}
                {!isSubmitting && "Save"}
              </Button>

            </div>
          );
        }}
      </RestaurantForm>
    );
  }

}
