import * as React from "react";
import { SettingsSection } from "./layout/section";
import { SettingsSectionBlock } from "./layout/block";
import { SettingsSectionHeading } from "./layout/heading";
import { SettingsFormGeneral } from "./forms/system/general";
import { SettingsFormLocation } from "./forms/system/location";
import { SettingsFormNotification } from "./forms/system/notifications";
import { SettingsFormNotificationsAudio } from "./forms/system/notifications-audio";
import { SettingsFormAccounts } from "./forms/system/accounts";
import { SettingsPromos } from "./forms/system/promos";
import { SettingsPrinters } from "./forms/system/printers";
import { SettingsFees } from "./forms/system/fees";
import { SettingsFormTerms } from "./forms/system/terms";
import { SettingsFormAgeVerification } from "./forms/system/age-verification";
import { SettingsFormMisc } from "./forms/system/misc";
import { SettingsFormListing } from "./forms/system/listing";
import { SettingsWebhooks } from "./forms/system/webhooks";
import { RestaurantSettingsPayments } from "./sections/payments";
import { RestaurantSettingsWebsite } from "./sections/website";
import { RestaurantSettingsIntegrations } from "./sections/integrations";
import { SettingsFormServicesPickup } from "./forms/services/pickup";
import { SettingsFormServicesDelivery } from "./forms/services/delivery";
import { SettingsFormServicesDineIn } from "./forms/services/dine_in";
import { SettingsFormServicesTableBooking } from "./forms/services/table_booking";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../mobx/components";
import { darken } from "polished";
import {FaQuestionCircle, FaTimes, FaCheck} from "react-icons/fa";
import { Content, Tooltip, Button, TabSelect, styled, IconCircle } from "@lib/components";

interface Props {}
interface State {
  tab: string;
  active: string;
}

const TabWrapper = styled("div")`
  border-bottom: 1px solid ${() => darken(0.1, "white")};
  .tab-select-label {
    font-size: 15px !important;
  }
`;

@inject("store") @observer
export class RestaurantSettings extends MobxComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      tab: this.getTabs()[0],
      active: "",
    };
  }

  getTabs() {
    const rr = this.injected.store.restrictions;
    if (rr.restaurant.settings_detail) {
      const sd = rr.restaurant.settings_detail;
      const available = [];
      if (sd.system) available.push("System");
      if (sd.services) available.push("Services");
      if (sd.payments) available.push("Payments");
      if (sd.website) available.push("Website");
      if (sd.integrations) available.push("Integrations");
      return available;
    }
    else if (rr.restaurant.settings) {
      return ["System", "Services", "Payments", "Website", "Integrations"];
    }
    return [];
  }

  setActive = (active: string) => {
    if (this.state.active === active)
      this.setState({ active: "" });
    else
      this.setState({ active });
  }

  render() {
    const { tab, active } = this.state;
    const { store } = this.injected;
    const { theme, restaurant } = store;
    const { services } = restaurant!.settings;
    const successIndicator = <IconCircle size={16} className={"m-r-2"} icon={<FaCheck/>} iconSizeModifier={6} background={theme.s.status_colors.complete}/>;
    const failIndicator = <IconCircle size={16} className={"m-r-2"} icon={<FaTimes/>} iconSizeModifier={6} background={"grey"}/>;
    return (
      <Content width="md">

        <div className="flex-l-r-center m-b-6">
          <h1 className="m-r-2">Settings</h1>
        </div>

        <TabWrapper className="m-t-6 bsm-1">
          <TabSelect
            id={"settings-tab-select"}
            value={tab}
            values={this.getTabs().map((t) => ({ label: t, value: t }))}
            onChange={(v) => this.setState({ tab: v.value, active: "" })}
            screenWidth={store.view.screen_width}
          />
        </TabWrapper>

        {tab === "System" && (
          <SettingsSection>
            <SettingsSectionHeading>
              <p>Business</p>
            </SettingsSectionHeading>
            <SettingsSectionBlock
              name="General"
              active={active === "General"}
              onClick={() => this.setActive("General")}>
              <SettingsFormGeneral/>
            </SettingsSectionBlock>
            <SettingsSectionBlock
              name="Location"
              active={active === "Location"}
              onClick={() => this.setActive("Location")}>
              <SettingsFormLocation/>
            </SettingsSectionBlock>
            <SettingsSectionBlock
              name="Promo Codes"
              active={active === "Promo Codes"}
              onClick={() => this.setActive("Promo Codes")}>
              <SettingsPromos/>
            </SettingsSectionBlock>
            <SettingsSectionBlock
              name="Conditional Fees"
              active={active === "Conditional Fees"}
              onClick={() => this.setActive("Conditional Fees")}>
              <SettingsFees/>
            </SettingsSectionBlock>
            <SettingsSectionBlock
              name="Terms & Conditions"
              active={active === "Terms & Conditions"}
              onClick={() => this.setActive("Terms & Conditions")}>
              <SettingsFormTerms/>
            </SettingsSectionBlock>
            <SettingsSectionHeading>
              <p>Functionality</p>
            </SettingsSectionHeading>
            <SettingsSectionBlock
              name="Receipt Printing"
              active={active === "Printing"}
              onClick={() => this.setActive("Printing")}>
              <SettingsPrinters/>
            </SettingsSectionBlock>
            <SettingsSectionBlock
              name="E-Mail Notifications"
              active={active === "E-Mail Notifications"}
              onClick={() => this.setActive("E-Mail Notifications")}>
              <SettingsFormNotification/>
            </SettingsSectionBlock>
            <SettingsSectionBlock
              name="Audio Notifications"
              active={active === "Audio Notifications"}
              onClick={() => this.setActive("Audio Notifications")}>
              <SettingsFormNotificationsAudio/>
            </SettingsSectionBlock>
            <SettingsSectionBlock
              name="Customer Accounts"
              active={active === "Customer Accounts"}
              onClick={() => this.setActive("Customer Accounts")}>
              <SettingsFormAccounts/>
            </SettingsSectionBlock>
            <SettingsSectionBlock
              name="Age Verification"
              active={active === "Age Verification"}
              onClick={() => this.setActive("Age Verification")}>
              <SettingsFormAgeVerification/>
            </SettingsSectionBlock>
            <SettingsSectionBlock
              name="Listing"
              active={active === "Listing"}
              onClick={() => this.setActive("Listing")}>
              <SettingsFormListing/>
            </SettingsSectionBlock>
            <SettingsSectionBlock
              name="Webhooks"
              active={active === "Webhooks"}
              onClick={() => this.setActive("Webhooks")}>
              <SettingsWebhooks/>
            </SettingsSectionBlock>
            <SettingsSectionBlock
              name="Misc."
              active={active === "Misc."}
              onClick={() => this.setActive("Misc.")}>
              <SettingsFormMisc/>
            </SettingsSectionBlock>
          </SettingsSection>
        )}

        {tab === "Services" && (
          <SettingsSection>
            <SettingsSectionBlock
              name={(
                <div className="flex-line centered">
                  {services.pickup.enabled ? successIndicator : failIndicator}
                  <span>Pickups</span>
                </div>
              )}
              active={active === "Pickups"}
              onClick={() => this.setActive("Pickups")}>
              <SettingsFormServicesPickup/>
            </SettingsSectionBlock>
            <SettingsSectionBlock
               name={(
                <div className="flex-line centered">
                  {services.delivery.enabled ? successIndicator : failIndicator}
                  <span>Deliveries</span>
                </div>
              )}
              active={active === "Deliveries"}
              onClick={() => this.setActive("Deliveries")}>
              <SettingsFormServicesDelivery/>
            </SettingsSectionBlock>
            <SettingsSectionBlock
               name={(
                <div className="flex-line centered">
                  {services.dine_in.enabled ? successIndicator : failIndicator}
                  <span>Dine-Ins</span>
                </div>
              )}
              active={active === "Dine-Ins"}
              onClick={() => this.setActive("Dine-Ins")}>
              <SettingsFormServicesDineIn/>
            </SettingsSectionBlock>
            <SettingsSectionBlock
               name={(
                <div className="flex-line centered">
                  {services.table_booking.enabled ? successIndicator : failIndicator}
                  <span>Table Bookings</span>
                </div>
              )}
              active={active === "Table Bookings"}
              onClick={() => this.setActive("Table Bookings")}>
              <SettingsFormServicesTableBooking/>
            </SettingsSectionBlock>
          </SettingsSection>
        )}

        {tab === "Payments" && <RestaurantSettingsPayments/>}
        {tab === "Website" && <RestaurantSettingsWebsite/>}
        {tab === "Integrations" && <RestaurantSettingsIntegrations/>}

      </Content>
    );
  }

}
