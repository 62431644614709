import "react-hot-loader";
import * as React from "react";
import * as ReactDOM from "react-dom";
import RenderApp from "./react/render";
import {RootStore} from "./mobx/store";

const store = new RootStore();

function renderApp() {
  const root = document.getElementById("root");
  if (root) {
    ReactDOM.render(<RenderApp store={store}/>, root);
  }
}

window.onload = () => {
  renderApp();
};

// @ts-ignore
if (module && module.hot) {
  // @ts-ignore
  module.hot.accept(renderApp);
}
