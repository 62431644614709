import * as React from "react";
import {MobxComponent} from "../../../../mobx/components";
import {withTranslation, WithTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import { FaUser } from "react-icons/fa";
import throttle from "lodash/throttle";
import { RotateLoader } from "@lib/components";
import {Header, List, Loader, Icon, Item, ItemContent, Stripe, ItemLoader} from "./list.components";
import moment from "moment-timezone";

interface Props extends WithTranslation {}
interface State {}

const event_colors = {
  age_verification: "#45709c",
  order_new: "#489b37",
};

@inject("store") @observer
class NotificationsListClass extends MobxComponent<Props, State> {

  list: React.RefObject<HTMLDivElement> = React.createRef();

  handleScroll = () => {
    const el = document.getElementById("notification-list");
    if (!el) return;
    const distanceFromBottom = (el.scrollHeight - el.scrollTop) - el.clientHeight;
    if (distanceFromBottom < 40) {
      this.injected.store.notifications.on_scroll_bottom();
    }
  }

  render() {
    const { t } = this.injected;
    const { notifications } = this.injected.store;
    const { items, loading, all_items_queried, unread } = notifications.s;
    return (
      <div>
        <Header>
          <h4>Notifications</h4>
          <p className="small font-bold">{unread} unread</p>
        </Header>
        {(items.length === 0 && loading) && (
          <Loader>
            <RotateLoader size={3}/>
          </Loader>
        )}
        {(items.length === 0 && !loading) && (
          <Loader>
            <h4 className="m-b-2">No notifications yet!</h4>
            <p className="lhp">The notifications panel will keep you up to date on the latest events such as new orders, bookings and customer requests</p>
          </Loader>
        )}
        {(items.length > 0) && (
          <List id="notification-list" onScroll={throttle(this.handleScroll, 200)}>
            {items.map((item, i) => {
              const { title, body, time, verb, is_read } = item;
              const date = moment.utc(time, moment.ISO_8601);
              return (
                <Item key={i} onClick={() => notifications.handle_click(item, i)}>
                  <Stripe style={{ background: is_read ? "transparent" : event_colors[verb as keyof typeof event_colors] }}/>
                  <ItemContent>
                    <div className="flex-line centered">
                      <Icon>
                        <FaUser/>
                      </Icon>
                      <div>
                        <p className="lhp font-semi-bold">{title}</p>
                        <p className="lhp">{body}</p>
                        {(date.isValid() && typeof date.valueOf() === "number") && (
                          <p className="small italic m-t-1">
                            {t("relative", { value: date.valueOf() })}
                          </p>
                        )}
                      </div>
                    </div>
                  </ItemContent>
                </Item>
              );
            })}
            {loading && (
              <ItemLoader>
                <RotateLoader size={2}/>
              </ItemLoader>
            )}
            {all_items_queried && (
              <ItemLoader>
                <p className="italic">No more notifications...</p>
              </ItemLoader>
            )}
          </List>
        )}
      </div>
    );
  }

}

export const NotificationsList = withTranslation()(NotificationsListClass);
