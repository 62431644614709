import * as React from "react";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../../mobx/components";
import styled from "styled-components";
import { SettingsSuccessIndicator, SettingsInactiveIndicator } from "../components";
import {SettingsFormIntegrationTookan} from "../forms/integrations/tookan";
import {SettingsFormIntegrationGoogleAnalytics} from "../forms/integrations/google-analytics";
import {SettingsFormIntegrationFacebookPixel} from "../forms/integrations/facebook-pixel";
import {SettingsFormIntegrationTransferWise} from "../forms/integrations/transferwise";
import { RestaurantUtils } from "@lib/common";
import { Tag, Modal, ModalTitle } from "@lib/components";
import {TransferWiseBalance} from "../../../../../components/data/TransferWiseBalance";

interface Props {}

interface State {
  activeModal: string;
}

interface ItemProps {
  title: string;
  description: string;
  tags: React.ReactNode[];
  image: string;
  active: boolean;
  setActive: () => void;
}

const ItemStatusWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 15px;
  background: rgb(225,225,225);
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 5px;
  transform: translateY(50%);
`;

const ItemWrapper = styled.div`
  background: white;
  cursor: pointer;
  transition: 0.23s box-shadow;
  &:hover {
    box-shadow: 0 0 22px rgba(0,0,0,0.20) !important; 
  }
`;

const Item = (props: ItemProps) => {
  const { title, description, tags, image, active, setActive } = props;
  return (
    <ItemWrapper className="col bsm-1 border-white-10" onClick={setActive}>
      <div className="p-lr-4 p-tb-8 flex-center border-white-b-10 relative">
        <img src={image} style={{ height: "40px", width: "auto" }}/>
        <ItemStatusWrapper>
          {active ? <SettingsSuccessIndicator/> : <SettingsInactiveIndicator/>}
          {active ? "Enabled" : "Disabled"}
        </ItemStatusWrapper>
      </div>
      <div className="p-tb-4 p-lr-5">
        <p className="bigger font-semi-bold">{title}</p>
        <p className="big m-tb-2 lhp">{description}</p>
        <div>
          {tags.map((t, i) => <Tag key={i} className="m-r-2">{t}</Tag>)}
        </div>
      </div>
    </ItemWrapper>
  );
};

@inject("store") @observer
export class RestaurantSettingsIntegrations extends MobxComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      activeModal: "",
    };
  }

  modalClose = () => this.setState({ activeModal: "" });

  render() {

    const { activeModal } = this.state;

    const { store } = this.injected;

    const r = store.restaurant!;

    const twActive = RestaurantUtils.settings.integrationTransferWise(r);

    const integrations = [
      {
        active: RestaurantUtils.settings.integrationFacebookPixelActive(r),
        image: "/images/integrations/facebook-pixel.png",
        title: "Facebook Pixel",
        description: "Add Facebook Pixel tracking to your website to track visitors and conversions",
        tags: ["Marketing & Tracking"],
      },
      {
        active: RestaurantUtils.settings.integrationGoogleAnalyticsActive(r),
        image: "/images/integrations/google-analytics.png",
        title: "Google Analytics",
        description: "Add google analytics tracking to your ordering site to track visitors",
        tags: ["Marketing & Tracking"],
      },
      {
        active: RestaurantUtils.settings.integrationTookanActive(r),
        image: "/images/integrations/tookan.png",
        title: "Tookan",
        description: "Automatically send delivery orders to Tookan for better delivery management",
        tags: ["Delivery Management"],
      },
      {
        active: twActive,
        image: "/images/integrations/transferwise.png",
        title: "TransferWise",
        description: "Automatically pay delivery drivers directly using your TransferWise bank account",
        tags: [
          "Delivery Management",
          twActive ? <TransferWiseBalance restaurant_id={r._id}/> : null,
        ],
      },
    ];

    return (
      <>

        <div className="m-t-4 grid-2 md sm-gap">

          {integrations.map((item, i) => (
            <Item
              {...item}
              key={i}
              setActive={() => this.setState({ activeModal: item.title })}
            />
          ))}

          {integrations.length % 2 !== 0 && <div className="col"/>}

        </div>

        <Modal
          width={600}
          active={!!activeModal}
          close={this.modalClose}>
          <ModalTitle className="round-top">
            <h4>{activeModal}</h4>
          </ModalTitle>
          {activeModal === "Tookan" && <SettingsFormIntegrationTookan close={this.modalClose}/>}
          {activeModal === "Google Analytics" && <SettingsFormIntegrationGoogleAnalytics close={this.modalClose}/>}
          {activeModal === "Facebook Pixel" && <SettingsFormIntegrationFacebookPixel close={this.modalClose}/>}
          {activeModal === "TransferWise" && <SettingsFormIntegrationTransferWise close={this.modalClose}/>}
        </Modal>

      </>
    );
  }

}
