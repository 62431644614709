import * as React from "react";
import { FastField} from "formik";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../../../mobx/components";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {UI} from "../../../../../../../core/ui";
import { FormGroup, Button, RotateLoader, Switch, Select } from "@lib/components";

interface Props {}
interface State {}
type FormValues = T.Models.Restaurant.Schema["website"]["sections"]["menus"];

@inject("store") @observer
export class SettingsFormDesignMenus extends MobxComponent<Props, State> {
  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    const r = this.injected.store.restaurant!;
    this.state = {};
    this.initialValues = r.website.sections.menus;
  }

  render() {
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.website.sections.menus = values;
          const update = { $set: { "website.sections.menus": values } };
          return { r, update };
        }}
        validators={{}}
        initialValues={this.initialValues}
        onSuccess={() => {}}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
          const { isSubmitting, setFieldValue, setFieldTouched } = form;
          return (
            <div className="p-4">

              <FastField
                name="dish_style"
                render={({ field }: any) => (
                  <FormGroup
                    title="Dish Style"
                    help="Determines the position of your dish image if there is one. List style shows the image to the left of the dish. Card puts it on the top. We recommend using list">
                    <Select
                      value={field.value}
                      options={[
                        { value: "list", label: "List" },
                        { value: "card", label: "Card" },
                      ]}
                      onChange={(e) => setFieldValue("dish_style", e.target.value)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="dish_tag_position"
                render={({ field }: any) => (
                  <FormGroup
                    title="Dish Tag Position"
                    help="Determines the position of your dish tags if there are any. Top edge is the default option">
                    <Select
                      value={field.value}
                      options={[
                        { value: "edge_top", label: "Top Edge" },
                        { value: "inside_bottom", label: "Inner Bottom" },
                      ]}
                      onChange={(e) => setFieldValue("dish_tag_position", e.target.value)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="dish_hide_description"
                render={({ field }: any) => (
                  <FormGroup
                    title="Hide Dish Descriptions"
                    help="Use this option to hide dish descriptions on your store menu grid. They will still show in the dish popup">
                    <Switch
                      id="dish_hide_description"
                      checked={field.value || false}
                      onChange={(e) => setFieldValue("dish_hide_description", e.target.checked)}/>
                  </FormGroup>
                )}
              />

              {error && <FormGroup error={error}/>}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white"/>}
                {!isSubmitting && "Save"}
              </Button>

            </div>
          );
        }}
      </RestaurantForm>
    );
  }
}
