import * as React from "react";
import { styled, ButtonProps, CS } from "@lib/components";
import { darken } from "polished";

const hover = darken(0.07, "white");
const border = darken(0.2, "white");
const text = darken(0.5, "white");

const MenuListButtonComponent = styled("button")`
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s all;
  display: block;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  border: 1px solid ${border};
  color: ${text};
  background: transparent;
  
  padding: 0 10px;
  height: 40px;
  line-height: normal;
  
  &:hover {
    border: transparent;
    color: ${({theme}) => theme.box.text};
    background: ${({theme}) => theme.box.background};
    ${CS.shadow.light.one};
  }
 
`;

interface Props extends ButtonProps {

}

export const MenuListButton = (props: Props) => (
  <div className="list-item">
    <MenuListButtonComponent {...props}>
      {props.children}
    </MenuListButtonComponent>
  </div>
);
