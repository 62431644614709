import * as React from "react";
import {FastField, FormikProps} from "formik";
import {RestaurantUtils} from "@lib/common";
import {FormGroup, Switch, Input, FieldOpeningHours, Tag} from "@lib/components";

interface ServiceAutoTimingFieldsProps {
  service: T.Models.Restaurant.Services.Types;
  values: T.Models.Restaurant.Services.Base;
}

interface ServiceRushSettingsProps {
  service: T.Models.Restaurant.Services.Types;
  form: FormikProps<T.Models.Restaurant.Services.Base>;
}

export const ServiceOrderTimeFields = (props: { form: FormikProps<T.Models.Restaurant.Services.Base>; service: T.Models.Restaurant.Services.Types; }) => {
  const form = props.form;
  return (
    <div>

      {props.service !== "table_booking" && (
        <>
          <FastField
            name="times.now"
            render={({ field }: any) => (
              <FormGroup
                title="Enable Immediate Orders"
                help="Allows customers to place orders due immediately">
                <Switch
                  id="enable-now-switch"
                  checked={field.value}
                  onChange={(e) => form.setFieldValue("times.now", e.target.checked)}/>
              </FormGroup>
            )}
          />

          <FormGroup
            title="First Order Offset (minutes)"
            help="How many minutes after your store opens will you accept the first order">
            <FastField
              name="times.conditions.first_order_offset"
              render={({ field }: any) => (
                <Input type="number" step={1} min={0} required={true} {...field}/>
              )}/>
          </FormGroup>

          <FormGroup
            title="Last Order Offset (minutes)"
            help="How many minutes before your store closes will you accept the last order">
            <FastField
              name="times.conditions.last_order_offset"
              render={({ field }: any) => (
                <Input type="number" step={1} min={0} required={true} {...field}/>
              )}/>
          </FormGroup>

          <FastField
            name="times.later"
            render={({ field }: any) => (
              <FormGroup
                title="Enable Later Orders"
                help="Allows customers to place orders for a later date and time">
                <Switch
                  id="enable-later-switch"
                  checked={field.value}
                  onChange={(e) => form.setFieldValue("times.later", e.target.checked)}/>
              </FormGroup>
            )}
          />
        </>
      )}

      <FormGroup
        title="Maximum Days Ahead"
        help="The number of days in advance customers can place orders">
        <FastField
          name="times.conditions.max_days"
          render={({ field }: any) => (
            <Input type="number" step={1} min={0} required={true} {...field}/>
          )}/>
      </FormGroup>

      <FormGroup
        title="Time Interval (minutes)"
        help="The number of minutes between each available order time slot">
        <FastField
          name="times.conditions.time_interval"
          render={({ field }: any) => (
            <Input type="number" step={1} min={1} required={true} {...field}/>
          )}/>
      </FormGroup>

      <FormGroup
        title="Order Offset (minutes)"
        help="The minimum amount of time from now that an order for a later time must be placed">
        <FastField
          name="times.conditions.order_offset"
          render={({ field }: any) => (
            <Input type="number" step={1} min={0} required={true} {...field}/>
          )}/>
      </FormGroup>

    </div>
  );
};

export const ServiceHoursField = (props: { error: (field: any) => string | null | undefined; service: T.Models.Restaurant.Services.Types; }) => {
  return (
    <div>
      <FastField
        name="hours"
        render={({ field, form }: any) => (
          <FormGroup
            optional={true}
            title={"Custom Service Hours"}
            help="Set custom weekly hours for this service. This will override your existing location opening hours for this particular service. Enter time in 24H format, e.g. 21:00 for 9:00pm. Ensure time slots do not overlap or close before they open"
            error={props.error("hours")}>
            <div className="m-tb-2 block"/>
            <FieldOpeningHours
              allowNone={true}
              hours={field.value}
              onChange={(opening_hours) => form.setFieldValue("hours", opening_hours)}/>
            <div className="m-t-3 block"/>
          </FormGroup>
        )}
      />
    </div>
  );
};

export const ServiceAutoStatusFields = (props: ServiceAutoTimingFieldsProps) => {
  const isBooking = props.service === "table_booking";
  const eta = RestaurantUtils.settings.calculateEstimatedWaitTime(props.service, props.values);
  return (
    <div>

      {!isBooking && (
        <FormGroup>
          <p className="lhp">
            The following settings are used to automatically update order statuses and calculate order wait times.
            {props.service === "delivery" ?
              " The customer wait time is calculated by adding the time till confirm & ready values together. The driving time is then added onto that" :
              " The customer wait time is calculated by adding the time till confirm & ready values together"}
          </p>
          {(eta === null && !isBooking) && (
            <Tag className="m-t-3 font14 p-2">
              Estimated Wait Time: <span className="underline">Unknown</span>
            </Tag>
          )}
          {(eta !== null && !isBooking) && (
            <>
              {props.service === "delivery" && (
                <Tag className="m-t-3 font14 p-2">
                  Estimated Delivery Time: <span className="underline">{eta} minutes + driving time</span>
                </Tag>
              )}
              {props.service !== "delivery" && (
                <Tag className="m-t-3 font14 p-2">
                  Estimated Wait Time: <span className="underline">{eta} minutes</span>
                </Tag>
              )}
            </>
          )}
        </FormGroup>
      )}

      {isBooking && (
        <FormGroup>
          <p className="lhp">The following settings are used to automatically update booking statuses</p>
        </FormGroup>
      )}

      <FormGroup
        title={"Enable Automated Order Statuses"}
        help="Automatically change order statuses based on timings set below. Only works for orders due immediately">
        <FastField
          name="status.auto.confirmed"
          render={({ field, form }: any) => (
          <div className="flex-line centered m-b-2">
            <Switch
              id="enable-auto-confirmed-switch"
              checked={field.value}
              onChange={(e) => form.setFieldValue("status.auto.confirmed", e.target.checked)}/>
            <p className="m-l-3">Confirm</p>
          </div>
        )}/>
        {props.service !== "table_booking" && (
          <FastField
            name="status.auto.ready"
            render={({ field, form }: any) => (
            <div className="flex-line centered m-b-2">
              <Switch
                id="enable-auto-ready-switch"
                checked={field.value}
                onChange={(e) => form.setFieldValue("status.auto.ready", e.target.checked)}/>
              <p className="m-l-3">Ready</p>
            </div>
          )}
        />
        )}
        {props.service === "delivery" && (
          <FastField
            name="status.auto.on_route"
            render={({ field, form }: any) => (
            <div className="flex-line centered m-b-2">
              <Switch
                id="enable-auto-on_route-switch"
                checked={field.value}
                onChange={(e) => form.setFieldValue("status.auto.on_route", e.target.checked)}/>
              <p className="m-l-3">On Route</p>
            </div>
          )}
          />
        )}
        <FastField
          name="status.auto.complete"
          render={({ field, form }: any) => (
            <div className="flex-line centered">
              <Switch
                id="enable-auto-complete-switch"
                checked={field.value}
                onChange={(e) => form.setFieldValue("status.auto.complete", e.target.checked)}/>
              <p className="m-l-3">Complete</p>
            </div>
          )}
        />

      </FormGroup>

      <FastField
        name="status.times.confirmed"
        render={({ field }: any) => (
        <FormGroup
          optional={true}
          title="Time Till Confirm (minutes)"
          help="Time in minutes from un-confirmed to confirmed. Set 0 for instant confirmation">
          <Input type="number" step={1} min={0} {...field}/>
        </FormGroup>
      )}/>

      {!isBooking && (
        <FastField
          name="status.times.ready"
          render={({ field }: any) => (
          <FormGroup
            optional={true}
            title="Time Till Ready (minutes)"
            help="Time in minutes from confirmed to ready. Set 0 for instant change, leaving empty will have no effect">
            <Input type="number" step={1} min={0} {...field}/>
          </FormGroup>
        )}/>
      )}

      {props.service === "delivery" && (
        <FastField
          name="status.times.on_route"
          render={({ field }: any) => (
          <FormGroup
            optional={true}
            title="Time Till On Route (minutes)"
            help="Time in minutes from ready to on route, delivery orders only. Set 0 for instant change, leaving empty will have no effect">
            <Input type="number" step={1} min={0} {...field}/>
          </FormGroup>
        )}/>
      )}

      <FastField
        name="status.times.complete"
        render={({ field }: any) => (
        <FormGroup
          optional={true}
          title="Time Till Complete (minutes)"
          help="Time in minutes from the previous status to complete. Set 0 for instant change, leaving empty will have no effect">
          <Input type="number" step={1} min={0} {...field}/>
        </FormGroup>
      )}/>

      {!isBooking && (
        <>
         <FormGroup title="Order Rush Management">
           <p className="lhp m-b-2">The settings below help you increase the estimated wait time of a customer's order during busy periods. It follows a simple structure. For every X oustanding orders, increase the estimated wait time by Y minutes</p>
           <p className="lhp m-b-2">An outstanding order is any order that is currently un-confirmed or confirmed. For example, if your wait time is 20 minutes, and you set 'for every 2 outstanding orders, increase the wait time by 10 minutes', this is how it would play out:</p>
           <ul className="list-disc">
             <li>0 outstanding orders - 20 minute wait time</li>
             <li>1 outstanding orders - 20 minute wait time</li>
             <li>2 outstanding orders - 30 minute wait time</li>
             <li>3 outstanding orders - 30 minute wait time</li>
             <li>4 outstanding orders - 40 minute wait time</li>
           </ul>
         </FormGroup>
          <FormGroup
            title="For Every X Outstanding Orders"
            help="The number of outstanding orders before an increase in wait time is applied">
            <FastField
              name="status.rush.linear_order_threshold"
              render={({ field }: any) => (
                <Input {...field} value={field.value || ""} type="number" step={1} min={0}/>
              )}
            />
          </FormGroup>
          <FormGroup
            title="Increase Wait Time By Y Minutes"
            help="The amount of time to add onto the customers wait time if the order count above is exceeded">
            <FastField
              name="status.rush.linear_time_increment"
              render={({ field }: any) => (
                <Input {...field} value={field.value || ""} type="number" step={1} min={0}/>
              )}
            />
          </FormGroup>
        </>
      )}

    </div>
  );
};
