import * as React from "react";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../mobx/components";
import {ThemeProvider} from "styled-components";

@inject("store") @observer
export default class ThemeWrapper extends MobxComponent<{}, {}> {

  render() {
    const { store } = this.injected;
    const children = this.props.children as React.ReactElement;
    return (
      <ThemeProvider theme={store.theme.s}>
        {children}
      </ThemeProvider>
    );

  }

}

