import React from "react";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../mobx/components";
import {logger} from "@lib/common";
import {withTranslation} from "react-i18next";

interface Props {
  restaurant_id: string;
}
interface State {
  balance: number | null;
}

@inject("store") @observer
class TransferWiseBalanceClass extends MobxComponent<Props, State> {

  state = {
    balance: null
  };

  componentDidMount() {
    const { store } = this.injected;
    (async () => {
      try {
        const result = await store.api.transferwise_balance({ restaurant_id: this.props.restaurant_id });
        if (result.outcome) {
          logger.captureException(result);
        }
        else {
          this.setState({
            balance: result.balance,
          });
        }
      }
      catch (e) {
        logger.captureException(e);
      }
    })();
  }

  render() {
    const { balance } = this.state;
    if (balance === null) {
      return "...";
    }
    return this.injected.t("currency", { value: balance });
  }

}

// @ts-ignore
export const TransferWiseBalance = withTranslation()(TransferWiseBalanceClass);