import * as React from "react";
import posed from "react-pose";
import { styled, CS, Box } from "@lib/components";

const MainPose = posed.div({
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
});

const Main = styled(MainPose)`
  ${CS.flex.center};
  padding: 20px;
  min-height: 90vh;
`;

const Content = styled("div")`
  width: 360px;
  margin: 0 auto;
`;

const Logo = styled("div")`
  text-align: center;
  margin-bottom: 30px;
  img {
    max-height: 60px;
  }
`;

const ContentBox = styled(Box)`
  border-radius: 3px;
  box-shadow: 0 4px 36px rgba(0, 0, 0, 0.2);
  .box-loader {
    border-radius: 3px;
  }
`;

export const AuthLayoutComponents = {
  Main, Content, Logo, ContentBox,
};
