import * as React from "react";
import {FastField} from "formik";
import { ServiceOrderTimeFields, ServiceAutoStatusFields, ServiceHoursField } from "./base";
import {MobxComponent} from "../../../../../../../mobx/components";
import {inject, observer} from "mobx-react";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {UI} from "../../../../../../../core/ui";
import shortid from "shortid";
import { FormGroup, Button, RotateLoader, TabSelect, Input, Switch, FieldDineInTables } from "@lib/components";
import {cc} from "@lib/common";
interface Props {}
interface State {
  tab: string;
}
type FormValues = T.Models.Restaurant.Schema["settings"]["services"]["dine_in"];

@inject("store") @observer
export class SettingsFormServicesDineIn extends  MobxComponent<Props, State> {

  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {
      tab: "0",
    };
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.services.dine_in;
  }

  render() {
    const { tab } = this.state;
    const { restaurant } = this.injected.store;
    const storeHost = cc.hosts.stores;
    const storeURL = restaurant!.domain ? `https://${restaurant!.domain}` : `https://${restaurant!.subdomain}.${storeHost}`;
    return (
      <div>

        <TabSelect
          id="accounts-tab-options"
          className="border-white-tb-10"
          hasBorder={true}
          screenWidth={this.injected.store.view.screen_width}
          onChange={(v) => this.setState({ tab: v.value })}
          value={tab}
          values={[
            { label: "General", value: "0" },
            { label: "Tables", value: "1" },
            { label: "Order Timings", value: "2" },
            { label: "Available Hours", value: "3" },
            { label: "Wait Times & Auto Statuses", value: "4" },
          ]}/>

        <RestaurantForm<FormValues>
          submit={async (r, values) => {
            r.settings.services.dine_in = values;
            const update = { $set: { "settings.services.dine_in": r.settings.services.dine_in } };
            return { r, update };
          }}
          validators={{}}
          initialValues={this.initialValues}
          onSuccess={() => {}}
          onError={() => UI.notification.error("An error occurred")}
          onSuccessMessage="Settings Updated"
          onErrorMessage="">
          {({ form, error, getFieldError }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
              <div className="p-4">

                <FormGroup>
                  {tab === "0" && (
                    <div>
                      <FastField
                        name="enabled"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Enabled"
                            help="Allows customers to place orders that can be collected at one of your locations">
                            <Switch
                              id="enable-switch"
                              checked={field.value}
                              onChange={(e) => setFieldValue("enabled", e.target.checked)}/>
                          </FormGroup>
                        )}
                      />
                      <FastField
                        name="notes"
                        render={({ field }: any) => (
                          <FormGroup
                            optional={true}
                            title="Notes"
                            help="This will be shown to customers if they choose this order method">
                            <Input type="text" {...field}/>
                          </FormGroup>
                        )}
                      />
                    </div>
                  )}
                  {tab === "1" && (
                    <div>

                      <FastField
                        name="tables"
                        render={({ field }: any) => (
                          <FormGroup
                            help=""
                            error={getFieldError(form, "tables")}>
                            <Button type="button" color="primary-inverse" size="xs" onClick={() => {
                              form.setFieldValue("tables", [ ...field.value, {
                                _id: shortid.generate(),
                                name: "",
                                password: "",
                              } as T.Models.Restaurant.Services.DineInTable]);
                            }}>
                              Add Table
                            </Button>
                            { (field.value && field.value.length !== 0) && (
                              <div className="m-t-4">
                                <FieldDineInTables
                                  baseURL={storeURL}
                                  values={field.value}
                                  onChange={(tables) => setFieldValue("tables", tables)}/>
                              </div>
                            )}
                          </FormGroup>
                        )}
                      />

                    </div>
                  )}
                  {tab === "2" && <ServiceOrderTimeFields service="dine_in" form={form}/>}
                  {tab === "3" && <ServiceHoursField service="dine_in" error={(field) => getFieldError(form, field) as string | null | undefined}/>}
                  {tab === "4" && <ServiceAutoStatusFields service="dine_in" values={form.values}/>}
                </FormGroup>

                {error && <FormGroup error={error}/>}

                <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <RotateLoader size={2} color="white"/>}
                  {!isSubmitting && "Save"}
                </Button>

              </div>
            );
          }}
        </RestaurantForm>

      </div>
    );

  }
}
