import autobind from "autobind-decorator";
import {RootStore} from "../store";
import {UI} from "../../core/ui";
import {logger, cc} from "@lib/common";
import {Untrusive} from "../../core/libs/untrusive";

@autobind
export class BillingStore {

  cb: any;
  store: RootStore;

  constructor(store: RootStore) {
    this.store = store;
  }

  initChargebeeSession = async (restaurant_id: string, close?: () => void) => {

    const { store } = this;
    const error = "Error loading billing, try again or contact us";

    store.service.track("Subscription: View", {
      restaurant_id: restaurant_id,
    });

    try {
      Untrusive.start();
      const response = await store.api.billing_session({ restaurant_id });
      if (response.outcome) {
        UI.notification.error(error);
        Untrusive.stop();
        return;
      }
      else {

        this.cb = Chargebee.init({
          site: cc.chargebee.site,
        });

        this.cb.setPortalSession(() => Promise.resolve(response.session));

        setTimeout(() => {
          this.cb.createChargebeePortal().open({
            close: () => {
              console.log("close");
              this.cb.logout();
              UI.notification.success("Allow 10 seconds for any billing changes to take effect", { timeout: 10000 });
              if (close)
                close();
            },
            paymentSourceAdd: () => {
              console.log("Subscription: Payment Source Add");
              store.service.track("Subscription: Payment Source Add", {
                restaurant_id: restaurant_id,
              });
            },
            paymentSourceUpdate: () => {
              console.log("Subscription: Payment Source Update");
              store.service.track("Subscription: Payment Source Update", {
                restaurant_id: restaurant_id,
              });
            },
            paymentSourceRemove: () => {
              console.log("Subscription: Payment Source Remove");
              store.service.track("Subscription: Payment Source Remove", {
                restaurant_id: restaurant_id,
              });
            },
            subscriptionChanged: (data: { subscription: T.Chargebee.Subscription }) => {
              console.log("subscriptionChanged");
              store.service.track("Subscription: Update", {
                restaurant_id: restaurant_id,
                plan_id: data.subscription.plan_id,
                status: data.subscription.status,
              });
            },
            subscriptionCancelled: (data: { subscription: T.Chargebee.Subscription }) => {
              console.log("subscriptionCancelled");
              store.service.track("Subscription: Cancel", {
                restaurant_id: restaurant_id,
                plan_id: data.subscription.plan_id,
                status: data.subscription.status,
              });
            },
            subscriptionReactivated: (data: { subscription: T.Chargebee.Subscription }) => {
              console.log("subscriptionReactivated");
              store.service.track("Subscription: Reactivated", {
                restaurant_id: restaurant_id,
                plan_id: data.subscription.plan_id,
                status: data.subscription.status,
              });
            },
          });
          Untrusive.stop();
        }, 200);

      }
    }
    catch (e) {
      logger.captureException(e);
      UI.notification.error(error);
      Untrusive.stop();
    }

  }


}
