import * as React from "react";
import {FastField} from "formik";
import {UI} from "../../../../../../../core/ui";
import validator from "validator";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../../../mobx/components";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {arrayMove} from "react-sortable-hoc";
import { logger, cloneDeepSafe, PrintDetailListCustomer } from "@lib/common";
import { FormGroup, Button, RotateLoader, Switch, HTMLEditor, TabSelect, InputTags, SwitchListSortable, ScrollList, ScrollListItem } from "@lib/components";

interface Props {}
interface State {
  tab: string;
}
type FormValues = T.Models.Restaurant.Schema["settings"]["notifications"];

@inject("store") @observer
export class SettingsFormNotification extends MobxComponent<Props, State> {

  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    const r = this.injected.store.restaurant!;
    this.state = {
      tab: "0",
    };
    this.initialValues = cloneDeepSafe(r.settings.notifications);
  }

  render() {
    const { tab } = this.state;

    return (
      <div>

        <TabSelect
          id="notifications-tab-options"
          className="border-white-tb-10"
          hasBorder={true}
          screenWidth={this.injected.store.view.screen_width}
          onChange={(v) => this.setState({ tab: v.value })}
          value={tab}
          values={[
            { label: "Store", value: "0" },
            { label: "Customer", value: "1" },
            { label: "PDF Receipt", value: "2" },
          ]}/>

        <RestaurantForm<FormValues>
          submit={async (r, v) => {
            r.settings.notifications = v;
            const update = { $set: { "settings.notifications": v } };
            return { r, update };
          }}
          validators={{}}
          initialValues={this.initialValues}
          onSuccess={() => {}}
          onError={() => UI.notification.error("An error occurred")}
          onSuccessMessage="Settings Updated"
          onErrorMessage="">
          {({ form, error }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
              <div className="p-4">

                <FormGroup>
                  {tab === "0" && (
                    <div>
                      <FastField
                        name="email.store.addresses"
                        render={({ field }: any) => (
                          <FormGroup
                            optional={true}
                            title="E-Mail Addresses"
                            help="Enter up to 3 e-mail addresses where you would like to receive notifications">
                            <InputTags
                              max={3}
                              placeholder="Enter an e-mail and press enter"
                              validator={(val) => validator.isEmail(val) ? null : "Invalid e-mail address"}
                              onError={(e) => UI.notification.error(e, { timeout: 5000 })}
                              tags={field.value}
                              onChange={(tags) => setFieldValue("email.store.addresses", tags)}/>
                          </FormGroup>
                        )}
                      />
                      <FastField
                        name="email.store.order_created"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Order Created"
                            help="Receive an e-mail notification with a PDF copy of the order receipt when a new order is placed">
                            <Switch
                              id="email.store.order_created"
                              checked={field.value}
                              onChange={(e) => setFieldValue("email.store.order_created", e.target.checked)}/>
                          </FormGroup>
                        )}
                      />
                      <FastField
                        name="email.store.booking_created"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Booking Created"
                            help="Receive an e-mail notification whenever a new booking is created by a customer">
                            <Switch
                              id="email.store.booking_created"
                              checked={field.value}
                              onChange={(e) => setFieldValue("email.store.booking_created", e.target.checked)}/>
                          </FormGroup>
                        )}
                      />
                      <FastField
                        name="email.store.age_verification"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Age Verification"
                            help="Receive an e-mail notification whenever a customer uploads their documents for age verification">
                            <Switch
                              id="email.store.age_verification"
                              checked={field.value}
                              onChange={(e) => setFieldValue("email.store.age_verification", e.target.checked)}/>
                          </FormGroup>
                        )}
                      />
                    </div>
                  )}
                  {tab === "1" && (
                    <div>
                      <FastField
                        name="email.customer.order_created.enabled"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Order Created"
                            help="Customers will receive an e-mail notification with a PDF copy of their order receipt upon placing a new order">
                            <Switch
                              id="email.customer.order_created.enabled"
                              checked={field.value}
                              onChange={(e) => setFieldValue("email.customer.order_created.enabled", e.target.checked)}/>
                          </FormGroup>
                        )}
                      />
                    </div>
                  )}
                  {tab === "2" && (
                    <div>

                      <FormGroup
                        optional={true}
                        title="Receipt Header"
                        help="Customize your receipt header with information such as your store name, business number, etc.">
                        <FastField
                          name="email.customer.header"
                          render={({ field }: any) => (
                            <HTMLEditor
                              id="receipt-email-header-html"
                              initialValue={field.value}
                              onChange={(val) => setFieldValue("email.customer.header", val)}/>
                          )}
                        />
                      </FormGroup>

                      <FormGroup
                        optional={true}
                        title="Receipt Footer"
                        help="Customize your footer header with information such as promotions or a thank you message">
                        <FastField
                          name="email.customer.footer"
                          render={({ field }: any) => (
                            <HTMLEditor
                              id="receipt-email-footer-html"
                              initialValue={field.value}
                              onChange={(val) => setFieldValue("email.customer.footer", val)}/>
                          )}
                        />
                      </FormGroup>

                      <FormGroup
                        title="Detail Customization"
                        help="Customize which order details are displayed on the receipt. Rearrange them using the drag handles">
                        <FastField
                          name="email.customer.order_created.details"
                          render={({ field }: any) => (
                            <SwitchListSortable
                              height={250}
                              items={field.value}
                              onCheck={(i, item) => {
                                const items = [...field.value];
                                items[i].enabled = !item.enabled;
                                setFieldValue("email.customer.order_created.details", items);
                              }}
                              onSortEnd={({oldIndex, newIndex}) => {
                                setFieldValue("email.customer.order_created.details", arrayMove(field.value, oldIndex, newIndex));
                              }}/>
                          )}
                        />
                        <Button
                          size="xs"
                          type="button"
                          className="m-t-3"
                          onClick={() => setFieldValue("email.customer.order_created.details", PrintDetailListCustomer())}>
                          Reset
                        </Button>
                      </FormGroup>

                      <FormGroup
                        title="Dish Customization"
                        help="Customize how the dishes are displayed on your receipts. Useful for cutting down on un-needed information">
                        <ScrollList height={null}>
                          <FastField
                            name="email.customer.order_created.dishes.enabled"
                            render={({ field }: any) => (
                              <ScrollListItem
                                className="flex-line centered"
                                onClick={() => setFieldValue("email.customer.order_created.dishes.enabled", !field.value)}>
                                <Switch
                                  id="customization_dishes_enabled"
                                  checked={field.value}
                                  onChange={(e) => setFieldValue("email.customer.order_created.dishes.enabled", e.target.checked)}/>
                                <p className="m-l-3">Show Dishes</p>
                              </ScrollListItem>
                            )}
                          />
                          <FastField
                            name="email.customer.order_created.dishes.prices"
                            render={({ field }: any) => (
                              <ScrollListItem
                                className="flex-line centered"
                                onClick={() => setFieldValue("email.customer.order_created.dishes.prices", !field.value)}>
                                <Switch
                                  id="customization_dishes_prices"
                                  checked={field.value}
                                  onChange={(e) => setFieldValue("email.customer.order_created.dishes.prices", e.target.checked)}/>
                                <p className="m-l-3">Show Dish Prices</p>
                              </ScrollListItem>
                            )}
                          />
                          <FastField
                            name="email.customer.order_created.dishes.option_prices"
                            render={({ field }: any) => (
                              <ScrollListItem
                                className="flex-line centered"
                                onClick={() => setFieldValue("email.customer.order_created.dishes.option_prices", !field.value)}>
                                <Switch
                                  id="customization_dishes_option_prices"
                                  checked={field.value}
                                  onChange={(e) => setFieldValue("email.customer.order_created.dishes.option_prices", e.target.checked)}/>
                                <p className="m-l-3">Show Dish Option-set Prices</p>
                              </ScrollListItem>
                            )}
                          />
                          <FastField
                            name="email.customer.order_created.dishes.combo_choice_names"
                            render={({ field }: any) => (
                              <ScrollListItem
                                className="flex-line centered"
                                onClick={() => setFieldValue("email.customer.order_created.dishes.combo_choice_names", !field.value)}>
                                <Switch
                                  id="customization_dishes_combo_choice_names"
                                  checked={field.value}
                                  onChange={(e) => setFieldValue("email.customer.order_created.dishes.combo_choice_names", e.target.checked)}/>
                                <p className="m-l-3">Show Combo Dish Choice Headings</p>
                              </ScrollListItem>
                            )}
                          />
                        </ScrollList>
                      </FormGroup>

                      <FastField
                        name="email.customer.order_created.totals"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Show Order Totals"
                            help="Disabling this will remove the order totals including any discounts or taxes from the bottom of the receipt">
                            <Switch
                              id="customization_receipt_total"
                              checked={field.value}
                              onChange={(e) => setFieldValue("email.customer.order_created.totals", e.target.checked)}/>
                          </FormGroup>
                        )}
                      />

                    </div>
                  )}
                </FormGroup>

                {error && <FormGroup error={error}/>}

                <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <RotateLoader size={2} color="white"/>}
                  {!isSubmitting && "Save"}
                </Button>

              </div>
            );
          }}
        </RestaurantForm>

      </div>
    );

  }

}
