import * as React from "react";
import { AuthLayoutComponents } from "./layout.c";
import { BoxLoader } from "@lib/components";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../mobx/components";

interface Props {
  loading: boolean;
  children: React.ReactNode;
}
interface State {
  pose_state: "visible" | "hidden";
}

@inject("store") @observer
export class AuthLayout extends MobxComponent<Props, State> {

  timeout: any;

  constructor(props: Props) {
    super(props);
    this.state = { pose_state: "hidden" };
  }

  componentDidMount() {
    this.timeout = setTimeout(() => this.setState({ pose_state: "visible" }), 200);
    if (this.injected.store.auth.token) {
      this.injected.store.router.push("/");
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const { children, loading } = this.props;
    return (
      <AuthLayoutComponents.Main pose={this.state.pose_state}>

        <AuthLayoutComponents.Content>

          <AuthLayoutComponents.Logo>
            <img src="/images/logos/logo-no-com.png" className="res-img"/>
          </AuthLayoutComponents.Logo>

          <AuthLayoutComponents.ContentBox>
            <BoxLoader active={loading}/>
            {children}
          </AuthLayoutComponents.ContentBox>

        </AuthLayoutComponents.Content>

      </AuthLayoutComponents.Main>
    );
  }

}
