import * as React from "react";
import {FastField} from "formik";
import {MobxComponent} from "../../../../../../../mobx/components";
import {inject, observer} from "mobx-react";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {UI} from "../../../../../../../core/ui";
import { FormGroup, Button, RotateLoader, Input, Switch, InputTags, FormUpload, Radio } from "@lib/components";

interface Props {}
interface State {}
type FormValues = T.Models.Restaurant.Schema["listing"];

@inject("store") @observer
export class SettingsFormListing extends MobxComponent<Props, State> {

  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.listing || {
      price: 1,
      tags: [],
      image: null,
      map_data: {
        administrative_area_level_1: "",
        locality: "",
      },
    };
  }

  render() {
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.listing = values;
          const update = { $set: { "listing": values } };
          return { r, update };
        }}
        validators={{}}
        initialValues={this.initialValues}
        onSuccess={() => {}}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          const price_values = [1, 2, 3];
          return (
            <div className="p-4">

              <FormGroup
                title="Listing Name"
                help="Will be displayed as the main name for the listing and will override your location name and restaurant name">
                <FastField
                  name="name"
                  render={({ field }: any) => (
                    <Input type="text" {...field} value={field.value || ""}/>
                  )}
                />
              </FormGroup>

              <FormGroup
                title="Price Rating"
                help="Used to determine how expensive this store is. A value of 3 will show 3 highlighted dollar icons to the end user. A value of one will only show one dollar icon. Higher values represent a higher price">
                <FastField
                  name="price"
                  render={({ field }: any) => (
                    <div className="child-mb-10">
                      { price_values.map((val, i) => (
                        <Radio
                          key={i}
                          title={val}
                          id={`${val}-price-rating`}
                          name={`${val}-price-rating`}
                          value={val.toString()}
                          checked={field.value === val}
                          onChange={() => setFieldValue("price", val)}/>
                      ))}
                    </div>
                  )}
                />
              </FormGroup>

              <FormGroup
                title="Tags"
                help="Displayed as part of a store listing and used to generate the filters available when searching for stores. We recommend no more than 4 tags">
                <FastField
                  name="tags"
                  render={({ field }: any) => (
                    <InputTags
                      tags={field.value}
                      placeholder="Type your tag and press enter to add it"
                      onError={(e) => UI.notification.error(e, { timeout: 5000 })}
                      onChange={(tags) => setFieldValue("tags", tags)}/>
                  )}
                />
              </FormGroup>

              <FastField
                name="image"
                render={({ field }: any) => (
                  <FormGroup title="Listing Image" help="Recommend size is 600 x 400 pixels">
                    <FormUpload
                      maxSize={500}
                      imagesOnly={true}
                      customSource={true}
                      value={[field.value]}
                      onRemove={() => setFieldValue("image", null)}
                      onChange={(files) => setFieldValue("image", files[0])}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="map_data.country"
                render={({ field }: any) => (
                  <FormGroup
                    title="Country"
                    help="Overrides the value provided by google maps. If you are using custom addresses, you must enter a value here for this store to show on the website">
                    <Input type="text" {...field}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="map_data.administrative_area_level_1"
                render={({ field }: any) => (
                  <FormGroup
                    title="Administrative Area Level 1"
                    help="Overrides the value provided by google maps. If you are using custom addresses, you must enter a value here for this store to show on the website">
                    <Input type="text" {...field}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="map_data.locality"
                render={({ field }: any) => (
                  <FormGroup
                    title="Locality"
                    help="Overrides the value provided by google maps. If you are using custom addresses, you must enter a value here for this store to show on the website">
                    <Input type="text" {...field}/>
                  </FormGroup>
                )}
              />

              <FormGroup
                title="Hide Listing"
                help="This will remove the listing from your portal. Please allow up to 5 minutes for it to take effect">
                <FastField
                  name="hidden"
                  render={({ field }: any) => (
                    <Switch
                      id={"hidden-listing-switch"}
                      checked={field.value || false}
                      onChange={(e) => setFieldValue("hidden", e.target.checked)}
                    />
                  )}
                />
              </FormGroup>

              {error && <FormGroup error={error}/>}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white"/>}
                {!isSubmitting && "Save"}
              </Button>

            </div>
          );
        }}
      </RestaurantForm>
    );
  }

}
