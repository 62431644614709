import * as React from "react";
import { SettingsSection } from "../layout/section";
import { SettingsSectionHeading } from "../layout/heading";
import { SettingsSectionBlock } from "../layout/block";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../../mobx/components";
import { FaCheck, FaTimes, FaPlus, FaArrowCircleUp, FaArrowCircleDown, FaTrashAlt } from "react-icons/fa";
import {SettingsFormPaymentsCash} from "../forms/payments/cash";
import {SettingsFormPaymentsCard} from "../forms/payments/card";
import {SettingsFormPaymentsStripe} from "../forms/payments/stripe";
import {SettingsFormPaymentsPoliPay} from "../forms/payments/polipay";
import {SettingsFormPaymentsBamboraAPAC} from "../forms/payments/bambora_apac";
import {SettingsFormPaymentsCustom} from "../forms/payments/custom";
import {withTranslation, TranslationProps} from "react-i18next";
import shortid from "shortid";
import difference from "lodash/difference";
import {UI} from "../../../../../../core/ui";
import { logger, PaymentMethods, RestaurantUtils, cloneDeepSafe, arraySwapItem } from "@lib/common";
import { IconCircle, Button, Tooltip, Modal, ModalContent, ModalTitle, ListSelect } from "@lib/components";
import { Untrusive } from "../../../../../../core/libs/untrusive";

type AddPaymentType = T.Models.Restaurant.Payments.TypesBase | "custom" | "";

interface Props extends TranslationProps {}
interface State {
  active: string;
  addPaymentModal: boolean;
  addPaymentType: AddPaymentType;
}

@inject("store") @observer
class RestaurantSettingsPaymentsClass extends MobxComponent<Props, State> {

  reorderTimeout: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      active: "",
      addPaymentModal: false,
      addPaymentType: "",
    };
  }

  setActive = (active: string) => {
    if (this.state.active === active)
      this.setState({ active: "" });
    else
      this.setState({ active });
  }

  methodReorder = async (direction: "up" | "down", key: string) => {
    try {

      const { store } = this.injected;
      const { restaurant } = store;
      const r = cloneDeepSafe(restaurant)!;
      const settings = cloneDeepSafe(r.settings);
      const paymentKeys = Object.keys(settings.payments);
      const index = paymentKeys.indexOf(key);

      let swapIndex = -1;
      if (direction === "up" && index > 0) {
        swapIndex = index - 1;
      }
      else if (direction === "down" && index < (paymentKeys.length - 1)) {
        swapIndex = index + 1;
      }

      if (swapIndex === -1) {
        return;
      }

      clearTimeout(this.reorderTimeout);

      arraySwapItem(paymentKeys, index, swapIndex);

      const payments: {[key: string]: T.Models.Restaurant.Payments.Base | undefined} = {};

      for (const pk of paymentKeys) {
        payments[pk] = settings.payments[pk];
      }

      settings.payments = payments;

      store.updateRestaurant({ settings });

      this.reorderTimeout = setTimeout(async () => {
        await this.saveRestaurantSilent({
          backup: r,
          update: { $set: { settings } },
          errorMsg: "Failed to save payment order, check connection and try again",
          before: () => {},
          onSuccess: () => {},
          onFail: () => {},
          onError: () => {},
        });
      }, 2000);

    }
    catch (e) {
      logger.captureException(e);
    }
  }

  methodRemove = async (key: string) => {

    const restaurant = this.injected.store.restaurant!;
    const methods = Object.keys(restaurant.settings.payments);

    if (methods.length === 1) {
      UI.notification.error("Cannot remove last payment method");
      return;
    }

    const msg = "Removing this payment method will permanently delete any data associated with it. You can disable it instead. Proceed with removal?";

    const proceed = confirm(msg);

    if (!proceed) { return; }

    Untrusive.start();

    await this.saveRestaurant({
      successMsg: "Payment method deleted",
      process: (r) => {
        delete r.settings.payments[key];
        return {
          update: {
            $set: {
              "settings.payments": r.settings.payments,
            },
          },
        };
      },
    });

    Untrusive.stop();

  }

  addPaymentMethod = async () => {

    const type = this.state.addPaymentType;

    let paymentData: any | undefined;
    if (type === "cash" || type === "card" || type === "custom") {
      paymentData = {
        enabled: false,
        services: [],
        label: type === "custom" ? "Custom Payment Method" : "",
      } as T.Models.Restaurant.Payments.Base;
    }
    else if (type === "stripe") {
      paymentData = {
        enabled: false,
        label: "",
        services: [],
        currency: "",
        secret_key: "",
        publishable_key: "",
      } as T.Models.Restaurant.Payments.Stripe;
    }
    else if (type === "poli_pay") {
      paymentData = {
        enabled: false,
        label: "",
        services: [],
        currency: "",
        merchant_id: "",
        auth_code: "",
      } as T.Models.Restaurant.Payments.PoliPay;
    }
    else if (type === "bambora_apac") {
      paymentData = {
        enabled: false,
        testing: false,
        label: "",
        services: [],
        currency: "",
        merchant_id: "",
        api_username: "",
        api_password: "",
      } as T.Models.Restaurant.Payments.BamboraAPAC;
    }

    const key = type === "custom" ? shortid.generate() : type;

    this.setState({ addPaymentType: "", addPaymentModal: false });

    Untrusive.start();

    await this.saveRestaurant({
      successMsg: "Payment method created",
      errorMsg: "Failed to create payment method, try again or contact us",
      process: (r) => {
        r.settings.payments[key] = paymentData;
        return {
          update: {
            $set: {
              "settings.payments": r.settings.payments,
            },
          },
        };
      },
    });

    Untrusive.stop();

  }

  render() {

    const { active, addPaymentModal, addPaymentType } = this.state;

    const { store, t } = this.injected;

    const { theme, restaurant } = store;

    const { payments } = restaurant!.settings;

    const successIndicator = <IconCircle size={16} className={"m-r-2"} icon={<FaCheck/>} iconSizeModifier={6} background={theme.s.status_colors.complete}/>;
    const failIndicator = <IconCircle size={16} className={"m-r-2"} icon={<FaTimes/>} iconSizeModifier={6} background={"grey"}/>;

    const forms = {
      cash: <SettingsFormPaymentsCash/>,
      card: <SettingsFormPaymentsCard/>,
      stripe: <SettingsFormPaymentsStripe/>,
      poli_pay: <SettingsFormPaymentsPoliPay/>,
      bambora_apac: <SettingsFormPaymentsBamboraAPAC/>,
    };

    const validators = {
      cash: RestaurantUtils.settings.paymentActiveCash,
      card: RestaurantUtils.settings.paymentActiveCard,
      stripe: RestaurantUtils.settings.paymentActiveStripe,
      poli_pay: RestaurantUtils.settings.paymentActivePoliPay,
      bambora_apac: RestaurantUtils.settings.paymentActiveBamboraAPAC,
    };

    const methods = Object.keys(payments).map((key) => key);
    const allMethods = difference([...PaymentMethods, "custom"], methods);

    const Title = (props: { methodKey: string; title: string; active: boolean; index: number; }) => (
      <div className="flex-l-r-center">
        <div className="flex-line centered">
          {props.active ? successIndicator : failIndicator}
          <span>{props.title}</span>
        </div>
        <div className="flex-line centered m-r-4">
          <Tooltip text="Remove" width={65} position={"top"}>
            <Button size="xs" color="white" className="p-lr-1" onClick={(e) => {
              e.stopPropagation();
              this.methodRemove(props.methodKey);
            }}>
              <FaTrashAlt/>
            </Button>
          </Tooltip>
          <Tooltip text="Move Up" width={70} position={"top"}>
            <Button size="xs" color="white" className="p-lr-1 m-l-1" onClick={(e) => {
              e.stopPropagation();
              this.methodReorder("up", props.methodKey);
            }}>
              <FaArrowCircleUp/>
            </Button>
          </Tooltip>
          <Tooltip text="Move Down" width={90} position={"top"}>
            <Button size="xs" color="white" className="p-lr-1 m-l-1" onClick={(e) => {
              e.stopPropagation();
              this.methodReorder("down", props.methodKey);
            }}>
              <FaArrowCircleDown/>
            </Button>
          </Tooltip>
        </div>
      </div>
    );

    return (
      <>

        <SettingsSection>
          <SettingsSectionHeading className="flex-l-r-center">
            <div/>
            <div className="flex-line centered cursor" onClick={() => this.setState({ addPaymentModal: true })}>
              <p className="underline">Add Payment Method</p>
              <p className="m-l-2"><FaPlus/></p>
            </div>
          </SettingsSectionHeading>
          {methods.map((key, i) => {
            const data = payments[key]!;
            const isBaseMethod = PaymentMethods.indexOf(key as T.Models.Restaurant.Payments.TypesBase) !== -1;
            const name = isBaseMethod ? t(`constants.payment.backend_method.${key}`) : (data.label || "");
            const component = isBaseMethod ? forms[key as keyof typeof forms] : <SettingsFormPaymentsCustom payment_id={key}/>;
            const enabled = isBaseMethod ? validators[key as keyof typeof forms](payments) : data.enabled;
            return (
              <SettingsSectionBlock
                key={i}
                name={<Title methodKey={key} title={name} active={enabled} index={i}/>}
                headerClass="p-tb-2"
                active={active === key}
                onClick={() => this.setActive(key)}>
                {component}
              </SettingsSectionBlock>
            );
          })}
        </SettingsSection>

        <Modal
          width={420}
          active={addPaymentModal}
          close={() => this.setState({ addPaymentModal: false, addPaymentType: "" })}>
          <ModalTitle className="round-top-sm">
            <h4>Add Payment Method</h4>
          </ModalTitle>
          <ModalContent>
            <ListSelect
              selected={addPaymentType}
              onChange={(value) => this.setState({ addPaymentType: value as AddPaymentType })}
              items={allMethods.map((method) => ({
                value: method,
                render: () => (
                  <div className="width100 flex-line centered">
                    {t(`constants.payment.backend_method.${method}`)}
                  </div>
                ),
              }))}
            />
          </ModalContent>
          {!!addPaymentType && (
            <ModalContent>
              <Button color="primary" full={true} onClick={this.addPaymentMethod}>
                Add Method
              </Button>
            </ModalContent>
          )}
        </Modal>

      </>
    );
  }

}

// @ts-ignore
export const RestaurantSettingsPayments = withTranslation()(RestaurantSettingsPaymentsClass);
