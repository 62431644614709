import * as React from "react";
import { MenuList } from "./layout/list";
import { MenuListButton } from "./layout/button";
import { MenuListItem } from "./layout/item";
import shortid from "shortid";
import cloneDeep from "lodash/cloneDeep";
import { Button, RotateLoader, Modal, ModalContent } from "@lib/components";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { arrayMove } from "react-sortable-hoc";
import { RestaurantFormOptionSet } from "./forms/option_set";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../mobx/components";

interface Props {}
interface State {

  reorder: boolean;
  index_copy: number | null;
  index_panel: number | null;
  index_remove: number | null;

  loading_copy: boolean;
  loading_remove: boolean;
  loading_reorder: boolean;

}

@inject("store") @observer
export class RestaurantOptionSetList extends MobxComponent<Props, State> {

  backup: T.Models.Restaurant.Schema["option_sets"] | null;

  constructor(props: Props) {
    super(props);
    this.state = {
      reorder: false,
      index_copy: null,
      index_panel: null,
      index_remove: null,
      loading_copy: false,
      loading_remove: false,
      loading_reorder: false,
    };
    this.backup = null;
  }

  componentDidMount() {

  }

  create_item = (): T.Models.Restaurant.Menus.OptionSet => {
    return {
      _id: shortid.generate(),
      name: "",
      display_name: "",
      show_in_menu: false,
      conditions: {
        required: false,
        multi_select: false,
        quantity_select: false,
        min_options: "",
        max_options: "",
        free_amount: "",
      },
      options: [],
    };
  }

  list_item = () => {
    const { reorder, loading_reorder } = this.state;
    const r = this.injected.store.restaurant!;
    const { option_sets } = r;
    return (
      <MenuList id="option-set-list" type="OPTIONSET">

        {option_sets.map((item, i) => {
          return (
            <MenuListItem
              key={i}
              type="OPTIONSET"
              id={item._id}
              dragDisabled={!reorder}
              name={item.name}
              index={i}
              onEdit={() => this.panel_set(i)}
              onRemove={() => this.modal_remove_open(i)}
              onCopy={() => this.modal_copy_open(i)}
            />
          );
        })}

        {!reorder && [
          <MenuListButton key="btn-create" onClick={() => this.panel_set(-1)}>
            Create New Option Set
          </MenuListButton>,
          <MenuListButton key="btn-arrange" onClick={this.reorder_start}>
            Re-arrange
          </MenuListButton>,
        ]}

        {reorder && (
          <div>
            <div className="grid-2 xs xs-gap">
              <div className="col">
                <MenuListButton onClick={this.reorder_save}>
                  {loading_reorder && <RotateLoader size={2} color="white"/>}
                  {!loading_reorder && "Save"}
                </MenuListButton>
              </div>
              <div className="col">
                <MenuListButton onClick={this.reorder_cancel}>
                  Cancel
                </MenuListButton>
              </div>
            </div>
            <div className="text-center m-t-3">
              <p>Click + hold to drag and drop items to re-order them</p>
            </div>
          </div>
        )}

      </MenuList>
    );
  }

  panel_set = (index_panel: number | null) => {
    this.setState({ index_panel });
  }
  panel_create = () => {
    const r = this.injected.store.restaurant!;
    const { index_panel } = this.state;

    let initialValues = null;
    if (index_panel !== null) {
      if (index_panel === -1) {
        initialValues = this.create_item();
      }
      else {
        const item = r.option_sets[index_panel];
        initialValues = cloneDeep(item);
      }
    }

    return (
      <RestaurantFormOptionSet
        type={index_panel === -1 ? "create" : "edit"}
        initialValues={initialValues}
        close={() =>  this.panel_set(null)}/>
    );
  }

  copy = async (index: number) => {
    await this.saveRestaurant({
      successMsg: "Tag copied",
      process: (r) => {
        const option_set = cloneDeep(r.option_sets[index]);
        option_set._id = shortid.generate();
        option_set.name = `${option_set.name} - Copy`;
        for (const [i] of option_set.options.entries()) {
          option_set.options[i]._id = shortid.generate();
        }
        r.option_sets.splice(index + 1, 0, option_set);
        return {
          update: { $set: { option_sets: r.option_sets } },
        };
      },
      before: () => this.setState({ loading_copy: true }),
      onSuccess: () => this.modal_copy_close(),
      onFail: () => this.setState({ loading_copy: false }),
      onError: () => this.setState({ loading_copy: false }),
    });
  }
  remove = async (index: number) => {
    await this.saveRestaurant({
      successMsg: "Option set deleted",
      process: (r) => {

        const _id = r.option_sets[index]._id;

        r.menus = cloneDeep(r.menus);

        for (const [i, m] of r.menus.entries()) {
          for (const [k, c] of m.categories.entries()) {
            for (const [z, d] of c.dishes.entries()) {
              const found = d.option_sets.indexOf(_id);
              if (found !== -1) {
                r.menus[i].categories[k].dishes[z].option_sets.splice(found, 1);
              }
            }
          }
        }

        r.option_sets.splice(index, 1);

        return {
          update: { $set: { option_sets: r.option_sets, menus: r.menus } },
        };

      },
      before: () => this.setState({ loading_remove: true }),
      onSuccess: () => this.modal_remove_close(),
      onFail: () => this.setState({ loading_remove: false }),
      onError: () => this.setState({ loading_remove: false }),
    });
  }

  modal_copy_open = (index: number | null) => {
    this.setState({
      index_copy: index,
      loading_copy: false,
    });
  }
  modal_copy_close = () => {
    this.setState({
      index_copy: null,
      loading_copy: false,
    });
  }
  modal_remove_open = (index: number | null) => {
    this.setState({
      index_remove: index,
      loading_remove: false,
    });
  }
  modal_remove_close = () => {
    this.setState({
      index_remove: null,
      loading_remove: false,
    });
  }

  modal_copy = () => {
    const r = this.injected.store.restaurant!;
    const { index_copy, loading_copy } = this.state;

    let item;
    let fn;
    if (index_copy !== null) {
      item = r.option_sets[index_copy];
      fn = () => this.copy(index_copy);
    }

    return (
      <Modal
        width="sm"
        close={this.modal_copy_close}
        closeButton={false}
        active={!!item}>
        <ModalContent className="flex-l-r-center">
          <h4 className="">Copy Option Set</h4>
          <p className="big underline">{item && item.name}</p>
        </ModalContent>
        <ModalContent className="flex-right">
          <Button type="button" className="m-r-2 width100 max100px" onClick={this.modal_copy_close}>Cancel</Button>
          <Button type="button" className="width100 max100px" color="primary" onClick={fn}>
            {loading_copy && <RotateLoader size={2} color="white"/>}
            {!loading_copy && "Copy"}
          </Button>
        </ModalContent>
      </Modal>
    );
  }
  modal_remove = () => {
    const r = this.injected.store.restaurant!;
    const { index_remove, loading_remove } = this.state;

    let item;
    let fn;
    if (index_remove !== null) {
      item = r.option_sets[index_remove];
      fn = () => this.remove(index_remove);
    }

    return (
      <Modal
        width="sm"
        close={this.modal_remove_close}
        closeButton={false}
        active={!!item}>
        <ModalContent className="flex-l-r-center">
          <h4 className="">Delete Option Set</h4>
          <p className="big underline">{item && item.name}</p>
        </ModalContent>
        <ModalContent className="flex-right">
          <Button type="button" className="m-r-2 width100 max100px" onClick={this.modal_remove_close}>Cancel</Button>
          <Button type="button" className="width100 max100px" color="primary" onClick={fn}>
            {loading_remove && <RotateLoader size={2} color="white"/>}
            {!loading_remove && "Delete"}
          </Button>
        </ModalContent>
      </Modal>
    );
  }

  reorder_start = () => {
    this.setState({ reorder: true });
    const r = this.injected.store.restaurant!;
    this.backup = cloneDeep(r.option_sets);
  }
  reorder_cancel = () => {
    const r = { ...this.injected.store.restaurant! };
    r.option_sets = this.backup ? this.backup : [];
    this.injected.store.setRestaurant(r);
    this.setState({ reorder: false });
  }
  reorder_save = async () => {
    const r = this.injected.store.restaurant!;
    const backup = cloneDeep(r);
    if (this.backup)
      backup.option_sets = this.backup;
    await this.saveRestaurantSilent({
      backup: backup,
      update: { $set: { option_sets: r.option_sets } },
      successMsg: "Option sets re-arranged",
      before: () => this.setState({ loading_reorder: true }),
      onSuccess: () => {
        this.setState({ reorder: false, loading_reorder: false });
        this.backup = null;
      },
      onFail: () => this.setState({ loading_reorder: false }),
      onError: () => this.setState({ loading_reorder: false }),
    });
  }

  onDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination) return;
    const r = { ...this.injected.store.restaurant! };
    if (destination.index !== source.index) {
      r.option_sets = arrayMove(r.option_sets, source.index, destination.index);
      this.injected.store.setRestaurant(r);
    }
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        {this.list_item()}
        {this.panel_create()}
        {this.modal_copy()}
        {this.modal_remove()}
      </DragDropContext>
    );
  }

}
