import * as React from "react";
import { Button } from "@lib/components";

interface Props {
  children: React.ReactNode;
  color: string;
  onClick: () => void;
}

export const BoardDropdownButton = (props: Props) => (
  <Button
    size="sm"
    display="flex"
    className="no-round flex-grow flex-basis-0"
    overrideColor={props.color}
    onClick={props.onClick}>
    {props.children}
  </Button>
);
