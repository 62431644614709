import * as React from "react";
import { styled } from "@lib/components";
import { Droppable } from "react-beautiful-dnd";

const Wrapper = styled("div")`
  padding-top: 5px; // needed to allow dropping onto empty list
  display: block;
  .list-item {
    padding: 6px 0;
  }
`;

interface MenuListProps {
  id: string;
  type: string;
  disabled?: boolean;
}

export class MenuList extends React.PureComponent<MenuListProps> {

  constructor(props: MenuListProps) {
    super(props);
  }

  render() {
    const { id, type, children, disabled } = this.props;
    return (
      <Droppable droppableId={id} type={type} isDropDisabled={disabled}>
        {(provided) => (
          <Wrapper ref={provided.innerRef} {...provided.droppableProps}>
            {children}
            {provided.placeholder}
          </Wrapper>
        )}
      </Droppable>
    );
  }

}
