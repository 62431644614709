import * as React from "react";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../mobx/components";
import {withTranslation, WithTranslation} from "react-i18next";
import {UI} from "../../../../../core/ui";
import { RestaurantItemModal } from "../../../common/item-modal";
import {action, observable} from "mobx";
import {Untrusive} from "../../../../../core/libs/untrusive";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment-timezone";
import nanoid from "nanoid";
import Big from "big.js";
import styled from "styled-components";
import { logger, Formats, PaymentMethods } from "@lib/common";
import { ModalContent, ModalTabs, OrderNumber, OrderItems, OrderTotals, DetailRowList, RelativeTime, OrderTimeline, Select, LinkTag, Tag } from "@lib/components";

interface Props extends WithTranslation {}

const NumberTag = styled.span`
  border-radius: 50%;
  background: #eaeaea;
  padding: 5px;
  min-width: 20px;
  font-size: 11px;
  text-align: center;
  font-weight: 600;
`;

const orderLogTitles = {
  status_update: "Update Status",
  due_update: "Update Due Time",
  print_request: "Print Request",
  tookan_book: "Book Delivery Tookan",
};

@inject("store") @observer
export class RestaurantOrderModalClass extends MobxComponent<Props> {

  last_id: string | null = null;

  @observable tab: number = 0;

  @action setTab = (tab: number) => this.tab = tab;

  query = async () => {
    try {
      const { store } = this.injected;
      const { query } = store.router.s;
      const item = store.order;
      const queryButNoItem = query._id && !item;
      const queryItemMismatch = query._id && item && item._id !== query._id;
      if (queryButNoItem || queryItemMismatch) {

        // CHECK ORDERS COLLECTION FOR ORDER
        const order = store.orders.items.find((o) => o._id === query._id);
        if (order) {
          store.setOrder(cloneDeep(order));
          return;
        }

        // QUERY ORDER IF NOT IN COLLECTION
        const _id = query._id;
        const response = await store.api.order_find({ _id });
        if (response.outcome) {
          store.router.push(`/restaurant/${store.restaurant!._id}/orders`);
          store.setOrder(null);
          UI.notification.error(response.message);
        }
        else {
          store.setOrder(response.item);
        }

      }
    }
    catch (e) {
      logger.captureException(e);
      UI.notification.error("Error finding order, please try again", { timeout: 5000 });
    }
  }

  close = () => {
    const { store } = this.injected;
    const r = store.restaurant!;
    store.router.push(`/restaurant/${r._id}/orders`);
    store.setOrder(null);
  }

  clearSelectInput = (id: string) => {
    const el = document.getElementById(id) as HTMLSelectElement | null;
    if (el) {
      el.value = "";
    }
  }

  handleStatusSelect = async (o: T.Models.Order.Schema, status: T.Models.Order.Statuses) => {
    if (!status) return;
    this.clearSelectInput("order-status-select");
    await this.injected.store.service.order.update_status(o._id, status);
  }

  handleActionSelect = async (o: T.Models.Order.Schema, value: string) => {

    if (!value) {
      return;
    }

    this.clearSelectInput("order-action-select");

    const { store } = this.injected;

    if (value.indexOf("print-") !== -1) {
      try {
        Untrusive.start();
        const r = store.restaurant!;
        const type = "order";
        const restaurant_id = r._id;
        const printer_id = value.split("print-")[1];
        const type_id = o._id;
        const job_id = nanoid();
        await store.api.print_receipt({ type, restaurant_id, type_id, printer_id, job_id });
        UI.notification.success("Order sent to printer");
      }
      catch (e) {
        logger.captureException(e);
        UI.notification.error("An error occurred, try again soon or contact us", { timeout: 6000 });
      }
      finally {
        Untrusive.stop();
      }
    }

    if (value === "refund-stripe") {
      try {
        const proceed = confirm("Once refunded, it cannot be reversed. Refund the order?");
        if (!proceed) { return; }
        Untrusive.start();
        const { _id } = o;
        const restaurant_id = store.restaurant!._id;
        const response = await store.api.order_stripe_refund({ _id, restaurant_id });
        if (response.outcome) {
          UI.notification.error(response.message, { timeout: 6000 });
        }
        else {
          store.updateOrderComplete(response.order);
          UI.notification.success("Payment refunded");
        }
      }
      catch (e) {
        logger.captureException(e);
        UI.notification.error("An error occurred, try again soon or contact us", { timeout: 6000 });
      }
      finally {
        Untrusive.stop();
      }
    }

    if (value === "book-tookan") {
      try {
        Untrusive.start();
        const order_id = o._id;
        const response = await store.api.order_book_tookan({ _id: order_id });
        if (response.outcome) {
          UI.notification.error(response.message);
        }
        else {
          store.setOrder(response.order);
          UI.notification.success("Order booked for delivery");
        }
      }
      catch (e) {
        logger.captureException(e);
        UI.notification.error("An error occurred, try again soon or contact us", { timeout: 6000 });
      }
      finally {
        Untrusive.stop();
      }
    }

    if (value === "delete") {
      try {
        const proceed = confirm("Once deleted, it cannot be recovered. Delete the order?");
        if (!proceed) {
          return;
        }
        Untrusive.start();
        const { _id } = o;
        const restaurant_id = store.restaurant!._id;
        const response = await store.api.order_delete({ _id, restaurant_id });
        if (response.outcome) {
          UI.notification.error(response.message, { timeout: 6000 });
        }
        else {
          this.close();
          store.removeOrder(_id);
          UI.notification.success("Order deleted");
        }
      }
      catch (e) {
        logger.captureException(e);
        UI.notification.error("An error occurred, try again soon or contact us", { timeout: 6000 });
      }
      finally {
        Untrusive.stop();
      }
    }

  }

  handleReadyTimeSelect = async (o: T.Models.Order.Schema, value: string) => {
    if (!value) {
      return;
    }
    this.clearSelectInput("order-ready-time-select");
    await this.injected.store.service.order.update_ready_time(o._id, parseInt(value, 10));
  }

  renderHeader = (o: T.Models.Order.Schema) => {
    const { t } = this.injected;
    /* IS GOOGLE MAPS NEEDED BELOW BECAUSE WITHOUT IT DELIVERY AND READY TIME ARE THE EXACT SAME */
    return (
      <ModalContent paddinglr={20} paddingtb={25} className="flex-l-r-center no-border">

        <OrderNumber>
          #{o.number}
        </OrderNumber>

        {(o.config.service !== "delivery" && o.ready_in) && (
          <div className="text-right">
            <p>Est. Ready Time - <span className="font-semi-bold">{t("datetimeFromTimestamp", { value: o.ready_in.timestamp })}</span></p>
            <p className="smaller m-t-1">Last updated <RelativeTime timestamp={o.updated || o.created}/></p>
          </div>
        )}

        {(o.config.service === "delivery" && !!(o.ready_in || o.delivery_in)) && (
          <div className="text-right">
            {o.ready_in && <p>Est. Driver Pickup time - <span className="font-semi-bold">{t("datetimeFromTimestamp", { value: o.ready_in.timestamp })}</span></p>}
            {o.delivery_in && <p className="m-t-1">Est. Delivery Time - <span className="font-semi-bold">{t("datetimeFromTimestamp", { value: o.delivery_in.timestamp })}</span></p>}
            <p className="smaller m-t-1">Last updated <RelativeTime timestamp={o.updated || o.created}/></p>
          </div>
        )}

      </ModalContent>
    );

  }

  renderStatusSelect = (o: T.Models.Order.Schema) => {
    
    const options: Array<{ label: string; value: T.Models.Order.Statuses; disabled: boolean }> = [
      { label: "Cancelled", value: "cancelled", disabled: false },
      { label: "Un-Confirmed", value: "unconfirmed", disabled: false },
      { label: "Confirmed", value: "confirmed", disabled: false },
      { label: "Ready",  value: "ready", disabled: false },
      { label: "On Route", value: "on_route", disabled: false },
      { label: "Complete", value: "complete", disabled: false },
    ];

    /*
    if (o.status === "on_route") {
      options[5].disabled = false;
    }

    if (o.status === "ready") {
      options[5].disabled = false;
      options[4].disabled = false;
    }

    if (o.status === "confirmed") {
      options[5].disabled = false;
      options[4].disabled = false;
      options[3].disabled = false;
    }

    if (o.status === "unconfirmed")  {
      options[2].disabled = false;
    }

    if (o.status !== "cancelled")  {
      options[0].disabled = false;
    }

    if (o.status === "cancelled") {
      options[2].disabled = false;
      options[1].disabled = false;
    }
    */

    for (const [i, option] of options.entries()) {
      if (option.value === o.status) {
        options[i].label = `${option.label} (Current)`;
        options[i].disabled = true;
      }
    }

    if (o.config.service !== "delivery") {
      options.splice(4, 1);
    }
    
    return (
      <Select
        id="order-status-select"
        className="no-round"
        placeholder="Change Status"
        options={options}
        onChange={(e) => this.handleStatusSelect(o, e.target.value as T.Models.Order.Statuses)}
      />
    );
    
  }
  renderReadyTimeSelect = (o: T.Models.Order.Schema) => {

    const isDelivery = o.config.service === "delivery";

    const options = [];

    for (let i = 1; i < 12; i++) {
      options.push({
        label: `Add ${i * 5} minutes`,
        value: (i * 5).toString(),
      });
    }

    for (let i = 6; i < 13; i++) {
      options.push({
        label: `Add ${i * 10} minutes`,
        value: (i * 10).toString(),
      });
    }

    const initialWord = (!o.ready_in || !o.ready_in.timestamp) ? "Set" : "Modify";

    return (
      <Select
        id="order-ready-time-select"
        className="no-round no-border-top"
        placeholder={isDelivery ? `${initialWord} Est. Driver Pickup Time` : `${initialWord} Est. Ready Time`}
        options={options}
        onChange={(e) => this.handleReadyTimeSelect(o, e.target.value)}
      />
    );

  }
  renderActionsSelect = (o: T.Models.Order.Schema) => {

    const { restrictions } = this.injected.store;
    const r = this.injected.store.restaurant!;

    const options = [];

    for (const p of r.settings.printers) {
      if (!p.disabled) {
        options.push({
          label: `Print - ${p.name}`,
          value: `print-${p._id}`,
        });
      }
    }

    if (o.payment.method === "stripe" && o.payment.reference && o.payment.status !== "refunded") {
      options.push({
        label: `Refund Stripe Payment`,
        value: `refund-stripe`,
      });
    }

    const tk = r.settings.services.delivery.providers.tookan;
    if (o.config.service === "delivery" && tk && tk.api_key && tk.utc_offset && !o.config.tookan_job_id) {
      options.push({
        label: "Book for delivery (Tookan)",
        value: "book-tookan",
      });
    }

    if (restrictions.restaurant.orders_delete) {
      options.push({
        label: "Delete Order",
        value: "delete",
      });
    }

    if (options.length === 0) {
      return null;
    }

    return (
      <Select
        id="order-action-select"
        className="no-round no-border-top"
        placeholder="Actions"
        options={options}
        onChange={(e) => this.handleActionSelect(o, e.target.value)}
      />
    );

  }

  render() {

    const { tab } = this;
    const { store, t } = this.injected;
    const { restrictions } = store;
    const { query } = store.router.s;
    const _id = query._id || null;
    const item = store.order;
    const r = store.restaurant!;

    if (this.last_id !== _id) {
      this.setTab(0);
    }
    this.last_id = _id;

    const hidePrices = restrictions.misc && restrictions.misc.hide_prices;

    return (
      <div>
        <RestaurantItemModal<T.Models.Order.Schema>
          active={!!_id}
          item={item}
          query={this.query}
          close={this.close}>
          {(o) => {
            const log = o.log;
            return (
              <div>

                {this.renderHeader(o)}
                {this.renderStatusSelect(o)}
                {this.renderReadyTimeSelect(o)}
                {this.renderActionsSelect(o)}

                <OrderTimeline
                  status={o.status}
                  isDelivery={o.config.service === "delivery"}
                />

                <ModalTabs
                  value={tab}
                  onChange={(selected) => this.setTab(selected.value as number)}
                  tabs={[
                    { label: "Details", value: 0 },
                    { label: "Dishes", value: 1 },
                    { label: "Log", value: 2 },
                  ]}
                />

                {tab === 0 && (
                  <ModalContent paddinglr={20} paddingtb={25}>
                    <DetailRowList
                      items={[
                        {
                          l: "Type",
                          v: t(`constants.services.${o.config.service}`),
                        },
                        {
                          l: "Tookan Status",
                          v: (() => {
                            if (o.config.tookan_error === "failed_book") {
                              return <span className="error-text">Failed to book delivery</span>;
                            }
                            if (o.config.tookan_error === "failed_update") {
                              return <span className="error-text">Failed to update delivery booking</span>;
                            }
                            if (o.config.tookan_job_id) {
                              return <span>Booked for delivery (Track <LinkTag target="_blank" href={o.config.tookan_pickup_tracking}>Pickup</LinkTag> / <LinkTag target="_blank" href={o.config.tookan_delivery_tracking}>Delivery</LinkTag>)</span>;
                            }
                            return null;
                          })(),
                        },
                        {
                          l: "Table",
                          h: o.config.service !== "dine_in",
                          v: o.config.table,
                        },
                        {
                          l: "Number Of People",
                          h: o.config.service !== "dine_in" || o.config.due !== "later",
                          v: o.config.number_of_people,
                        },
                        {
                          l: "Payment Method",
                          v: store.getPaymentMethodName(o.payment.method),
                        },
                        {
                          l: "Payment Status",
                          h: (o.payment.method === "cash" || o.payment.method === "card" || PaymentMethods.indexOf(o.payment.method as T.Models.Restaurant.Payments.TypesBase) === -1),
                          v: (
                            <span className={(
                              o.payment.status === "success" ? "success-text" :
                                ["error", "refunded"].indexOf(o.payment.status) !== -1 ? "error-text" : ""
                            )}>
                              <span className="block">{t(`constants.payment.status.${o.payment.status}`)}</span>
                            </span>
                          ),
                        },
                        {
                          l: "Payment Reference",
                          h: (o.payment.method === "cash" || o.payment.method === "card" || !o.payment.reference),
                          v: o.payment.reference,
                        },
                        {
                          l: "Payment Refund Reference",
                          h: (o.payment.method === "cash" || o.payment.method === "card" || !o.payment.refund_reference),
                          v: o.payment.refund_reference,
                        },
                        {
                          l: "Payment Currency",
                          h: o.payment.currency === o.bill.currency,
                          v: o.payment.currency,
                        },
                        {
                          l: "Placed",
                          v: t("datetimeFromTimestamp", { value: o.created }),
                        },
                        {
                          l: "Due",
                          v: (() => {
                            if (o.config.due === "now") {
                              return "Now / ASAP";
                            }
                            const { date, time } = o.config;
                            const timestamp = moment.tz(`${date} ${time}`, Formats.moment.datetime, store.intl.s.tz).valueOf();
                            return t("datetimeFromTimestamp", { value: timestamp });
                          })(),
                        },
                        {
                          l: "Notes",
                          v: o.notes,
                        },
                        {
                          l: "Delivery Zone",
                          h: o.config.service !== "delivery" || !o.config.zone,
                          v: o.config.zone,
                        },
                        {
                          l: "Delivery Address",
                          h: o.config.service !== "delivery",
                          v: (() => {
                            const md = r.location.map_data;
                            const oc = o.config;
                            const directionsUrl = `https://www.google.com/maps/dir/?api=1&origin=${md.lat},${md.lng}&destination=${oc.lat},${oc.lng}`;
                            const directions = <span> - <LinkTag href={directionsUrl} target="_blank">Directions</LinkTag></span>;
                            return (
                              <span>
                              {(o.config.destination_misc ? `${o.config.destination_misc} - ` : "") + o.config.destination} {directions}
                            </span>
                            );
                          })(),
                        },
                        {
                          l: "Distance",
                          h: o.config.service !== "delivery" || !o.config.distance,
                          v: `${Big(o.config.distance / 1000).toFixed(3)} km`,
                        },
                        {
                          l: "Driving Time",
                          h: o.config.service !== "delivery" || !o.config.driving_time,
                          v: `${Big(o.config.driving_time / 60).toFixed(2)} min`,
                        },
                        {
                          l: "Name",
                          v: o.customer.name,
                        },
                        {
                          l: "E-Mail",
                          v: o.customer.email,
                        },
                        {
                          l: "Phone",
                          v: o.customer.phone,
                        },
                      ]}
                    />
                  </ModalContent>
                )}

                {tab === 1 && (
                  <ModalContent paddinglr={20} paddingtb={15}>
                    <OrderItems
                      items={o.dishes}
                      renderDetails={{
                        enabled: true,
                        prices: !hidePrices,
                        option_prices: !hidePrices,
                        combo_choice_names: true,
                        disable_print_name: true,
                      }}
                    />
                    {!hidePrices && (
                      <OrderTotals
                        tax_in_prices={o.bill.tax_in_prices}
                        fees={o.bill.fees}
                        taxes={o.bill.taxes}
                        discount={o.bill.discount}
                        promo={o.promos[0] || null}
                        totalCart={o.bill.cart}
                        total={o.bill.total}
                        tip={typeof o.bill.tip === "undefined" ? 0 : o.bill.tip}
                      />
                    )}
                  </ModalContent>
                )}

                {tab === 2 && (
                  <ModalContent paddinglr={20} paddingtb={20}>

                    {!!(!log || log.length === 0) && (
                      <p className="text-center p-tb-8 big">No log entries...</p>
                    )}

                    {!!(log && log.length > 0) && (
                      <div className="">
                        <p className="m-b-3 p-b-3 border-white-b-15 small1">Sorted from newest to oldest</p>
                        {log.map(({ user, timestamp, data }, i) => {
                          return (
                            <div key={i} className={i === 0 ? "" : "m-t-3 p-t-3 border-white-dash-t-15"}>
                              <div style={{ marginBottom: "7px" }} className="flex-line centered">
                                <NumberTag className="m-r-2">{log.length - i}</NumberTag>
                                <p className="font-semi-bold uppercase small" style={{ color: "#838383" }}>{orderLogTitles[data.type]}</p>
                              </div>
                              <p className="lhp">
                                {(data.type === "status_update") && `Updated from "${t(`order.status.${data.from}`)}" to "${t(`order.status.${data.to}`)}"`}
                                {(data.type === "due_update" && o.config.service === "delivery") && `Delivery time updated to "${t(`datetimeFromTimestamp`, { value: data.due })}"`}
                                {(data.type === "due_update" && o.config.service !== "delivery") && `Ready time updated to "${t(`datetimeFromTimestamp`, { value: data.due })}"`}
                                {(data.type === "print_request") && `Print request sent to printer "${data.printer}"`}
                                {(data.type === "tookan_book") && (data.success ? "Successfully booked delivery job with Tookan" : "Failed to book delivery job with Tookan")}
                              </p>
                              <div className="m-t-2">
                                <Tag className="m-r-2">{t(`datetimeFromTimestamp`, { value: timestamp })}</Tag>
                                <Tag className="small">{user === "system" ? "System" : user}</Tag>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}

                  </ModalContent>
                )}

              </div>
            );
          }}
        </RestaurantItemModal>
      </div>
    );
  }

}

export const RestaurantOrderModal = withTranslation()(RestaurantOrderModalClass);
