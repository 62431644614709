import * as React from "react";
import { FastField } from "formik";
import {MobxComponent} from "../../../../../../../mobx/components";
import {inject, observer} from "mobx-react";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {UI} from "../../../../../../../core/ui";
import { FormGroup, Button, RotateLoader, Input } from "@lib/components";

interface Props {}
interface State {}
type FormValues = T.Models.Restaurant.Schema["website"]["social_links"];

@inject("store") @observer
export class SettingsFormSocialLinks extends MobxComponent<Props, State> {

  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.website.social_links;
  }

  render() {
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.website.social_links = values;
          const update = { $set: { "website.social_links": r.website.social_links } };
          return { r, update };
        }}
        validators={{}}
        initialValues={this.initialValues}
        onSuccess={() => {}}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
          const { isSubmitting, setFieldValue, setFieldTouched } = form;
          return (
            <div className="p-4">

              <FormGroup help={<span className="font-semi-bold">Please include the full URL including https://</span>}/>

              <FastField
                name="facebook"
                render={({ field }: any) => (
                  <FormGroup optional={true} title="Facebook">
                    <Input type="url" {...field}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="instagram"
                render={({ field }: any) => (
                  <FormGroup optional={true} title="Instagram">
                    <Input type="url" {...field}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="twitter"
                render={({ field }: any) => (
                  <FormGroup optional={true} title="Twitter">
                    <Input type="url" {...field}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="pinterest"
                render={({ field }: any) => (
                  <FormGroup optional={true} title="Pinterest">
                    <Input type="url" {...field}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="youtube"
                render={({ field }: any) => (
                  <FormGroup optional={true} title="Youtube">
                    <Input type="url" {...field}/>
                  </FormGroup>
                )}
              />

              {error && <FormGroup error={error}/>}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white"/>}
                {!isSubmitting && "Save"}
              </Button>

            </div>
          );
        }}
      </RestaurantForm>
    );
  }

}
