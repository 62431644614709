import * as React from "react";
import { MobxComponent } from "../../../mobx/components";
import { TopNav, Tooltip } from "@lib/components";
import { LayoutDashboardComponents } from "./layout.c";
import { inject, observer } from "mobx-react";
import {FaLifeRing, FaPowerOff, FaRocket} from "react-icons/fa";
import {withTranslation, WithTranslation} from "react-i18next";

interface Props extends WithTranslation {
  children: React.ReactNode;
}
interface State {

}

@inject("store") @observer
class LayoutDashboardClass extends MobxComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { store, t } = this.injected;
    const pathname = store.router.s.path;
    const { restrictions } = store;
    const { children } = this.props;
    const links = [];

    if (
      restrictions.restaurant.create ||
      restrictions.restaurant.delete ||
      restrictions.restaurant.billing ||
      restrictions._.restaurantView
    ) {
      links.push({
        text: "Restaurants",
        onClick: () => store.router.push("/"),
        active: pathname === "/",
      });
    }

    if (restrictions.website.create || restrictions.website.edit) {
      links.push({
        text: "Landing Page",
        onClick: () => store.router.push("/website"),
        active: pathname === "/website",
      });
    }

    if (restrictions.staff.create || restrictions.staff.edit || restrictions.staff.delete) {
      links.push({
        text: "Staff",
        onClick: () => store.router.push("/staff"),
        active: pathname === "/staff",
      });
    }

    if (restrictions.api && (restrictions.api.create || restrictions.api.edit || restrictions.api.delete)) {
      links.push({
        text: "Developers",
        onClick: () => store.router.push("/developer"),
        active: pathname === "/developer",
      });
    }

    return (
      <div>

        <TopNav.Wrapper>
          <TopNav.Content width="lg" align="space-between">

            <div className="flex-line centered">

              <TopNav.Logo className="m-r-4">
                <img src="/images/logos/logo-no-com.png"/>
              </TopNav.Logo>

              <TopNav.Menu
                align="left"
                items={links}
              />

            </div>

            <div className="flex-line centered child-mr-7">
              <Tooltip
                text="Documentation"
                width={120}
                position="bottom"
                offset={5}>
                <a href="https://support.booknorder.co.nz" target="_blank">
                  <TopNav.Icon>
                    <FaLifeRing/>
                  </TopNav.Icon>
                </a>
              </Tooltip>
              <Tooltip text="Logout" width={60} position="bottom" offset={5}>
                <TopNav.Icon onClick={() => store.service.logout()}>
                  <FaPowerOff/>
                </TopNav.Icon>
              </Tooltip>
            </div>

          </TopNav.Content>
        </TopNav.Wrapper>

        <LayoutDashboardComponents.Content>
          {children}
        </LayoutDashboardComponents.Content>

      </div>
    );
  }

}

export const LayoutDashboard = withTranslation()(LayoutDashboardClass);
