import * as React from "react";
import { FaChevronLeft, FaChevronRight, FaChevronDown } from "react-icons/fa";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components";
import { withTheme } from "styled-components";
import moment from "moment-timezone";
import {logger} from "@lib/common";
import {withTranslation, WithTranslation} from "react-i18next";
import { ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid, LineChart, Line, Label, LabelList } from "recharts";
import {DropDown, DetailRowList, RotateLoader, Content, Box, BoxHeading, BoxSection, ButtonGroup, Button } from "@lib/components";

type Period = "day" | "week" | "month" | "year";

interface Props extends WithTranslation {}
interface State {
  start: number;
  end: number;
  period: Period;
  chart: "sales" | "orders";
  filtersActive: boolean;
  error: boolean;
  data: T.API.DashboardReportsBasicResponse | null;
}

@inject("store") @observer
class RestaurantDashboardClass extends MobxComponent<Props, State> {

  mounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.getTimeRange(Date.now(), "day"),
      chart: "sales",
      filtersActive: false,
      error: false,
      data: null,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.query();
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }

  query = async () => {
    try {
      this.setState({ error: false, data: null });
      const { store } = this.injected;
      const { start, end, period } = this.state;
      const r = store.restaurant!;
      const data = await this.injected.store.api.reports_basic({
        restaurant_id: r._id,
        start: start,
        end: end,
        period: period,
      });
      if (data.outcome && this.mounted) {
        this.setState({
          error: true,
          data: null,
        });
      }
      else if (this.mounted) {
        this.setState({ data });
      }
    }
    catch (e) {
      logger.captureException(e);
      if (this.mounted) {
        this.setState({
          error: true,
          data: null,
        });
      }
    }
  }

  setTimePeriod = (period: Period) => {
    this.setState({
      ...this.getTimeRange(Date.now(), period),
    }, this.query);
  }

  getTimeRange = (start: number, period: Period) => {
    const { store } = this.injected;
    const r = store.restaurant!;
    const tz = r.settings.region.timezone;
    const momentStart = period === "week" ? moment.tz(start, tz).startOf("isoWeek") : moment.tz(start, tz).startOf(period);
    const momentEnd = period === "week" ? moment.tz(start, tz).endOf("isoWeek") : moment.tz(start, tz).endOf(period);
    return {
      start: momentStart.valueOf(),
      end: momentEnd.valueOf(),
      period: period,
    };
  }

  changeTimeRange = (direction: "forward" | "backward") => {
    const { start, period } = this.state;
    const fn = direction === "forward" ? "add" : "subtract";
    const next = moment(start)[fn](1, period).valueOf();
    this.setState({
      ...this.getTimeRange(next, period),
    }, this.query);
  }

  reset = () => {
    this.setTimePeriod("day");
  }

  renderHeader = () => {
    const { t, store } = this.injected;
    const { start, end, period, filtersActive } = this.state;
    const r = store.restaurant!;
    const startToday = moment.tz(start, r.settings.region.timezone).isSame(Date.now(), "day");
    const endToday = moment.tz(end, r.settings.region.timezone).isSame(Date.now(), "day");
    const isToday = startToday && endToday;
    const displayStart = t("dateFriendlyFromTimestamp", { value: start });
    const displayEnd = t("dateFriendlyFromTimestamp", { value: end });

    let displayRange = `${displayStart} - ${displayEnd}`;
    if (isToday) {
      displayRange = "Today";
    }
    else if (period === "day") {
      displayRange = displayStart;
    }

    return (
      <div className="flex-l-r-center m-b-6">
        <h1>Dashboard</h1>
        <div className="flex-line centered">
          <div className="text-right m-r-3">
            <p className="font-semi-bold m-b-1">Viewing Reports For</p>
            <p className="big">{displayRange}</p>
          </div>
          <DropDown
            active={filtersActive}
            open={() => this.setState({ filtersActive: true })}
            close={() => this.setState({ filtersActive: false })}
            width={420}
            offset={15}
            flow="left"
            content={(
              <div className="p-3">
                <div className="flex-l-r-center">

                  <div className="text-left">
                    <p className="font-semi-bold m-b-2 small">Date Range</p>
                    <ButtonGroup
                      size={"xs"}
                      width={66}
                      baseColor="white"
                      onSelect={(selected) => this.setTimePeriod(selected.value as Period)}
                      selected={period}
                      options={[
                        { value: "day", name: "Day" },
                        { value: "week", name: "Week" },
                        { value: "month", name: "Month" },
                        { value: "year", name: "Year" },
                      ]}/>
                  </div>

                  <div className="text-right">
                    <p className="font-semi-bold m-b-2 small">Change Date</p>
                    <ButtonGroup
                      size={"xs"}
                      width={40}
                      baseColor="white"
                      onSelect={(s) => this.changeTimeRange(s.value as "forward" | "backward")}
                      options={[
                        { value: "backward", name: <FaChevronLeft/> },
                        { value: "forward", name: <FaChevronRight/> },
                      ]}/>
                  </div>

                </div>
              </div>
            )}>
            <Button
              className="p-lr-2"
              size="xxs"
              color="primary-inverse">
              <FaChevronDown/>
            </Button>
          </DropDown>
        </div>
      </div>
    );
  }

  renderTotals = () => {
    const { data, error } = this.state;
    const { t } = this.injected;
    if (!data || data.outcome === 1 || error) {
      return null;
    }
    return (
      <div className="m-t-6 grid-2 md sm-gap">
        <Box
          shadow="one"
          className="col no-border">
          <div className="p-3 flex-l-r-center">
            <p className="big font-semi-bold">Total Sales</p>
            <p className="big">{t("currency", { value: data.sales_total })}</p>
          </div>
        </Box>
        <Box
          shadow="one"
          className="col no-border">
          <div className="p-3 flex-l-r-center">
            <p className="big font-semi-bold">Total Orders</p>
            <p className="big">{t("number", { value: data.orders_total })}</p>
          </div>
        </Box>
      </div>
    );
  }

  renderChart = () => {
    const { data, error, chart } = this.state;
    const { theme, t } = this.injected;
    if (!data || data.outcome === 1 || error) {
      return null;
    }

    const isSales = chart === "sales";

    const chartData = (!data || data.outcome !== 0) ? [] :
      (isSales ? data.sales_breakdown : data.orders_breakdown)
        .map(({l, v}) => ({ name: l, value: v }));

    return (
      <div className="m-t-6">

        <ButtonGroup
          size={"xxs"}
          selected={chart}
          buttonClassName="no-round-bottom"
          onSelect={(s) => this.setState({ chart: s.value as "sales" | "orders" })}
          width={60}
          options={[
            { name: "Sales", value: "sales" },
            { name: "Orders", value: "orders" },
          ]}
        />

        <Box
          shadow="one"
          style={{ height: "400px" }}
          className="no-border no-round-left">
          <ResponsiveContainer width="100%" height="100%">

            <LineChart
              data={chartData}
              margin={{
                top: 50,
                right: 50,
                bottom: 30,
                left: 15,
              }}>

              <CartesianGrid stroke="#ccc"/>

              <XAxis dataKey="name" minTickGap={4}>
                <Label position="bottom" offset={5}>
                  Time
                </Label>
              </XAxis>

              <YAxis
                dataKey="value"
                label={{
                  value: isSales ? "Amount ($)" : "Orders",
                  angle: -90,
                  position: "insideLeft",
                }}
              />

              <Line
                type="monotone"
                dataKey="value"
                stroke={theme.colors.primary}
                animationDuration={600}
                fontWeight="bold"
                strokeWidth={2}>
                <LabelList
                  dataKey="value"
                  position="top"
                  offset={10}
                  content={({ x, y, stroke, value }) => value === 0 ? "" : isSales ?
                    t("currency", { value }) :
                    t("number", { value })}
                />
              </Line>

              <Tooltip
                formatter={(value) => isSales ?
                  t("currency", { value }) :
                  t("number", { value })}
              />

            </LineChart>

          </ResponsiveContainer>
        </Box>

      </div>
    );
  }

  renderDetails = () => {
    const { data, error } = this.state;
    const { t, store } = this.injected;
    if (!data || data.outcome === 1 || error) {
      return null;
    }

    const r = store.restaurant!;

    const details = [
      {
        name: "Payment Methods",
        data: Object.keys(data.payment_counts).map((k) => {
          const m = r.settings.payments[k];
          const c = data.payment_counts[k];
          const s = data.payment_sales[k];
          if (!m) return null;
          const name = store.getPaymentMethodName(k);
          return {
            l: name,
            v: `${t("currency", { value: s })} | ${c}`,
            h: (!m.enabled) && c === 0,
          };
        }),
      },
      {
        name: "Order Types",
        data: Object.keys(data.service_counts).map((key) => {
          const k = key as T.Models.Restaurant.Services.Types;
          const c = data.service_counts[k];
          const s = data.service_sales[k];
          return {
            l: t(`constants.services.${key}`),
            v: k === "table_booking" ? c : `${t("currency", { value: s })} | ${c}`,
            h: !r.settings.services[k].enabled && c === 0,
          };
        }),
      },
      {
        name: "Order Completion",
        data: [
          {
            l: "Complete",
            v: `${t("currency", { value: data.status_sales.complete })} | ${data.status_counts.complete}`,
          },
          {
            l: "In-Complete",
            v: `${t("currency", { value: data.status_sales.incomplete })} | ${data.status_counts.incomplete}`,
          },
          {
            l: "Cancelled",
            v: `${t("currency", { value: data.status_sales.cancelled })} | ${data.status_counts.cancelled}`,
          },
        ],
      },
    ];

    return (
      <div className="m-t-6 grid-3 lg sm-gap">
        {details.map((d, i) => (
          <Box
            key={i}
            shadow="one"
            className="col no-border">
            <BoxHeading className="p-tb-2 p-lr-3 flex-l-r-center">
              <p className="font-semi-bold">{d.name}</p>
              <p className="small">Sales | Count</p>
            </BoxHeading>
            <BoxSection className="p-3">
              <DetailRowList
                showNull={true}
                spacing={6}
                items={d.data}
              />
            </BoxSection>
          </Box>
        ))}
      </div>
    );

  }

  render() {
    if (!this.state) return;
    const { data, error } = this.state;
    return (
      <Content width="lg">

        {this.renderHeader()}

        {(!data && error) && (
          <div className="m-t-10 max500 center text-center">
            <p className="error-bg p-2 round">
              Something went wrong loading your data, try again shortly or contact us
            </p>
            <Button className="m-t-4" onClick={this.query} color="primary-inverse" size="xs">
              Try Again
            </Button>
          </div>
        )}

        {(!data && !error) && (
          <div className="flex-center height200">
           <RotateLoader/>
          </div>
        )}

        {((data && data.outcome === 0) && !error) && (
          <div>
            {this.renderTotals()}
            {this.renderChart()}
            {this.renderDetails()}
          </div>
        )}

      </Content>
    );
  }

}

// @ts-ignore
export const RestaurantDashboard = withTheme(withTranslation()(RestaurantDashboardClass));
