import * as React from "react";
import { Provider } from "mobx-react";
import ThemeWrapper from "./theme";
import App from "./app";
import {RootStore} from "../mobx/store";
import { I18nextProvider } from "react-i18next";

export default ({ store }: { store: RootStore }) => {
  return (
    <Provider store={store}>
      <ThemeWrapper>
        <I18nextProvider i18n={store.intl.i18n}>
          <App/>
        </I18nextProvider>
      </ThemeWrapper>
    </Provider>
  );
};


