import * as React from "react";
import { FastField } from "formik";
import { GoogleService } from "../../../../../../../core/libs/google";
import { MobxComponent } from "../../../../../../../mobx/components";
import { inject, observer } from "mobx-react";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {UI} from "../../../../../../../core/ui";
import { FormGroup, Button, RotateLoader, LinkTag, FieldFont } from "@lib/components";

interface Props {}
interface State {}
type FormValues = T.Models.Restaurant.Schema["website"]["fonts"];

@inject("store") @observer
export class SettingsFormFonts extends MobxComponent<Props, State> {
  initialValues: FormValues;
  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.website.fonts;
  }
  render() {
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.website.fonts = values;
          const update = { $set: { "website.fonts": r.website.fonts } };
          return { r, update };
        }}
        validators={{}}
        initialValues={this.initialValues}
        onSuccess={() => {}}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <div className="p-4">

              <FormGroup>
                <p className="lhp">View all available fonts at <LinkTag href="https://fonts.google.com" target="_blank">https://fonts.google.com</LinkTag></p>
              </FormGroup>

              <FastField
                name="heading"
                render={({ field }: any) => (
                  <FormGroup
                    title="Heading Font">
                    <FieldFont
                      value={field.value.family}
                      onChange={(font) => setFieldValue("heading", font)}
                      google={GoogleService}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="body"
                render={({ field }: any) => (
                  <FormGroup
                    title="Normal Font">
                    <FieldFont
                      value={field.value.family}
                      onChange={(font) => setFieldValue("body", font)}
                      google={GoogleService}/>
                  </FormGroup>
                )}
              />

              {error && <FormGroup error={error}/>}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white"/>}
                {!isSubmitting && "Save"}
              </Button>

            </div>
          );
        }}
      </RestaurantForm>
    );
  }
}
