import * as React from "react";
import {FastField} from "formik";
import {UI} from "../../../../../../../core/ui";
import {MobxComponent} from "../../../../../../../mobx/components";
import {inject, observer} from "mobx-react";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {FormGroup, Button, RotateLoader, Input, Switch, LinkTag, Select} from "@lib/components";

interface Props {
  close?: () => void;
}
interface State {}

type FormValues = T.Models.Restaurant.Schema["settings"]["integrations"];

@inject("store") @observer
export class SettingsFormIntegrationTransferWise extends MobxComponent<Props, State> {

  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.integrations;
  }

  render() {
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.integrations = values;
          const update = { $set: { "settings.integrations": r.settings.integrations } };
          return { r, update };
        }}
        validators={{}}
        initialValues={this.initialValues}
        onSuccess={() => {
          if (this.props.close) {
            this.props.close();
          }
        }}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <div className="p-4">

              <FastField
                name="transferwise.profile_type"
                render={({ field }: any) => (
                  <FormGroup
                    title="Profile Type"
                    help="TransferWise provides a personal profile and optionally a business profile. Select which profile payments should be made from">
                    <Select
                      options={[
                        { label: "Select your profile type", value: "" },
                        { label: "Personal", value: "personal" },
                        { label: "Business", value: "business" },
                      ]}
                      value={field.value}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setFieldValue("transferwise.profile_type", e.target.value || undefined);
                      }}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="transferwise.api_key"
                render={({ field }: any) => (
                  <FormGroup
                    title="API Key"
                    help="An API key can be created by going to your TransferWise account settings and clicking on 'API tokens'. Press 'Add new token', give it a name, full access and create it.">
                    <Input type="text" {...field} value={field.value || ""}/>
                  </FormGroup>
                )}
              />

              {error && <FormGroup error={error}/>}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white"/>}
                {!isSubmitting && "Save"}
              </Button>

            </div>
          );
        }}
      </RestaurantForm>
    );

  }

}
