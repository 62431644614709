import * as React from "react";
import {FastField} from "formik";
import shortid from "shortid";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../../mobx/components";
import {withTranslation, WithTranslation} from "react-i18next";
import { RestaurantUtils } from "@lib/common";
import { Modal, ModalContent, FormGroup, Input, Button, RotateLoader, TabSelect, SelectAdv, FormUpload, ButtonGroup, FieldDishIngredients, FieldDishChoices } from "@lib/components";
import {RestaurantForm} from "../../../../../../mobx/components/restaurant-form";

type FormValues = T.Models.Restaurant.Menus.Dish;

interface Props extends WithTranslation {
  type: "edit" | "create";
  menuIndex: number;
  categoryIndex: number;
  initialValues: FormValues | null;
  close: () => void;
}
interface State {
  tab: string;
  preventCloseModal: boolean;
}

@inject("store") @observer
class RestaurantFormDishC extends MobxComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      tab: "0",
      preventCloseModal: false,
    };
  }

  setTab = (tab: string) => {
    this.setState({ tab });
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.initialValues && !prevProps.initialValues) {
      this.setState({
        tab: "0",
      });
    }
  }

  render() {
    const { store } = this.injected;
    const { type, initialValues, close } = this.props;
    const { tab, preventCloseModal } = this.state;

    const restaurant = store.restaurant!;
    const currency = store.intl.s.currency;

    return (
      <Modal
        width="md"
        alignTop={true}
        active={!!initialValues}
        close={close}
        preventClose={preventCloseModal}>
        <ModalContent>
          <h3>{type === "create" ? "Create Dish" : "Edit Dish"}</h3>
          {(type === "edit" && initialValues) && <p className="m-t-1">{initialValues.name}</p>}
        </ModalContent>
        <RestaurantForm<FormValues>
          submit={async (r, values) => {

            if (values.image && !values.image._id) {
              values.image = null;
            }

            const { menuIndex, categoryIndex } = this.props;

            // CLEAN & PROCESS
            if (values.type === "combo") {

              values.option_sets = [];
              values.ingredients = [];

              const dishes = RestaurantUtils.menu.getDishes(r);
              for (const [index, choice] of values.choices.entries()) {

                // CHECK FOR CHOICES THAT NO LONGER EXIST
                for (let i = choice.dishes.length; i--;) {
                  const found = dishes.findIndex((d) => d._id === choice.dishes[i]);
                  if (found === -1) {
                    values.choices[index].dishes.splice(i, 1);
                  }
                }

                // SET LOWEST PRICE OPTION
                let lowest_price_option = 1000000;
                for (const dish of dishes) {
                  if (choice.dishes.indexOf(dish._id) !== -1 && dish.price < lowest_price_option) {
                    lowest_price_option = dish.price;
                  }
                }
                values.choices[index].lpo = lowest_price_option;

              }

            }
            else {
              values.choices = [];
            }

            // CLEAN TAGS THAT HAVE BEEN DELETED
            values.tags = values.tags.filter((tag) => {
              return r.dish_tags.findIndex((dt) => dt._id === tag) !== -1;
            });

            // UPDATE EXISTING OR PUSH NEW
            const existing = r.menus[menuIndex].categories[categoryIndex].dishes.findIndex((p) => p._id === values._id);
            if (existing === -1) {
              r.menus[menuIndex].categories[categoryIndex].dishes.push(values);
            }
            else {
              r.menus[menuIndex].categories[categoryIndex].dishes[existing] = values;
            }
            const update = { $set: { menus: r.menus } };
            return { r, update };
          }}
          validators={{
            name: (values) => {
              if (!values.name)
                return { name: "This field is required" };
            },
            price: (values) => {
              // @ts-ignore
              if (values.price === "")
                return { name: "This field is required" };
            },
          }}
          initialValues={initialValues}
          onSuccess={close}
          onSuccessMessage={type === "edit" ? "Dish edited" : "Dish created"}
          onErrorMessage="">
          {({ form, error, getFieldError }) => {
            const { isSubmitting, setFieldValue, values } = form;
            const isCombo = values.type === "combo";
            return (
              <div>

                <TabSelect
                  id="menu-tab-options"
                  className="border-white-tb-15"
                  hasBorder={true}
                  screenWidth={this.injected.store.view.screen_width}
                  onChange={(v) => this.setState({ tab: v.value })}
                  value={tab}
                  values={[
                    { label: "General", value: "0" },
                    { label: "Image & Tags", value: "1" },
                    { label: isCombo ? "Dish Choices" : "Options & Ingredients", value: isCombo ? "3" : "2" },
                  ]}/>

                {tab === "0" && (
                  <ModalContent>
                    {type !== "edit" && (
                      <FastField
                        name="type"
                        render={({field}: any) => (
                          <FormGroup title="Dish Type" help="A combo dishes allows customers to make several choices between selected standard dishes">
                            <ButtonGroup
                              width={100}
                              size="sm"
                              selected={field.value}
                              options={[
                                { name: "Standard", value: "standard" },
                                { name: "Combo", value: "combo" },
                              ]}
                              onSelect={({value}) => setFieldValue("type", value)}
                            />
                          </FormGroup>
                        )}
                      />
                    )}
                    <FastField
                      name="name"
                      render={({ field }: any) => (
                        <FormGroup
                          title="Name"
                          help="A unique name for your dish"
                          error={getFieldError(form, "name")}>
                          <Input type="text" {...field} required={true}/>
                        </FormGroup>
                      )}
                    />
                    <FastField
                      name="price"
                      render={({ field }: any) => (
                        <FormGroup
                          title={`Price (${currency.symbol})`}
                          help="This cost of this item"
                          error={getFieldError(form, "price")}>
                          <Input type="number" step={currency.step} min={0} {...field} required={true}/>
                        </FormGroup>
                      )}
                    />
                    {values.type === "combo" && (
                      <FastField
                        name="price_type"
                        render={({field}: any) => (
                          <FormGroup
                            title="Combo Price Type"
                            help="Standard pricing means all the combo choices are set at same price. Difference pricing will take into account the price differences between the various dishes to increase the price if certain dishes are selected">
                            <ButtonGroup
                              width={100}
                              size="sm"
                              selected={field.value}
                              options={[
                                { name: "Standard", value: "standard" },
                                { name: "Difference", value: "difference" },
                              ]}
                              onSelect={({value}) => setFieldValue("price_type", value)}
                            />
                          </FormGroup>
                        )}
                      />
                    )}
                    <FastField
                      name="display_name"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Display Name"
                          help="Will override the unique name in your store">
                          <Input type="text" {...field}/>
                        </FormGroup>
                      )}
                    />
                    <FastField
                      name="print_name"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Print Name"
                          help="Will override the unique name on your printed order receipts">
                          <Input {...field} type="text" value={field.value || ""}/>
                        </FormGroup>
                      )}
                    />
                    <FastField
                      name="description"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Description"
                          help="Will be displayed in your menu and dish popup">
                          <Input type="text" {...field}/>
                        </FormGroup>
                      )}
                    />
                    <FastField
                      name="subtitle"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Subtitle"
                          help="Will be displayed your dish name in bold font. Keep it short and sweet">
                          <Input type="text" {...field}/>
                        </FormGroup>
                      )}
                    />
                    {isCombo && (
                      <FastField
                        name="option_set_blacklist"
                        render={({ field }: any) => (
                          <FormGroup
                            optional={true}
                            title="Option Set Blacklist"
                            help="Designate option sets that will be removed from your combo dish choices">
                            <SelectAdv
                              type="multi"
                              value={field.value || []}
                              options={restaurant.option_sets.map((item) => ({
                                label: item.name,
                                value: item._id,
                              }))}
                              onChange={(selectedValues: string[]) => {
                                setFieldValue("option_set_blacklist", selectedValues);
                              }}
                            />
                          </FormGroup>
                        )}
                      />
                    )}
                  </ModalContent>
                )}

                {tab === "1" && (
                  <ModalContent>

                    <FormGroup optional={true} title="Image">
                      <FastField
                        name="image"
                        render={({ field }: any) => (
                          <FormUpload
                            maxSize={1000}
                            imagesOnly={true}
                            value={[field.value]}
                            customSource={true}
                            onRemove={() => setFieldValue("image", null)}
                            onOpen={() => this.setState({ preventCloseModal: true })}
                            onClose={() => this.setState({ preventCloseModal: false })}
                            onChange={(files) => setFieldValue("image", files[0])}/>
                        )}/>
                    </FormGroup>

                    <FastField
                      name="tags"
                      render={({ field }: any) => (
                        <FormGroup optional={true} title="Tags" help="Select tags to be shown with the dish">
                          <SelectAdv
                            type="multi"
                            value={field.value}
                            options={restaurant.dish_tags.map((item) => ({
                              label: item.name,
                              value: item._id,
                            }))}
                            onChange={(selectedValues: string[]) => {
                              setFieldValue("tags", selectedValues);
                            }}
                          />
                        </FormGroup>
                      )}
                    />
                  </ModalContent>
                )}

                {tab === "2" && (
                  <ModalContent>
                    <FastField
                      name="option_sets"
                      render={({ field }: any) => (
                        <FormGroup optional={true} title="Option Sets" help="Select option sets to be applied to the dish. Option sets are ordered according to how they are listed on the option set page, not by the order added here">
                          <SelectAdv
                            type="multi"
                            value={field.value}
                            options={restaurant.option_sets.map((item) => ({
                              label: item.name,
                              value: item._id,
                            }))}
                            onChange={(selectedValues: string[]) => {
                              setFieldValue("option_sets", selectedValues);
                              if (selectedValues.indexOf(form.values.option_set_primary || "") !== -1) {
                                setFieldValue("option_set_primary", "");
                              }
                            }}
                          />
                        </FormGroup>
                      )}
                    />
                    <FormGroup
                      optional={true}
                      title="Primary Option Set"
                      help="Designate a primary option set which is 'required' and has 'multi-select' disabled. This will result in multiple prices showing on your dish card. For example, if you have 2 pizza sizes, regular and large ($5+), the dish will show your base dish price along with the price of the large option">
                      <SelectAdv
                        type="single"
                        options={
                          restaurant.option_sets
                            .filter((it) => {
                              return (
                                form.values.option_sets.indexOf(it._id) !== -1 &&
                                it.conditions.required &&
                                !it.conditions.multi_select
                              );
                            })
                            .map((item) => ({
                              label: item.name,
                              value: item._id,
                            }))
                        }
                        value={form.values.option_set_primary || null}
                        onChange={(option: string) => setFieldValue("option_set_primary", option)}
                      />
                    </FormGroup>
                    <FastField
                      name="ingredients"
                      render={({ field }: any) => (
                        <FormGroup optional={true} title="Ingredients" help="Define a list of ingredients for this dish that be can removed by the customer when ordering">
                          <Button type="button" color="primary-inverse" size="xs" onClick={() => {
                            setFieldValue("ingredients", [ ...field.value, {
                              _id: shortid.generate(),
                              name: "",
                              active: true,
                            } as T.Models.Restaurant.Menus.DishIngredient]);
                          }}>
                            Add Ingredient
                          </Button>
                          {field.value.length !== 0 && <div className="m-tb-3"/>}
                          {field.value.length !== 0 && (
                            <FieldDishIngredients
                              values={field.value}
                              onChange={(item) => setFieldValue("ingredients", item)}
                            />
                          )}
                        </FormGroup>
                      )}
                    />
                  </ModalContent>
                )}

                {tab === "3" && (
                  <ModalContent>
                    <FastField
                      name="choices"
                      render={({ field }: any) => (
                        <FormGroup help="Define choices that can contain a list of dishes a customer can select from. If a choice only has a single dish, then it will be preselect when ordering">
                          <Button type="button" color="primary-inverse" size="xs" onClick={() => {
                            setFieldValue("choices", [ ...field.value, {
                              _id: shortid.generate(),
                              name: "",
                              dishes: [],
                              selected: null,
                              lpo: 0,
                            } as T.Models.Restaurant.Menus.DishChoice]);
                          }}>
                            Add Choice
                          </Button>
                          {field.value.length !== 0 && <div className="m-tb-3"/>}
                          {field.value.length !== 0 && (
                            <FieldDishChoices
                              r={restaurant}
                              values={field.value}
                              onChange={(item) => setFieldValue("choices", item)}
                            />
                          )}
                        </FormGroup>
                      )}
                    />
                  </ModalContent>
                )}

                <ModalContent>
                  {error && <FormGroup error={error}/>}
                  <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white"/>}
                    {!isSubmitting && "Save"}
                  </Button>
                </ModalContent>

              </div>
            );
          }}
        </RestaurantForm>
      </Modal>
    );

  }

}

export const RestaurantFormDish = withTranslation()(RestaurantFormDishC);
