import * as React from "react";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../mobx/components";
import {withTranslation, WithTranslation} from "react-i18next";
import {UI} from "../../../../../core/ui";
import {logger} from "@lib/common";
import { RestaurantItemModal } from "../../../common/item-modal";
import { OrderNumber, DetailRowList, OrderTimeline, Select, ModalContent } from "@lib/components";
import {Untrusive} from "../../../../../core/libs/untrusive";
import {action, observable} from "mobx";
import cloneDeep from "lodash/cloneDeep";
import nanoid from "nanoid";

interface Props extends WithTranslation {}

@inject("store") @observer
export class RestaurantBookingModalClass extends MobxComponent<Props> {

  @observable tab: number = 0;

  @action setTab = (tab: number) => this.tab = tab;

  query = async () => {
    try {
      const { store } = this.injected;
      const { query } = store.router.s;
      const item = store.booking;
      const queryButNoItem = query._id && !item;
      const queryItemMismatch = query._id && item && item._id !== query._id;
      if (queryButNoItem || queryItemMismatch) {

        // CHECK ORDERS COLLECTION FOR ORDER
        const booking = store.bookings.items.find((o) => o._id === query._id);
        if (booking) {
          store.setBooking(cloneDeep(booking));
          return;
        }

        // QUERY ORDER IF NOT IN COLLECTION
        const _id = query._id;
        const response = await store.api.booking_find({ _id });
        if (response.outcome) {
          store.router.push(`/restaurant/${store.restaurant!._id}/bookings`);
          store.setBooking(null);
          UI.notification.error(response.message);
        }
        else {
          store.setBooking(response.item);
        }

      }
    }
    catch (e) {
      logger.captureException(e);
      UI.notification.error("Error finding booking, please try again", { timeout: 5000 });
    }
  }

  close = () => {
    const { store } = this.injected;
    const r = store.restaurant!;
    store.router.push(`/restaurant/${r._id}/bookings`);
    store.setBooking(null);
  }

  clearSelectInput = (id: string) => {
    const el = document.getElementById(id) as HTMLSelectElement | null;
    if (el) {
      el.value = "";
    }
  }

  handleStatusSelect = async (item: T.Models.Booking.Schema, status: T.Models.Booking.Statuses) => {
    if (!status) return;
    this.clearSelectInput("booking-status-select");
    await this.injected.store.service.booking.update_status(item._id, status);
  }

  handleActionSelect = async (item: T.Models.Booking.Schema, value: string) => {

    this.clearSelectInput("booking-action-select");

    if (!value) {
      return;
    }

    const { store } = this.injected;

    if (value.indexOf("print-") !== -1) {
      try {
        Untrusive.start();
        const r = store.restaurant!;
        const type = "booking";
        const restaurant_id = r._id;
        const printer_id = value.split("print-")[1];
        const type_id = item._id;
        const job_id = nanoid();
        await store.api.print_receipt({ type, restaurant_id, type_id, printer_id, job_id });
        UI.notification.success("Booking sent to printer");
      }
      catch (e) {
        logger.captureException(e);
        UI.notification.error("An error occurred, try again soon or contact us", { timeout: 6000 });
      }
      finally {
        Untrusive.stop();
      }
    }

    if (value === "delete") {
      try {
        const proceed = confirm("Once deleted, it cannot be recovered. Delete the booking?");
        if (!proceed) {
          return;
        }
        Untrusive.start();
        const { _id } = item;
        const restaurant_id = store.restaurant!._id;
        const response = await store.api.booking_delete({ _id, restaurant_id });
        if (response.outcome) {
          UI.notification.error(response.message, { timeout: 6000 });
        }
        else {
          this.close();
          store.removeBooking(_id);
          UI.notification.success("Booking deleted");
        }
      }
      catch (e) {
        logger.captureException(e);
        UI.notification.error("An error occurred, try again soon or contact us", { timeout: 6000 });
      }
      finally {
        Untrusive.stop();
        this.clearSelectInput("booking-action-select");
      }
    }

  }

  renderHeader = (item: T.Models.Booking.Schema) => {
    return (
      <ModalContent paddinglr={20} paddingtb={25} className="flex-l-r-center no-border">
        <OrderNumber>
          #{item.number}
        </OrderNumber>
      </ModalContent>
    );
  }

  renderStatusSelect = (item: T.Models.Booking.Schema) => {
    
    const options: Array<{ label: string; value: T.Models.Booking.Statuses; disabled: boolean }> = [
      { label: "Cancelled", value: "cancelled", disabled: false },
      { label: "Un-Confirmed", value: "unconfirmed", disabled: false },
      { label: "Confirmed", value: "confirmed", disabled: false },
    ];

    for (const [i, option] of options.entries()) {
      if (option.value === item.status) {
        options[i].label = `${option.label} (Current)`;
        options[i].disabled = true;
      }
    }
    
    return (
      <Select
        id="booking-status-select"
        className="no-round"
        placeholder="Change Status"
        options={options}
        onChange={(e) => this.handleStatusSelect(item, e.target.value as T.Models.Booking.Statuses)}
      />
    );
    
  }

  renderActionsSelect = (item: T.Models.Booking.Schema) => {

    const { restrictions } = this.injected.store;
    const r = this.injected.store.restaurant!;

    const options = [];

    for (const p of r.settings.printers) {
      if (!p.disabled) {
        options.push({
          label: `Print - ${p.name}`,
          value: `print-${p._id}`,
        });
      }
    }

    if (restrictions.restaurant.bookings_delete) {
      options.push({
        label: "Delete Booking",
        value: "delete",
      });
    }

    if (options.length === 0) {
      return null;
    }

    return (
      <Select
        id="booking-action-select"
        className="no-round no-border-top"
        placeholder="Actions"
        options={options}
        onChange={(e) => this.handleActionSelect(item, e.target.value)}
      />
    );

  }
  
  render() {

    const { store, t } = this.injected;
    const { query } = store.router.s;
    const _id = query._id || null;
    const item = store.booking;

    return (
      <div>
        <RestaurantItemModal<T.Models.Booking.Schema>
          active={!!_id}
          item={item}
          query={this.query}
          close={this.close}>
          {(b) => (
            <div>

              {this.renderHeader(b)}
              {this.renderStatusSelect(b)}
              {this.renderActionsSelect(b)}

              <OrderTimeline
                status={b.status}
                isDelivery={false}
                isBooking={true}
              />

              <ModalContent paddinglr={20} paddingtb={25}>
                <DetailRowList
                  items={[
                    {
                      l: "Booking For",
                      v: t("datetimeFromTimestamp", { value: b.config.timestamp }),
                    },
                    {
                      l: "No. Of People",
                      v: t("number", { value: b.config.number_of_people }),
                    },
                    {
                      l: "Placed",
                      v: t("datetimeFromTimestamp", { value: b.created }),
                    },
                    {
                      l: "Last Updated",
                      h: !!b.updated,
                      v: b.updated ? t("datetimeFromTimestamp", { value: b.updated }) : "",
                    },
                    {
                      l: "Notes",
                      v: b.notes,
                    },
                    {
                      l: "Name",
                      v: b.customer.name,
                    },
                    {
                      l: "E-Mail",
                      v: b.customer.email,
                    },
                    {
                      l: "Phone",
                      v: b.customer.phone,
                    },
                  ]}
                />
              </ModalContent>

            </div>
          )}
        </RestaurantItemModal>
      </div>
    );
  }

}

export const RestaurantBookingModal = withTranslation()(RestaurantBookingModalClass);
