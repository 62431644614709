import * as React from "react";
import { inject, observer } from "mobx-react";
import {MobxComponent} from "../../mobx/components";
import { Button } from "@lib/components";
import {UI} from "../../core/ui";

@inject("store") @observer
export class AudioUnlock extends MobxComponent<{ className?: string }, {}> {

  onClick = () => {
    setTimeout(() => {
      const n = this.injected.store.notifications;
      if (n.s.audio_unlocked) {
        UI.notification.success("Audio enabled");
      }
    }, 600);
  }

  render() {
    const n = this.injected.store.notifications;
    if (n.s.audio_unlocked) return null;
    return (
      <Button
        id="audio-notification-button"
        size="xxs"
        color="primary-inverse"
        paddinglr={5}
        onClick={this.onClick}
        className={this.props.className}>
        Enable Audio
      </Button>
    );
  }

}
