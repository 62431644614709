import * as React from "react";
import { UI } from "../../../../../core/ui";
import {
  logger,
  validators,
  Locales,
  CurrenciesList,
  Currencies,
  cc,
} from "@lib/common";
import { withTheme, Button, Input, Switch, FormGroup, InputAddress, FieldOpeningHours, FieldTaxes, FieldSubdomain, RotateLoader, SelectAdv, SubscriptionPlansV4, ButtonGroup, StripeElementCard } from "@lib/components";
import {Formik, Form, FastField, FormikProps, FormikActions} from "formik";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../mobx/components";
import {FormFieldValidators, FormHelpers} from "../../../../../core/form";
import {GoogleService} from "../../../../../core/libs/google";
import {MapboxAPI} from "../../../../../core/libs/mapbox";
import {TimezonesList} from "@lib/common/dist/esm/data/timezones";

type FormValues = T.API.RestaurantCreateRequest;

interface Props {
  back?: () => void;
  theme: T.ThemeInterface;
}

interface State {
  error: string | null;
  card_name: string;
  card_error: string;
}

const initialValues: FormValues = {
  subdomain: "",
  name: "",
  address: "",
  map_data: {
    type: "osm",
    components: {},
    lat: 0,
    lng: 0,
  },
  phone: "",
  opening_hours: [{
    day: "Monday",
    open: "09:00",
    close: "21:00",
    h24: false,
  }],
  locale: "",
  timezone: "",
  formats: {
    date: "DD/MM/YYYY",
    time: "h:mm a",
  },
  currency: null,
  taxes: [],
  tax_in_prices: false,
};

@inject("store") @observer
class RestaurantFormSetupClass extends MobxComponent<Props, State> {

  prePopulated: boolean = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      card_name: "",
      card_error: "",
    };
  }

  onSubmit = async (values: FormValues, form: FormikActions<FormValues>) => {

    const { service } = this.injected.store;

    const stop = () => {
      form.setSubmitting(false);
    };

    try {

      this.setState({ error: null });

      form.setSubmitting(true);

      const res = await this.injected.store.api.restaurant_create(values);

      stop();

      if (res.outcome) {
        this.setState({ error: res.message });
        return;
      }

      UI.notification.success("Restaurant created");

      this.props.back!();

      service.track("Restaurant: Created", {
        name: values.name,
        timestamp: Date.now(),
      });

    }
    catch (e) {
      logger.captureException(e);
      this.setState({ error: "Error creating restaurant, please try again or contact us" });
      stop();
    }

  }

  validate = (values: FormValues) => {
    const { errors, isError } = FormHelpers.validate<FormValues>(values, this.validators);
    if (this.state.error && !isError) {
      this.setState({ error: "" });
    }
    else if (!this.state.error && isError) {
      this.setState({ error: "There are errors in your form that need correcting, please scroll up and check other tabs if needed" });
    }
    return errors;
  }

  validators: FormFieldValidators<FormValues> = {
    name: (values) => {
      if (!values.name)
        return { name: "Required - Enter a name for your store location" };
      return undefined;
    },
    address: (values) => {
      const md = values.map_data;
      if (md.type === "google_maps" || md.type === "osm") {
        if (!values.address || !md.components || !md.lat || !md.lng) {
          return { address: "Required - search and select your store address" };
        }
      }
      else if (!values.address) {
        return { address: "Required - enter your store address" };
      }
      return undefined;
    },
    opening_hours: (values) => {
      const { error } = validators.business_hours(values.opening_hours);
      if (error) {
        return { opening_hours: error };
      }
      return undefined;
    },
    locale: (values) => {
      if (!values.locale)
        return { locale: "Required - search and select your locale" };
      return undefined;
    },
    timezone: (values) => {
      if (!values.timezone)
        return { timezone: "Required - search and select your timezone" };
      return undefined;
    },
    currency: (values) => {
      if (!values.currency)
        return { currency: "Required - search and select your currency" };
      return undefined;
    },
    taxes: (values) => {
      if (values.taxes) {
        for (const tax of values.taxes) {
          if (!tax.rate || !tax.name) {
            return { taxes: `One of your taxes is missing a name or tax rate. Please ensure all the fields are completed` };
          }
        }
      }
      return undefined;
    },
  };

  getFieldError = (form: FormikProps<FormValues>, field: keyof FormValues) => {
    return FormHelpers.error(form, field);
  }

  prePopulate = async (updateForm: (data: { locale: string, timezone: string }) => void) => {
    try {
      if (!this.prePopulated) {
        this.prePopulated = true;

        let locale = "";
        let timezone = "";

        const languages = navigator.languages || [navigator.language];
        for (const l of languages) {
          const found = Locales.find((v) => v.value.toLowerCase() === l.toLowerCase());
          if (found) {
            locale = found.value;
            break;
          }
        }

        const { location } = await GoogleService.cords_get_current();
        const data = await GoogleService.timezone_from_cords(location.lat, location.lng);
        if (TimezonesList.indexOf(data.timeZoneId) !== -1) {
          timezone = data.timeZoneId;
        }

        updateForm({ locale, timezone });
      }
    }
    catch (e) {
      logger.captureException(e);
    }
  }

  render() {
    return (
      <Formik
        initialValues={initialValues}
        validate={this.validate}
        onSubmit={this.onSubmit}>
        {(form) => {
          const { isSubmitting, setFieldValue, setFieldTouched, setFieldError, submitCount } = form;
          this.prePopulate(({ locale, timezone }) => {
            if (locale) setFieldValue("locale", locale);
            if (timezone) setFieldValue("timezone", timezone);
          });
          const mapType = form.values.map_data.type;
          return (
            <Form>

              <FastField
                name="name"
                render={({ field }: any) => (
                  <FormGroup
                    title="Name"
                    help="The name of this restaurant location"
                    error={this.getFieldError(form, "name")}>
                    <Input type="text" {...field} required={true}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="subdomain"
                render={({ field }: any) => (
                  <FormGroup
                    title="Sub-domain"
                    help="Enter the sub-domain name where your online store will be located. This can be changed any time. A custom domain can be set after creation"
                    error={form.errors.subdomain || null}>
                    <FieldSubdomain
                      restaurant_id=""
                      website_id=""
                      base_domain={cc.hosts.stores}
                      value={field.value}
                      checkDomain={this.injected.store.api.proxy_subdomain_check}
                      onChange={(subdomain) => setFieldValue("subdomain", subdomain, false)}
                      onCheckCallback={(e) => setFieldError("subdomain", e)}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="map_data.type"
                render={({ field }: any) => (
                  <FormGroup
                    title="Map Data Source"
                    help="We recommend using Open Street Maps. If you cannot find you store address, try use Google Maps instead. Selecting 'None' will allow you to enter any address but certain delivery related features will be disabled.">
                    <ButtonGroup
                      size={"xs"}
                      selected={field.value}
                      options={[
                        { value: "osm", name: "Open Street Maps" },
                        { value: "google_maps", name: "Google Maps" },
                      ]}
                      buttonClassName="p-lr-2"
                      onSelect={(v) => {
                        setFieldValue("address", "");
                        setFieldValue("map_data", { type: v.value });
                      }}
                      width="auto"
                    />
                  </FormGroup>
                )}
              />

              <FormGroup
                title="Store Address"
                help="Search for your address and select from the dropdown"
                error={this.getFieldError(form, "address")}>
                <InputAddress
                  type={mapType}
                  gm={GoogleService}
                  mb={MapboxAPI}
                  value={form.values.address}
                  onChange={(address, map_data) => {
                    setFieldValue("address", address);
                    setFieldValue("map_data", map_data);
                  }}
                  onError={() => {}}
                />
              </FormGroup>

              <FastField
                name="phone"
                render={({ field }: any) => (
                  <FormGroup
                    optional={true}
                    title="Phone Number"
                    help="Enter your store contact number">
                    <Input {...field}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="opening_hours"
                render={({ field }: any) => (
                  <FormGroup
                    title="Opening Hours"
                    help="Enter time in 24H format, e.g. 21:00 for 9:00pm. To avoid errors, ensure time slots do not overlap or close before they open"
                    error={this.getFieldError(form, "opening_hours")}>
                    <div className="m-tb-3">
                      <FieldOpeningHours
                        hours={field.value}
                        onChange={(opening_hours) => setFieldValue("opening_hours", opening_hours)}/>
                    </div>
                  </FormGroup>
                )}
              />

              <FastField
                name="locale"
                render={({ field }: any) => (
                  <FormGroup
                    title="System Locale"
                    help="Determines your default store language and how certain dates / currencies are formatted"
                    error={this.getFieldError(form, "locale")}>
                    <SelectAdv
                      type="single"
                      options={Locales}
                      value={field.value}
                      onChange={(option: string) => setFieldValue("locale", option)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="timezone"
                render={({ field }: any) => (
                  <FormGroup
                    title="Timezone"
                    help="Used to accurately calculate store timings"
                    error={this.getFieldError(form, "timezone")}>
                    <SelectAdv
                      type="single"
                      options={TimezonesList.map((t) => ({ label: t, value: t }))}
                      value={field.value}
                      onChange={(option: string) => setFieldValue("timezone", option)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="formats.time"
                render={({ field }: any) => (
                  <FormGroup
                    title="Time Formatting"
                    help="Determines how times are formatted for this store">
                    <ButtonGroup
                      size={"xs"}
                      selected={field.value}
                      options={[
                        { value: "h:mm a", name: "12 Hour (9:00pm)" },
                        { value: "HH:mm", name: "24 Hour (21:00)" },
                      ]}
                      onSelect={(v) => setFieldValue("formats.time", v.value)}
                      width={150}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="formats.date"
                render={({ field }: any) => (
                  <FormGroup
                    title="Date Formatting"
                    help="Determines how dates are formatted. DD is the day, MM is the month and YYYY is year. Ensure characters are in uppercase. From example, DD/MM/YYYY will display dates as 21/03/2019">
                    <Input
                      type="text"
                      required={true}
                      placeholder="DD-MM-YYYY or MM/DD/YYYY"
                      {...field}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="currency"
                render={({ field }: any) => (
                  <FormGroup
                    title="Currency"
                    help="Select your store currency. This affects financial calculations and how your prices are disabled"
                    error={this.getFieldError(form, "currency")}>
                    <SelectAdv
                      type="single"
                      options={CurrenciesList}
                      value={field.value ? field.value.code : null}
                      onChange={(option: string) => {
                        const currency = Currencies[option as keyof typeof Currencies];
                        setFieldValue("currency", {
                          code: currency.code,
                          symbol: currency.symbol_native,
                          precision: currency.decimal_digits,
                        });
                      }}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="taxes"
                render={({ field }: any) => (
                  <FormGroup
                    optional={true}
                    title="Taxes (Optional)"
                    help="Taxes are calculated from top to bottom if compounded"
                    error={this.getFieldError(form, "taxes")}>
                    <div className="m-t-2">
                      <FieldTaxes
                        taxes={field.value}
                        onChange={(taxes) => {
                          if (!field.value || field.value.length === 0) {
                            setFieldTouched("taxes", false);
                          }
                          setFieldValue("taxes", taxes);
                        }}/>
                    </div>
                  </FormGroup>
                )}
              />

              <FastField
                name="tax_in_prices"
                render={({ field }: any) => (
                  <FormGroup
                    title="Tax In Prices"
                    help="Enable this if all your prices are already inclusive of tax. If this is disabled, taxes will be calculated and added to an order's total cost">
                    <Switch
                      id="tax_in_prices"
                      checked={field.value}
                      onChange={(e) => setFieldValue("tax_in_prices", e.target.checked)}/>
                  </FormGroup>
                )}
              />

              {(submitCount > 0 && this.state.error) && <FormGroup error={this.state.error}/>}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white"/>}
                {!isSubmitting && "Create Restaurant"}
              </Button>

            </Form>
          );
        }}
      </Formik>
    );
  }

}

export const RestaurantFormSetup = withTheme(RestaurantFormSetupClass);
