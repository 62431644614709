/// <reference types="@lib/types" />
require("intl");
require("animate.css/source/_base.css");
require("animate.css/source/fading_entrances/fadeInDown.css");
require("animate.css/source/fading_exits/fadeOutDown.css");
require("react-quill/dist/quill.snow.css");
require("react-day-picker/lib/style.css");
require("intro.js/introjs.css");
require("./styles/app.scss");
import {cc} from "@lib/common";
import * as Sentry from "@sentry/browser";
import { ExtraErrorData, } from "@sentry/integrations";
import smoothscroll from "smoothscroll-polyfill";

console.log(process.env.VERSION);

Sentry.init({
  enabled: cc.production,
  dsn: "https://58033a800c1842bb8c89c4d5d5890eaf@sentry.io/1804699",
  environment: cc.production ? "production" : "development",
  integrations: [
    new ExtraErrorData({ depth: 5 })
  ],
  attachStacktrace: true,
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can\"t find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
  ],
  blacklistUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});

Sentry.configureScope((scope) => {
  if (process.env.VERSION) {
    scope.setTag("version", process.env.VERSION);
  }
});

window.onunhandledrejection = (e: any) => {
  console.log("Unhandled Promise Rejection", e);
  Sentry.captureException(e);
};

smoothscroll.polyfill();

require("./render");
