import * as React from "react";
import { FastField } from "formik";
import { FormGroup, Button, RotateLoader, FieldFont, LinkTag } from "@lib/components";
import { GoogleService } from "../../../../../../core/libs/google";
import { MobxComponent } from "../../../../../../mobx/components";
import { inject, observer } from "mobx-react";
import {WebsiteForm} from "../../../../../../mobx/components/website-form";
import {UI} from "../../../../../../core/ui";

interface Props {}
interface State {}
type FormValues = T.Models.Website.Schema["fonts"];

@inject("store") @observer
export class WebsiteFormFonts extends MobxComponent<Props, State> {
  initialValues: FormValues;
  constructor(props: Props) {
    super(props);
    this.state = {};
    const w = this.injected.store.website!;
    this.initialValues = w.fonts;
  }
  render() {
    return (
      <WebsiteForm<FormValues>
        submit={async (w, values) => {
          w.fonts = values;
          const update = { $set: { "fonts": w.fonts } };
          return { w, update };
        }}
        validators={{}}
        initialValues={this.initialValues}
        onSuccess={() => {}}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <div className="p-4">

              <FormGroup>
                <p>View all available fonts at <LinkTag href="https://fonts.google.com" target="_blank">https://fonts.google.com</LinkTag></p>
              </FormGroup>

              <FastField
                name="heading"
                render={({ field }: any) => (
                  <FormGroup
                    title="Heading Font">
                    <FieldFont
                      value={field.value.family}
                      onChange={(font) => setFieldValue("heading", font)}
                      google={GoogleService}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="body"
                render={({ field }: any) => (
                  <FormGroup
                    title="Body Font">
                    <FieldFont
                      value={field.value.family}
                      onChange={(font) => setFieldValue("body", font)}
                      google={GoogleService}/>
                  </FormGroup>
                )}
              />

              {error && <FormGroup error={error}/>}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white"/>}
                {!isSubmitting && "Save"}
              </Button>

            </div>
          );
        }}
      </WebsiteForm>
    );
  }
}
