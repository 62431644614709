import * as React from "react";
import { FastField } from "formik";
import {MobxComponent} from "../../../../../../../mobx/components";
import {inject, observer} from "mobx-react";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {UI} from "../../../../../../../core/ui";
import { FormGroup, Button, RotateLoader, Input, Switch } from "@lib/components";

interface Props {}
interface State {}
interface FormValues {
  bottom_cart_button: T.Models.Restaurant.Schema["website"]["bottom_cart_button"];
  experimental_ui_1: T.Models.Restaurant.Schema["website"]["experimental_ui_1"];
  contact_form_email: T.Models.Restaurant.Schema["website"]["contact_form_email"];
  map_zoom: T.Models.Restaurant.Schema["website"]["map_zoom"];
}

@inject("store") @observer
export class WebsiteFormMisc extends MobxComponent<Props, State> {

  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = {
      bottom_cart_button: r.website.bottom_cart_button || false,
      experimental_ui_1: r.website.experimental_ui_1 || false,
      contact_form_email: r.website.contact_form_email,
      map_zoom: r.website.map_zoom || "",
    };
  }

  render() {

    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.website.bottom_cart_button = values.bottom_cart_button;
          r.website.experimental_ui_1 = values.experimental_ui_1;
          r.website.contact_form_email = values.contact_form_email;
          r.website.map_zoom = values.map_zoom;
          const update = { $set: {
            "website.bottom_cart_button": r.website.bottom_cart_button,
            "website.experimental_ui_1": r.website.experimental_ui_1,
            "website.contact_form_email": r.website.contact_form_email,
            "website.map_zoom": r.website.map_zoom,
          }};
          return { r, update };
        }}
        validators={{}}
        initialValues={this.initialValues}
        onSuccess={() => {}}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <div className="p-4">

              <FastField
                name="bottom_cart_button"
                render={({ field }: any) => (
                  <FormGroup
                    title="Show Cart & Order Button At Bottom"
                    help="If disabled, the cart and order buttons will be shown in the top navigation bar. This option only affects mobile devices. On tablets and desktops, the cart button is always in the top navigation bar">
                    <Switch
                      id={"bottom-cart-switch"}
                      checked={field.value || false}
                      onChange={(e) => setFieldValue("bottom_cart_button", e.target.checked)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="experimental_ui_1"
                render={({ field }: any) => (
                  <FormGroup
                    title="Menu Selector Only"
                    help="If enabled, the horizontal category bar will be removed and all dishes will be visible on a single screen. If you have multiple menus, a dropdown menu with a hamburger button will replace the menu select for mobile devices">
                    <Switch
                      id={"experimental_ui_1"}
                      checked={field.value || false}
                      onChange={(e) => setFieldValue("experimental_ui_1", e.target.checked)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="contact_form_email"
                render={({ field }: any) => (
                  <FormGroup optional={true} title="Contact Form E-Mail" help="Entering an e-mail address here will enable your online store contact form in the footer">
                    <Input type="email" {...field}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="map_zoom"
                render={({ field }: any) => (
                  <FormGroup optional={true} title="Map Zoom" help="The zoom level of your embedded store map. The higher the value, the more zoomed in the map will be. Default zoom value is 12 if left empty">
                    <Input type="number" {...field}/>
                  </FormGroup>
                )}
              />

              {error && <FormGroup error={error}/>}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white"/>}
                {!isSubmitting && "Save"}
              </Button>

            </div>
          );
        }}
      </RestaurantForm>
    );

  }

}
