import * as React from "react";
import {inject, observer} from "mobx-react";
import {withTheme} from "styled-components";
import {MobxComponent} from "../../../../../mobx/components";
import {withTranslation, WithTranslation} from "react-i18next";
import { cc, RestaurantUtils, Formats } from "@lib/common";
import { IconCircle, RelativeTime, Protable, OrderIcon } from "@lib/components";
import moment from "moment-timezone";

interface Props extends WithTranslation {}
interface State {}

@inject("store") @observer
class RestaurantOrdersListClass extends MobxComponent<Props, State> {

  render() {
    const { store, t } = this.injected;
    const { restrictions } = store;
    const r = store.restaurant!;
    const rid = r._id;
    const orders = store.orders;
    return (
      <Protable<T.Models.Order.Schema>
        region={r.settings.region}
        items={orders.items}
        count={orders.count}
        exportUrl={`${cc.api.url}/exports/dashboard/orders?jwt=${store.auth.token}&rid=${store.restaurant!._id}`}
        screenWidth={store.view.screen_width}
        query={{
          sortKeys: [
            { label: "Created", value: "created" },
            { label: "Due", value: "ready_in.timestamp" },
            { label: "Name", value: "customer.name" },
            { label: "Total", value: "bill.total" },
          ],
          filters: [
            {
              label: "Status",
              key: "status",
              values: [
                { label: "Un-Confirmed", value: "unconfirmed" },
                { label: "Confirmed", value: "confirmed" },
                { label: "Ready", value: "ready" },
                { label: "On Route", value: "on_route" },
                { label: "Complete", value: "complete" },
                { label: "Cancelled", value: "cancelled" },
              ],
            },
            {
              label: "Service",
              key: "config.service",
              values: [
                { label: "Pickup", value: "pickup" },
                { label: "Delivery", value: "delivery" },
                { label: "Dine In", value: "dine_in" },
              ],
            },
            {
              label: "Seen",
              key: "seen",
              values: [
                { label: "Seen", value: true },
                { label: "Un-Seen", value: false },
              ],
            },
          ],
        }}
        onClick={(order) => {
          store.router.push(`/restaurant/${rid}/orders?_id=${order._id}`);
          store.notifications.mark_read_object("order", order._id);
        }}
        fetch={async (data: T.API.RestaurantQueryRequest) => {
          data.query.restaurant_id = store.restaurant!._id;
          const response = await store.api.orders_find(data);
          if (response.outcome)
            throw new Error(response.message);
          store.updateOrders({
            items: response.items,
            count: response.count,
            page: data.page,
          });
        }}
        rowAlert={(order) => order.status === "unconfirmed"}
        columns={[
          {
            title: <div className="text-center width100">Type</div>,
            width: 80,
            render: (row) => {
              return (
                <IconCircle
                  className="center"
                  size={38}
                  icon={<OrderIcon service={row.config.service as T.Models.Restaurant.Services.Types}/>}
                  background={RestaurantUtils.order.color(row.status)}
                />
              );
            },
          },
          {
            title: "# / Name",
            render: (row) => (
              <div>
                <p className="big font-semi-bold m-b-1">{row.number}</p>
                <p>{row.customer.name}</p>
              </div>
            ),
          },
          {
            title: "Phone",
            breakpoint: 1000,
            render: (row) => <span>{row.customer.phone}</span>,
          },
          {
            title: "Email",
            breakpoint: 1100,
            render: (row) => <span>{row.customer.email}</span>,
          },
          {
            title: "Due",
            breakpoint: 400,
            render: (row) => {
              if (row.config.due === "now") {
                if (row.ready_in && row.ready_in.timestamp) {
                  return <span>{t("datetimeFromTimestamp", { value: row.ready_in.timestamp })}</span>;
                }
                return <span>Now / ASAP</span>;
              }
              const { date, time } = row.config;
              const timestamp = moment.tz(`${date} ${time}`, Formats.moment.datetime, store.intl.s.tz).valueOf();
              return <span>{t("datetimeFromTimestamp", { value: timestamp })}</span>;
            },
          },
          {
            title: "Placed",
            breakpoint: 500,
            render: (row) => <RelativeTime timestamp={row.created}/>,
          },
          {
            title: "Total",
            breakpoint: 600,
            hide: (restrictions.misc && restrictions.misc.hide_prices),
            render: (row) => <span>{t("currency", { value: row.bill.total })}</span>,
          },
          {
            title: "# Items",
            breakpoint: 600,
            render: (row) => <span>{(row.dishes || []).length}</span>,
          },
          {
            title: "Payment",
            breakpoint: 700,
            render: (row) => (
              <span>{store.getPaymentMethodName(row.payment.method)}</span>
            ),
          },
          {
            title: "Status",
            breakpoint: 700,
            render: (row) => (
              <span>{t(`order.status.${row.status}`)}</span>
            ),
          },
        ]}
      />
    );
  }

}

// @ts-ignore
export const RestaurantOrdersList = withTheme(withTranslation()(RestaurantOrdersListClass));
