import * as React from "react";
import { AuthLayout } from "../layout";
import { BoxHeading } from "@lib/components";
import { AuthLoginForm } from "./login.form";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../mobx/components";

interface Props {}
interface State {
  loading: boolean;
}

@inject("store") @observer
export class AuthLogin extends MobxComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  load = (loading: boolean) => {
    this.setState({ loading });
  }

  render() {
    return (
      <AuthLayout loading={this.state.loading}>
        <BoxHeading className="text-center">
          <h3>Login</h3>
        </BoxHeading>
        <AuthLoginForm load={this.load}/>
      </AuthLayout>
    );
  }

}
