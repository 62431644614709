import * as React from "react";
import InputMask from "react-input-mask";
import { FastField } from "formik";
import { MobxComponent } from "../../../../../../mobx/components";
import { inject, observer } from "mobx-react";
import { Modal, ModalContent, FormGroup, Input, Button, RotateLoader, Switch, TabSelect, SelectAdv, FieldOpeningHours, Tag } from "@lib/components";
import { RestaurantForm } from "../../../../../../mobx/components/restaurant-form";
import {ListServicesOptionsNoB, ListOrderTimesOptions} from "@lib/common";

type FormValues = T.Models.Restaurant.Menus.Menu;
interface Props {
  type: "edit" | "create";
  initialValues: FormValues | null;
  close: () => void;
}
interface State {
  tab: string;
}

@inject("store") @observer
export class RestaurantFormMenu extends MobxComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      tab: "0",
    };
  }

  setTab = (tab: string) => {
    this.setState({ tab });
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.initialValues && !prevProps.initialValues) {
      this.setTab("0");
    }
  }

  render() {
    const { type, initialValues, close } = this.props;
    const { tab } = this.state;
    return (
      <Modal
        width="md"
        alignTop={true}
        active={!!initialValues}
        close={close}>

        <ModalContent>
          <h3 className="">{type === "create" ? "Create Menu" : "Edit Menu"}</h3>
          {(type === "edit" && initialValues) && <p className="m-t-1">{initialValues.name} <Tag className="m-l-1">{initialValues._id}</Tag></p>}
        </ModalContent>

        <TabSelect
          id="menu-tab-options"
          className="border-white-tb-15"
          hasBorder={true}
          screenWidth={this.injected.store.view.screen_width}
          onChange={(v) => this.setTab(v.value)}
          value={tab}
          values={[
            { label: "General", value: "0" },
            { label: "Conditions", value: "1" },
          ]}/>

        <RestaurantForm<FormValues>
          submit={async (r, values) => {
            const existing = r.menus.findIndex((p) => p._id === values._id);
            if (existing === -1) {
              r.menus.push(values);
            }
            else {
              r.menus[existing] = values;
            }
            const update = { $set: { menus: r.menus } };
            return { r, update };
          }}
          validators={{
            name: (values) => {
              if (!values.name)
                return { name: "This field is required" };
            },
          }}
          initialValues={initialValues}
          onSuccess={close}
          onSuccessMessage={type === "edit" ? "Menu edited" : "Menu created"}
          onErrorMessage="">
          {({ form, error, getFieldError }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
              <div>

                {tab === "0" && (
                  <ModalContent>
                    <FastField
                      name="name"
                      render={({ field }: any) => (
                        <FormGroup
                          title="Name"
                          help="A unique name for your menu"
                          error={getFieldError(form, "name")}>
                          <Input type="text" {...field} required={true}/>
                        </FormGroup>
                      )}
                    />
                    <FastField
                      name="display_name"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Display Name"
                          help="Will override the unique name in your store"
                          error={getFieldError(form, "display_name")}>
                          <Input type="text" {...field}/>
                        </FormGroup>
                      )}
                    />
                    <FastField
                      name="description"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Description"
                          help="Will be displayed above your menu. You can use this to summarise your menu requirements. E.g. 'Available Monday-Thursday after 9:00pm, Pickup's Only'">
                          <Input type="text" {...field}/>
                        </FormGroup>
                      )}
                    />
                    <FastField
                      name="disable_dish_notes"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Disable Dish Notes"
                          help="Toggling this will prevent customers for adding notes to items ordered from this menu">
                          <Switch
                            id="disable-dish-notes"
                            checked={field.value || false}
                            onChange={(e) => setFieldValue("disable_dish_notes", e.target.checked)}/>
                        </FormGroup>
                      )}
                    />
                  </ModalContent>
                )}

                {tab === "1" && (
                  <ModalContent>

                    <FastField
                      name="conditions.times"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Order Times"
                          help="Select which order times this menu will be available for. Leave empty for this menu to apply at all times">
                          <SelectAdv
                            type="multi"
                            value={field.value}
                            onChange={(options: string[]) => {
                              setFieldValue("conditions.times", options);
                            }}
                            options={ListOrderTimesOptions}/>
                        </FormGroup>
                      )}
                    />

                    <FastField
                      name="conditions.services"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Services"
                          help="Select which services this menu will be available for. Leave empty for this menu to apply for services">
                          <SelectAdv
                            type="multi"
                            value={field.value}
                            onChange={(options: string[]) => {
                              setFieldValue("conditions.services", options);
                            }}
                            options={ListServicesOptionsNoB}/>
                        </FormGroup>
                      )}
                    />

                    <FastField
                      name="conditions.hours"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Applicable Hours"
                          help="Set which hours this menu will be available for. If no hours entered, the menu is applicable at all times. Enter time in 24H format, e.g. 21:00 for 9:00pm. Ensure time slots do not overlap or close before they open"
                          error={getFieldError(form, "hours")}>
                          <div className="m-tb-3 block">
                            <FieldOpeningHours
                              allowNone={true}
                              hours={field.value}
                              onChange={(opening_hours) => setFieldValue("conditions.hours", opening_hours)}/>
                          </div>
                        </FormGroup>
                      )}
                    />

                    <FastField
                      name="conditions.age_restricted"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Enable Age Restricted Access"
                          help="Enabling this will only allow logged in customers with their age verified to order from this menu. Age verification must be enabled for this feature to work">
                          <Switch
                            id="enable-age_restricted-switch"
                            checked={field.value}
                            onChange={(e) => setFieldValue("conditions.age_restricted", e.target.checked)}/>
                        </FormGroup>
                      )}
                    />

                    <FastField
                      name="conditions.pre_order.enabled"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Enable Pre-orders Only"
                          help="Enabling this will disable immediate orders for this menu and require that people pre-order according to the conditions below">
                          <Switch
                            id="enable-pre-order-switch"
                            checked={field.value}
                            onChange={(e) => setFieldValue("conditions.pre_order.enabled", e.target.checked)}/>
                        </FormGroup>
                      )}
                    />

                    <FastField
                      name="conditions.pre_order.days_in_advance"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Pre-order Days In Advance"
                          help="Ensures customers must pre-order items from this menu a certain amount of days in advance">
                          <Input type="number" step={1} min={0} {...field}/>
                        </FormGroup>
                      )}/>

                    <FastField
                      name="conditions.pre_order.cutoff_time"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Pre-order Cutoff Time (24H Format)"
                          help="Use in conjunction with the above option to ensure customers must place orders before a certain time on the last pre-order day. For example, if you set the cut off to 8:00pm and you require orders 2 day's in advance. For a customer to order for Friday, the latest they can put in their order is at 8:00pm on Wednesday">
                          <InputMask mask="99:99" {...field}>
                            {(inputProps: any) => <Input type="text" placeholder="00:00" {...inputProps}/>}
                          </InputMask>
                        </FormGroup>
                      )}/>

                  </ModalContent>
                )}

                <ModalContent>
                  {error && <FormGroup error={error}/>}
                  <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white"/>}
                    {!isSubmitting && "Save"}
                  </Button>
                </ModalContent>

              </div>
            );
          }}
        </RestaurantForm>

      </Modal>
    );

  }

}
