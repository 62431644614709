import * as React from "react";
import {FastField, FormikProps} from "formik";
import { parseString } from "xml2js";
import {UI} from "../../../../../../../core/ui";
import S from "string";
import shortid from "shortid";
import {MobxComponent} from "../../../../../../../mobx/components";
import {inject, observer} from "mobx-react";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {ListOrderTimesOptions, Countries, logger, CoreUtils} from "@lib/common";
import {DeliveryFeeFormulaTest} from "../../../../../../components/forms/delivery-fee-formula-test";
import { FormGroup, Button, RotateLoader, TabSelect, Input, FieldDeliveryFeeRange, Switch, ButtonGroup, InputGroup, FormDropzone, FieldDeliveryZones, SelectAdv, LinkTag, Textarea, Tag } from "@lib/components";
import {ServiceAutoStatusFields, ServiceHoursField, ServiceOrderTimeFields} from "./base";

interface Props {}
interface State {
  tab: string;
  files: File[];
}
type FormValues = T.Models.Restaurant.Schema["settings"]["services"]["delivery"];

@inject("store") @observer
export class SettingsFormServicesDelivery extends MobxComponent<Props, State> {

  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {
      tab: "0",
      files: [],
    };
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.services.delivery;
  }

  onChangeZonesFileReader = (result: any, form: FormikProps<FormValues>) => {
    try {
      const zones: T.Models.Restaurant.Services.DeliveryZone[] = result.kml.Document[0].Folder[0].Placemark.map((zone: any) => {
        const polygon = S(zone.Polygon[0].outerBoundaryIs[0].LinearRing[0].coordinates[0]).collapseWhitespace().s;
        const polygonSplit = polygon.split(" ");
        return {
          _id: shortid.generate(),
          name: zone.name[0],
          fee: "",
          disabled: false,
          polygon: polygonSplit.map((cords) => {
            const temp = cords.split(",");
            return {
              lat: parseFloat(temp[1]),
              lng: parseFloat(temp[0]),
            };
          }),
        } as T.Models.Restaurant.Services.DeliveryZone;
      });
      form.setFieldValue("zones", zones);
    }
    catch (e) {
      UI.notification.error("Something went wrong reading your file, contact us or try again", { timeout: 6000 });
      logger.captureException(e);
    }
  }

  onChangeZones = (files: File[], form: FormikProps<FormValues>) => {
    if (files.length === 0) return;
    try {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result !== "string") {
          UI.notification.error("Something went wrong reading your file, contact us or try again", { timeout: 6000 });
          return;
        }
        parseString(reader.result, (err, result) => {
          if (err) {
            UI.notification.error("Something went wrong reading your file, contact us or try again", { timeout: 6000 });
            logger.captureException(err);
          }
          else {
            this.onChangeZonesFileReader(result, form);
          }
        });
      };
      reader.readAsText(file);
    }
    catch (e) {
      logger.captureException(e);
      UI.notification.error("Something went wrong reading your file, contact us or try again", { timeout: 6000 });
    }
  }

  render() {
    const restaurant = this.injected.store.restaurant!;
    const { tab, files } = this.state;
    const currency = restaurant.settings.region.currency;
    const step = CoreUtils.currency.precision_to_step(currency.precision);

    return (
      <div>

        <TabSelect
          id="section-tab-options"
          className="border-white-tb-10"
          hasBorder={true}
          screenWidth={this.injected.store.view.screen_width}
          onChange={(v) => this.setState({ tab: v.value })}
          value={tab}
          values={[
            { label: "General", value: "0" },
            { label: "Conditions", value: "1" },
            { label: "Fees", value: "2" },
            { label: "Zones", value: "3" },
            { label: "Order Timing", value: "4" },
            { label: "Available Hours", value: "5" },
            { label: "Wait Times & Auto Statuses", value: "6" },
          ]}/>

        <RestaurantForm<FormValues>
          submit={async (r, values) => {
            r.settings.services.delivery = values;
            const update = { $set: { "settings.services.delivery": r.settings.services.delivery } };
            return { r, update };
          }}
          validators={{}}
          initialValues={this.initialValues}
          onSuccess={() => {}}
          onError={() => UI.notification.error("An error occurred")}
          onSuccessMessage="Settings Updated"
          onErrorMessage="">
          {({ form, error, getFieldError }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
              <div className="p-4">

               <FormGroup>
                 {tab === "0" && (
                   <div>
                     <FastField
                       name="enabled"
                       render={({ field }: any) => (
                         <FormGroup
                           title="Enabled"
                           help="Allow customers to place delivery orders">
                           <Switch
                             id="enable-switch"
                             checked={field.value}
                             onChange={(e) => setFieldValue("enabled", e.target.checked)}/>
                         </FormGroup>
                       )}
                     />
                     <FastField
                       name="notes"
                       render={({ field }: any) => (
                         <FormGroup
                           optional={true}
                           title="Delivery Notes"
                           help="This will be shown to customers if they choose this order method">
                           <Input type="text" {...field}/>
                         </FormGroup>
                       )}
                     />
                     <FastField
                       name="restrict_delivery_addresses"
                       render={({ field }: any) => (
                         <FormGroup
                           title="Restrict Delivery Addresses"
                           help="Restrict the delivery addresses selectable by a customer to a particular country">
                           <SelectAdv
                             type="single"
                             value={field.value}
                             onChange={(v: string) => setFieldValue("restrict_delivery_addresses", v)}
                             options={Countries}/>
                         </FormGroup>
                       )}
                     />
                     <FastField
                       name="avoid_tolls"
                       render={({ field }: any) => (
                         <FormGroup
                           title="Avoid Tolls"
                           help="Avoid delivery routes with tolls when calculating delivery distances and driving times">
                           <Switch
                             id="avoid-tolls-switch"
                             checked={field.value}
                             onChange={(e) => setFieldValue("avoid_tolls", e.target.checked)}/>
                         </FormGroup>
                       )}
                     />
                     <FastField
                       name="avoid_highways"
                       render={({ field }: any) => (
                         <FormGroup
                           title="Avoid Highways"
                           help="Avoid delivery routes with highways when calculating delivery distances and driving times">
                           <Switch
                             id="avoid-highways-switch"
                             checked={field.value}
                             onChange={(e) => setFieldValue("avoid_highways", e.target.checked)}/>
                         </FormGroup>
                       )}
                     />
                     <FastField
                       name="block_addresses"
                       render={({ field }: any) => (
                         <FormGroup
                           optional={true}
                           title="Block Delivery Addresses"
                           help="Enter address that you would like to block from placing orders. One address per line. The system will match an address containing any part of the text entered per line. Which means a line containing just 'road' will block any addresses with the word 'road' in it. Values are case in-sensitive">
                           <Textarea
                             value={field.value ? (() => {
                               const data = field.value as string[];
                               return data.reduce((a, v, i) => a + `${v}${i !== (field.value.length - 1) ? "\n" : ""}`, "");
                             })() : ""}
                             onChange={(e) => {
                               const value = e.target.value.replace(/\r\n/g, "\n").split("\n");
                               setFieldValue("block_addresses", value || []);
                             }}
                           />
                         </FormGroup>
                       )}
                     />
                   </div>
                 )}
                 {tab === "1" && (
                   <div>
                     <FastField
                       name="conditions.min_order"
                       render={({ field }: any) => (
                         <FormGroup
                           optional={true}
                           title="Minimum Order Amount ($)"
                           help="The minimum order amount required for a customer to proceed with checkout. Does not include other fees or taxes, only the customers cart value">
                           <Input type="number" step={0.01} min={0} {...field}/>
                         </FormGroup>
                       )}
                     />
                     <FastField
                       name="conditions.max_distance"
                       render={({ field }: any) => (
                         <FormGroup
                           optional={true}
                           title="Max Delivery Distance (km)"
                           help="Orders further than this distance will be rejected. Set to 0 for unlimited distance (not recommended)">
                           <Input type="number" min={0} {...field}/>
                         </FormGroup>
                       )}
                     />
                     <FastField
                       name="conditions.max_driving_time"
                       render={({ field }: any) => (
                         <FormGroup
                           optional={true}
                           title="Max Driving Time (minutes)"
                           help="Orders which take longer than this time to drive to will be rejected. Set to 0 for unlimited driving time (not recommended)">
                           <Input type="number" min={0} {...field}/>
                         </FormGroup>
                       )}
                     />
                   </div>
                 )}
                 {tab === "2" && (
                   <div>

                     <FastField
                       name="fee.type"
                       render={({ field }: any) => (
                         <FormGroup title="Fee Type">
                           <ButtonGroup
                             size="sm"
                             width={100}
                             selected={field.value}
                             options={[
                               { value: "fixed", name: "Fixed" },
                               { value: "range", name: "Range" },
                               { value: "formula", name: "Formula" },
                               { value: null, name: "None" },
                             ]}
                             onSelect={(selection) => setFieldValue("fee.type", selection.value)}
                           />
                         </FormGroup>
                       )}
                     />

                     { form.values.fee.type === "fixed" && (
                       <FastField
                         name="fee.fixed_cost"
                         render={({ field }: any) => (
                           <FormGroup
                             title="Fixed Fee"
                             help="This fixed amount will be added to your customers cart total">
                             <InputGroup iconHtml={<p className="font-semi-bold">{currency.symbol}</p>}>
                               <Input type="number" step={step} min={0} {...field}/>
                             </InputGroup>
                           </FormGroup>
                         )}
                       />
                     )}

                     { form.values.fee.type === "range" && (
                       <FastField
                         name="fee.ranges"
                         render={({ field }: any) => (
                           <FormGroup>
                             <FieldDeliveryFeeRange
                               values={field.value || []}
                               onChange={(values) => setFieldValue("fee.ranges", values)}/>
                           </FormGroup>
                         )}
                       />
                     )}

                     { form.values.fee.type === "formula" && (
                       <>
                         <FormGroup contentClassName="child-mb-15">
                           <p className="lhp">
                             A formula delivery fee allows you calculate the delivery fee based on a number of variables. The following variables are available to use:
                           </p>
                           <ul className="child-mb-7">
                             <li>Order cart total (inc. any discount) ($) <Tag style={{ padding: "3px" }}>CT</Tag></li>
                             <li>Delivery distance (meters) <Tag style={{ padding: "3px" }}>DD</Tag></li>
                             <li>Driving time (seconds) <Tag style={{ padding: "3px" }}>DT</Tag></li>
                             <li>Logged in user (1 if user, 0 if guest) <Tag style={{ padding: "3px" }}>LI</Tag></li>
                           </ul>
                           <p className="lhp">
                             Below are some example formulae you can use for ideas:
                           </p>
                           <ul className="child-mb-7">
                             <li>$1.50 per km of driving distance <Tag style={{ padding: "3px 6px" }}>roundTo((DD / 1000), 1) * 1.5</Tag></li>
                             <li>Delivery is $10 for guests and $5 for logged in users <Tag style={{ padding: "3px 6px" }}>LI == 0 ? 10 : 5</Tag></li>
                             <li>$1.50 per km and provide $5 off for logged in users <Tag style={{ padding: "3px 6px" }}>(roundTo((DD / 1000), 1) * 1.5) - (LI == 1 ? 5 : 0)</Tag></li>
                             <li>$1.50 per km and provide $1 off for every $10 ordered <Tag style={{ padding: "3px 6px" }}>(roundTo((DD / 1000), 1) * 1.5) - (roundTo(CT / 10, 0) * 1)</Tag></li>
                           </ul>
                           <p className="lhp">
                             There are a number of different operators and functions you can use in your formula. Here is what's available:
                           </p>
                           <table className="simple-table">
                             <thead>
                               <tr>
                                 <th align="left">Function</th>
                                 <th align="left">Description</th>
                               </tr>
                             </thead>
                             <tbody>
                               <tr>
                                 <td align="left">(...)</td>
                                 <td align="left">Grouping</td>
                               </tr>
                               <tr>
                                 <td align="left">+, -, ||</td>
                                 <td align="left">Addition, subtraction, concatenation</td>
                               </tr>
                               <tr>
                                 <td align="left">*, /, %</td>
                                 <td align="left">Multiplication, division, remainder</td>
                               </tr>
                               <tr>
                                 <td align="left">!</td>
                                 <td align="left">Factorial</td>
                               </tr>
                               <tr>
                                 <td align="left">^</td>
                                 <td align="left">Exponentiation</td>
                               </tr>
                               <tr>
                                 <td align="left">==, !=, &gt;=, &lt;=, &gt;, &lt;, in</td>
                                 <td align="left">Equals, not equals, etc. "in" means "is the left operand included in the right array operand?" (disabled by default)</td>
                               </tr>
                               <tr>
                                 <td align="left">and</td>
                                 <td align="left">Logical AND</td>
                               </tr>
                               <tr>
                                 <td align="left">or</td>
                                 <td align="left">Logical OR</td>
                               </tr>
                               <tr>
                                 <td align="left">x ? y : z</td>
                                 <td align="left">Ternary conditional (if x then y else z)</td>
                               </tr>
                               <tr>
                                 <td align="left">random(n)</td>
                                 <td align="left">Get a random number in the range [0, n). If n is zero, or not provided, it defaults to 1.</td>
                               </tr>
                               <tr>
                                 <td align="left">min(a,b,…)</td>
                                 <td align="left">Get the smallest (minimum) number in the list</td>
                               </tr>
                               <tr>
                                 <td align="left">max(a,b,…)</td>
                                 <td align="left">Get the largest (maximum) number in the list</td>
                               </tr>
                               <tr>
                                 <td align="left">hypot(a,b)</td>
                                 <td align="left">Hypotenuse, i.e. the square root of the sum of squares of its arguments.</td>
                               </tr>
                               <tr>
                                 <td align="left">pyt(a, b)</td>
                                 <td align="left">Alias for hypot</td>
                               </tr>
                               <tr>
                                 <td align="left">pow(x, y)</td>
                                 <td align="left">Equivalent to x^y. For consistency with JavaScript's Math object.</td>
                               </tr>
                               <tr>
                                 <td align="left">atan2(y, x)</td>
                                 <td align="left">Arc tangent of x/y. i.e. the angle between (0, 0) and (x, y) in radians.</td>
                               </tr>
                               <tr>
                                 <td align="left">if(c, a, b)</td>
                                 <td align="left">Function form of c ? a : b</td>
                               </tr>
                               <tr>
                                 <td align="left">roundTo(x, n)</td>
                                 <td align="left">Rounds x to n places after the decimal point.</td>
                               </tr>
                             </tbody>
                           </table>
                           <p className="lhp small text-right">Formula processing courtesy of <LinkTag href="https://github.com/silentmatt/expr-eval" target="_blank">Silent Matt</LinkTag></p>
                         </FormGroup>
                         <FastField
                           name="fee.formula"
                           render={({ field }: any) => (
                             <FormGroup title="Delivery Fee Formula" help="Enter your delivery fee formula based on the rules above and test it below">
                               <Input type="text" {...field} value={field.value || ""} required={true}/>
                             </FormGroup>
                           )}
                         />
                         <FormGroup title="Test Formula">
                            <DeliveryFeeFormulaTest
                              formula={form.values.fee.formula || ""}
                            />
                         </FormGroup>
                       </>
                     )}

                     {form.values.fee.type !== null && (
                       <>
                         <FastField
                           name="fee.free_delivery_minimum"
                           render={({ field }: any) => (
                             <FormGroup
                               optional={true}
                               title="Free Delivery Amount ($)"
                               help="If a customer's cart total exceeds this value, their delivery will become free and the fee above will not apply">
                               <Input type="number" step={0.01} min={0} {...field} value={field.value || ""}/>
                             </FormGroup>
                           )}
                         />
                         <FastField
                           name="fee.order_times"
                           render={({ field }: any) => (
                             <FormGroup
                               optional={true}
                               title="Restrict Delivery Fee Times"
                               help="Restrict the delivery fee to either orders due now or at a later date. Leave empty for the delivery fee to be applicable for orders at any time">
                               <SelectAdv
                                 type="multi"
                                 value={field.value || []}
                                 onChange={(options: string[]) => {
                                   setFieldValue("fee.order_times", options);
                                 }}
                                 options={ListOrderTimesOptions}
                               />
                             </FormGroup>
                           )}
                         />
                       </>
                     )}

                   </div>
                 )}
                 {tab === "3" && (
                   <div>
                     <FormGroup title="How Delivery Zones Work">
                       <p className="lhp m-b-2">Use delivery zones to ensure delivery orders are accepted only within set areas. Adding delivery zones will override existing delivery fee and max driving distance settings</p>
                       <ol>
                         <li>Visit your <LinkTag href="https://www.google.com/maps/d" target="_blank">custom google maps</LinkTag> and login if needed</li>
                         <li>Press the button that says "Create A New Map"</li>
                         <li>Use the draw line tool to create your various delivery zones making sure to give them meaningful names that you can remember</li>
                         <li>Delivery zones must be closed shapes. Do not create any additional layers</li>
                         <li>Use the "Export to KML/KMZ" option and check the box that says "Export to a .KML file". Press download to get your map file and upload it here</li>
                       </ol>
                     </FormGroup>
                     <FastField
                       name="zones"
                       render={({ field }: any) => (
                         <FormGroup
                           hide={field.value.length > 0}
                           title="Upload Delivery Zone File (.kml)">
                           <FormDropzone
                             files={files}
                             accept=".kml"
                             onChange={(f) => {
                               this.setState({ files: f });
                               this.onChangeZones(f, form);
                             }}
                           />
                         </FormGroup>
                       )}
                     />
                     <FastField
                       name="zones"
                       render={({ field }: any) => (
                         <FormGroup hide={field.value.length === 0}>
                           <FieldDeliveryZones
                             values={field.value}
                             onChange={(zones) => setFieldValue("zones", zones)}/>
                           <div className="m-t-4">
                             <Button color="primary-inverse" size="xs" onClick={() => setFieldValue("zones", [])}>
                               Remove All Zones
                             </Button>
                           </div>
                         </FormGroup>
                       )}
                     />
                   </div>
                 )}
                 {tab === "4" && <ServiceOrderTimeFields service="delivery" form={form}/>}
                 {tab === "5" && <ServiceHoursField service="delivery" error={(field) => getFieldError(form, field) as string | null | undefined}/>}
                 {tab === "6" && <ServiceAutoStatusFields service="delivery" values={form.values}/>}
               </FormGroup>

                {error && <FormGroup error={error}/>}

                <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <RotateLoader size={2} color="white"/>}
                  {!isSubmitting && "Save"}
                </Button>

              </div>
            );
          }}
        </RestaurantForm>

      </div>
    );

  }

}
