import * as React from "react";
import {FastField} from "formik";
import {MobxComponent} from "../../../../../../../mobx/components";
import {inject, observer} from "mobx-react";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {UI} from "../../../../../../../core/ui";
import { FormGroup, Button, RotateLoader, Input, Switch } from "@lib/components";

interface Props {}
interface State {}
interface FormValues {
  business: T.Models.Restaurant.Schema["settings"]["business"];
}

@inject("store") @observer
export class SettingsFormMisc extends MobxComponent<Props, State> {

  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = {
      business: r.settings.business,
    };
  }

  render() {
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.business = values.business;
          const update = { $set: { "settings.business": values.business } };
          return { r, update };
        }}
        validators={{}}
        initialValues={this.initialValues}
        onSuccess={() => {}}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <div className="p-4">

              <FormGroup
                optional={true}
                title="Validation - Phone Number RegExp"
                help="Enter a javascript regex to validate phone numbers against in order checkout">
                <FastField
                  name="business.validation.phone_regex"
                  render={({ field }: any) => (
                    <Input type="text" {...field}/>
                  )}
                />
              </FormGroup>

              <FormGroup
                title="Order Tipping"
                help="Enable this to allow customers to leave tips when placing an order">
                <FastField
                  name="business.tipping.enabled"
                  render={({ field }: any) => (
                    <Switch
                      id="business.tipping.enabled"
                      checked={field.value || false}
                      onChange={(e) => setFieldValue("business.tipping.enabled", e.target.checked)}/>
                  )}
                />
              </FormGroup>

              <FormGroup
                title="Logged In Only"
                help="Enable this to blur your store and prevent orders unless a customer has signed in with their account">
                <FastField
                  name="business.logged_in_only"
                  render={({ field }: any) => (
                    <Switch
                      id="business.logged_in_only"
                      checked={field.value || false}
                      onChange={(e) => setFieldValue("business.logged_in_only", e.target.checked)}/>
                  )}
                />
              </FormGroup>

              <FormGroup
                title="Disable Signup's"
                help="Disable any sign up options available for creating a new customer account. You can manually create accounts in the customers page of your dashboard. This will also disable social login methods">
                <FastField
                  name="business.disable_signup"
                  render={({ field }: any) => (
                    <Switch
                      id="business.disable_signup"
                      checked={field.value || false}
                      onChange={(e) => setFieldValue("business.disable_signup", e.target.checked)}/>
                  )}
                />
              </FormGroup>

              <FormGroup
                title="Disable Ordering"
                help="Toggle this to disable online ordering for this restaurant without needing to delete it">
                <FastField
                  name="business.ordering_disabled"
                  render={({ field }: any) => (
                    <Switch
                      id="business.ordering_disabled"
                      checked={field.value}
                      onChange={(e) => setFieldValue("business.ordering_disabled", e.target.checked)}/>
                  )}
                />
              </FormGroup>

              {error && <FormGroup error={error}/>}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white"/>}
                {!isSubmitting && "Save"}
              </Button>

            </div>
          );
        }}
      </RestaurantForm>
    );
  }

}
