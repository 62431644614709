import * as React from "react";
import { FastField} from "formik";
import { MobxComponent } from "../../../../../../../mobx/components";
import { inject, observer } from "mobx-react";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {UI} from "../../../../../../../core/ui";
import cloneDeep from "lodash/cloneDeep";
import { FormGroup, Button, RotateLoader, LinkTag, BlockSuccess, BlockError, FieldDomain, FieldSubdomain, TabSelect } from "@lib/components";
import {logger, cc} from "@lib/common";

interface Props {}

interface FormValuesSubdomain {
  subdomain: string;
}

interface FormValuesDomain {
  domain: string | null;
}

interface State {
  tab: string;
  certExists: boolean;
  certLoading: boolean;
  certError: boolean;
}

@inject("store") @observer
export class SettingsFormDomain extends MobxComponent<Props, State> {

  initialValuesSubdomain: FormValuesSubdomain;
  initialValuesDomain: FormValuesDomain;

  constructor(props: Props) {
    super(props);
    const r = this.injected.store.restaurant!;
    this.initialValuesSubdomain = {
      subdomain: r.subdomain,
    };
    this.initialValuesDomain = {
      domain: r.domain,
    };
    this.state = {
      tab: "0",
      certExists: false,
      certLoading: false,
      certError: false,
    };
  }

  checkCertificate = async () => {
    try {

      const { store } = this.injected;
      const r = store.restaurant!;

      if (!r.domain) return;

      this.setState({
        certExists: false,
        certLoading: true,
        certError: false,
      });

      const response = await store.api.proxy_domain_cert_check({
        domain: r.domain,
      });

      if (response.outcome) {
        UI.notification.error(response.message, { timeout: 6000 });
        this.setState({
          certExists: false,
          certLoading: false,
          certError: true,
        });
      }
      else {
        this.setState({
          certExists: response.exists,
          certLoading: false,
          certError: false,
        });
      }

    }
    catch (e) {
      logger.captureException(e);
      UI.notification.error("Something went wrong, loading your domain status");
      this.setState({
        certExists: false,
        certLoading: false,
        certError: true,
      });
    }
  }
  issueCertificate = async () => {
    try {

      const { store } = this.injected;
      const r = store.restaurant!;

      if (!r.domain) return;

      this.setState({
        certExists: false,
        certLoading: true,
        certError: false,
      });

      const response = await store.api.proxy_domain_cert_issue({
        restaurant_id: r._id,
        website_id: "",
      });

      if (response.outcome) {
        UI.notification.error(response.message, { timeout: 9000 });
        this.setState({
          certExists: false,
          certLoading: false,
          certError: true,
        });
      }
      else {
        this.setState({
          certExists: true,
          certLoading: false,
          certError: false,
        });
      }

    }
    catch (e) {
      logger.captureException(e);
      UI.notification.error("Something went wrong, try again or contact us");
      this.setState({
        certExists: false,
        certLoading: false,
        certError: true,
      });
    }
  }

  render() {

    const { tab, certError, certExists, certLoading } = this.state;

    const { store } = this.injected;
    const restaurant = this.injected.store.restaurant!;

    return (
      <div>

        <TabSelect
          id="notifications-tab-options"
          className="border-white-tb-10"
          hasBorder={true}
          screenWidth={this.injected.store.view.screen_width}
          onChange={(v) => {
            this.setState({ tab: v.value });
            if (v.value === "1") {
              this.checkCertificate();
            }
          }}
          value={tab}
          values={[
            { label: "Sub-domain", value: "0" },
            { label: "Custom Domain", value: "1" },
          ]}/>

        {tab === "0" && (
          <RestaurantForm<FormValuesSubdomain>
            customSubmit={async (values, form) => {
              try {
                form.setSubmitting(true);
                const { subdomain } = values;
                const response = await store.api.proxy_subdomain_update({
                  subdomain: subdomain,
                  restaurant_id: restaurant._id,
                  website_id: "",
                });
                if (response.outcome) {
                  UI.notification.error(response.message, { timeout: 6000 });
                }
                else {
                  UI.notification.success("Sub-domain updated");
                  const r = cloneDeep(restaurant);
                  r.subdomain = subdomain;
                  store.setRestaurant(r);
                }
              }
              catch (e) {
                logger.captureException(e);
                UI.notification.error("Something went wrong, try again or contact us");
              }
              finally {
                form.setSubmitting(false);
              }
            }}
            validators={{}}
            initialValues={this.initialValuesSubdomain}
            onSuccess={() => {}}
            onError={() => UI.notification.error("An error occurred")}
            onSuccessMessage="Settings Updated"
            onErrorMessage="">
            {({ form, error }) => {
              const { isSubmitting, setFieldValue, setFieldError } = form;
              return (
                <div className="p-4">

                  <FastField
                    name="subdomain"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Sub-domain"
                        help="Enter the sub-domain name where your online store will be located"
                        error={form.errors.subdomain}>
                        <FieldSubdomain
                          restaurant_id={restaurant._id}
                          website_id=""
                          base_domain={cc.hosts.stores}
                          value={field.value}
                          checkDomain={this.injected.store.api.proxy_subdomain_check}
                          onChange={(subdomain) => setFieldValue("subdomain", subdomain, false)}
                          onCheckCallback={(e) => setFieldError("subdomain", e)}/>
                      </FormGroup>
                    )}
                  />

                  {error && <FormGroup error={error}/>}

                  <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white"/>}
                    {!isSubmitting && "Save"}
                  </Button>

                </div>
              );
            }}
          </RestaurantForm>
        )}

        {tab === "1" && (
          <RestaurantForm<FormValuesDomain>
            customSubmit={async (values, form) => {
              try {
                form.setSubmitting(true);
                const { domain } = values;
                const response = await store.api.proxy_domain_update({
                  website_id: "",
                  restaurant_id: restaurant._id,
                  domain: domain || null,
                });
                if (response.outcome) {
                  UI.notification.error(response.message, { timeout: 6000 });
                }
                else {
                  UI.notification.success("Domain updated");
                  const r = cloneDeep(restaurant);
                  r.domain = domain || null;
                  store.setRestaurant(r);
                  this.setState({
                    certLoading: false,
                    certExists: response.cert_exists,
                    certError: false,
                  });
                }
              }
              catch (e) {
                logger.captureException(e);
                UI.notification.error("Something went wrong, try again or contact us");
              }
              finally {
                form.setSubmitting(false);
              }
            }}
            validators={{}}
            initialValues={this.initialValuesDomain}
            onSuccess={() => {}}
            onError={() => UI.notification.error("An error occurred")}
            onSuccessMessage="Settings Updated"
            onErrorMessage="">
            {({ form, error }) => {
              const { isSubmitting, setFieldValue, setFieldError } = form;
              return (
                <div className="p-4">

                  <FormGroup>
                    <p className="lhp">A custom domain name allows your online store to be hosted under your own domain name such as www.your-restaurant.com. Follow the instructions below to set it up</p>
                  </FormGroup>

                  <FormGroup title="Instructions">
                    <ol>
                      <li>Enter your domain name below and save the form</li>
                      <li>Navigate to your domain's DNS settings page on the website you purchased the domain from</li>
                      <li>Create a "A Record" for your subdomain or root domain pointing to "159.203.157.46"</li>
                      <li>Wait at least 5 minutes for your DNS to update</li>
                      <li>Press the "Generate SSL Certificate". Once generated, your site will be live on your domain</li>
                    </ol>
                    <p className="lhp m-t-2">
                      Contact us if you are having an issues and we will be happy to assist you.
                    </p>
                  </FormGroup>

                  <FastField
                    name="domain"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Custom Domain"
                        help="Enter your custom domain name where your store will be located. You cannot use a root domain, e.g. 'example.com'. You must use a subdomain such as 'www.example.com' or 'store.example.com'"
                        error={form.errors.domain}>
                        <FieldDomain
                          restaurant_id={restaurant._id}
                          website_id=""
                          value={field.value}
                          check={this.injected.store.api.proxy_domain_check}
                          onChange={(value) => setFieldValue("domain", value, false)}
                          onError={(e) => setFieldError("domain", e)}/>
                      </FormGroup>
                    )}
                  />

                  {restaurant.domain && (
                    <FormGroup title="Domain Status">
                      {certError && (
                        <p>Failed to load domain status, <LinkTag onClick={this.checkCertificate}>try again</LinkTag></p>
                      )}
                      {(!certError && certLoading) && (
                        <RotateLoader size={2}/>
                      )}
                      {(!certError && !certLoading) && (
                        <div>
                          {!certExists && (
                            <div>
                              <BlockError text="No SSL certificate found, your site won't work till you generate one" className="inline-block"/>
                              <div/>
                              <Button
                                size="xs"
                                color="primary-inverse"
                                type="button"
                                className="m-t-3"
                                onClick={this.issueCertificate}>
                                Generate SSL Certificate
                              </Button>
                            </div>
                          )}
                          {certExists && (
                            <BlockSuccess text="SSL certificate generated, your domain is live" className="inline-block"/>
                          )}
                        </div>
                      )}
                    </FormGroup>
                  )}

                  {error && <FormGroup error={error}/>}

                  <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white"/>}
                    {!isSubmitting && "Save"}
                  </Button>

                </div>
              );
            }}
          </RestaurantForm>
        )}

      </div>
    );

  }
}
