import * as React from "react";
import {FastField} from "formik";
import {arrayMove} from "react-sortable-hoc";
import {MobxComponent} from "../../../../../../../mobx/components";
import {inject, observer} from "mobx-react";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {action, observable} from "mobx";
import { HTMLEditor, FormGroup, Button, RotateLoader, Input, Switch, SelectAdv, Tag, InputBox, TabSelect, ScrollList, ScrollListItem, SwitchListSortable, Modal, ModalContent } from "@lib/components";
import { Locales, PrintDetailList, logger } from "@lib/common";

type FormValues = T.Models.Restaurant.Printer;
interface Props {
  type: "edit" | "create";
  initialValues: FormValues | null;
  close: () => void;
}

const localeList = [ { label: "Store Default", value: "" }, ...Locales ];

@inject("store") @observer
export class SettingsPrintersForm extends MobxComponent<Props, {}> {

  @observable tab: string = "0";

  @action setTab = (tab: string) => {
    this.tab = tab;
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.initialValues && !prevProps.initialValues) {
      this.setTab("0");
    }
  }

  render() {
    const { type, initialValues, close } = this.props;
    const tab = this.tab;
    return (
      <Modal
        width="md"
        alignTop={true}
        active={!!initialValues}
        close={close}>

        <ModalContent>
          <h3 className="">{type === "create" ? "Create Printer" : "Edit Printer"}</h3>
          {initialValues && <p className="m-t-1">API Key: <Tag className="m-l-1">{initialValues.api_key}</Tag></p>}
        </ModalContent>

        <TabSelect
          id="printer-tab-options"
          className="border-white-tb-15"
          hasBorder={true}
          screenWidth={this.injected.store.view.screen_width}
          onChange={(v) => this.setTab(v.value)}
          value={tab}
          values={[
            { label: "General", value: "0" },
            { label: "Printer Settings", value: "1" },
            { label: "Customization", value: "2" },
            { label: "Header & Footer", value: "3" },
          ]}/>

        <RestaurantForm<FormValues>
          submit={async (r, printer) => {

            const existingPrinter = r.settings.printers.findIndex((p) => p._id === printer._id);
            const margin = printer.paper.margins.top;
            printer.paper.margins.bottom = margin;
            printer.paper.margins.left = margin;
            printer.paper.margins.right = margin;

            if (existingPrinter === -1) {
              r.settings.printers.push(printer);
            }
            else {
              r.settings.printers[existingPrinter] = printer;
            }

            const update = { $set: { "settings.printers": r.settings.printers } };

            return { r, update };

          }}
          validators={{
            name: (values) => {
              if (!values.name)
                return { name: "This field is required" };
            },
          }}
          initialValues={initialValues}
          onSuccess={close}
          onSuccessMessage={type === "edit" ? "Printer edited" : "Printer created"}
          onErrorMessage="">
          {({ form, error, getFieldError }) => {
            const { isSubmitting, setFieldValue, values } = form;
            return (
              <div>

                {tab === "0" && (
                  <ModalContent>
                    <FastField
                      name="name"
                      render={({ field }: any) => (
                        <FormGroup
                          title="Name"
                          help="Printer configuration name. It is only used to help you identify this configuration"
                          error={getFieldError(form, "name")}>
                          <Input type="text" {...field} required={true}/>
                        </FormGroup>
                      )}
                    />
                    <FastField
                      name="auto_print_enabled"
                      render={({ field }: any) => (
                        <FormGroup
                          title="Auto Print Orders"
                          help="Automatically print out new orders as they are placed">
                          <Switch
                            id="auto_print_enabled"
                            checked={field.value}
                            onChange={(e) => setFieldValue("auto_print_enabled", e.target.checked)}/>
                        </FormGroup>
                      )}
                    />
                    <FastField
                      name="auto_print_booking_enabled"
                      render={({ field }: any) => (
                        <FormGroup
                          title="Auto Print Bookings"
                          help="Automatically print out new bookings as they are placed">
                          <Switch
                            id="auto_print_booking_enabled"
                            checked={field.value}
                            onChange={(e) => setFieldValue("auto_print_booking_enabled", e.target.checked)}/>
                        </FormGroup>
                      )}
                    />
                    <FastField
                      name="auto_print_fail_mail"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Auto Print Fail E-Mail"
                          help="If an e-mail address is entered, whenever an automated print job fails, you will receive an e-mail notification">
                          <Input type="email" {...field}/>
                        </FormGroup>
                      )}
                    />

                    <FastField
                      name="locale"
                      render={({ field }: any) => (
                        <FormGroup
                          optional={true}
                          title="Receipt Language & Locale"
                          help="Determines the language and formatting of the receipt. Defaults to your system locale"
                          error={getFieldError(form, "locale")}>
                          <SelectAdv
                            type="single"
                            options={localeList}
                            value={field.value || ""}
                            onChange={(option: string) => setFieldValue("locale", option)}
                          />
                        </FormGroup>
                      )}
                    />

                    <FastField
                      name="disabled"
                      render={({ field }: any) => (
                        <FormGroup
                          title="Disable Printer"
                          help="Disable this printer from being used without deleting it">
                          <Switch
                            id="disabled-switch"
                            checked={field.value || false}
                            onChange={(e) => setFieldValue("disabled", e.target.checked)}/>
                        </FormGroup>
                      )}
                    />

                  </ModalContent>
                )}

                {tab === "1" && (
                  <ModalContent>
                    <FastField
                      name="type"
                      render={({ field }: any) => (
                        <FormGroup
                          title="Printing Method"
                          help="Determines whether the receipt should be printed through an installed print driver or ESCPOS format"
                          error={getFieldError(form, "type")}>
                          <SelectAdv
                            type="single"
                            options={[
                              { label: "Printer Driver", value: "driver" },
                              { label: "ESCPOS", value: "escpos" },
                            ]}
                            value={field.value || "driver"}
                            onChange={(option: string) => setFieldValue("type", option)}
                          />
                        </FormGroup>
                      )}
                    />
                    {values.type === "escpos" && (
                      <>
                        <FastField
                          name="escpos_paper_width"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Paper Width (pixels)"
                              help="This is not your actual paper width, rather it is the width of the receipt image generated. For 80mm thermal printers, use 285 with a scale factor of 2"
                              error={getFieldError(form, "escpos_paper_width")}>
                              <Input {...field} type="number" required={true} placeholder="285"/>
                            </FormGroup>
                          )}
                        />
                        <FastField
                          name="escpos_paper_height"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Paper Height (pixels)"
                              help="This is not your actual paper height, rather it is the height of the receipt image generated. Use '1' to enable automatic cropping of excess whitespace at the bottom"
                              error={getFieldError(form, "escpos_paper_height")}>
                              <Input {...field} type="number" required={true} placeholder="1"/>
                            </FormGroup>
                          )}
                        />
                        <FastField
                          name="escpos_paper_scale_factor"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Paper Scale Factor"
                              help="Determines how 'zoomed' in the image is. Usually a value of 2 will suit 80mm thermal printers or 1.5-1.7 for 58mm thermal printers"
                              error={getFieldError(form, "escpos_paper_scale_factor")}>
                              <Input {...field} type="number" required={true} placeholder="2"/>
                            </FormGroup>
                          )}
                        />
                        <FastField
                          name="escpos_paper_density"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Paper Density"
                              help="Determined by your thermal printer. Most printers support 'D24'"
                              error={getFieldError(form, "escpos_paper_width")}>
                              <SelectAdv
                                type="single"
                                options={[
                                  { label: "D24", value: "d24" },
                                  { label: "S24", value: "s24" },
                                  { label: "D8", value: "d8" },
                                  { label: "S8", value: "s8" },
                                ]}
                                value={field.value || ""}
                                onChange={(option: string) => setFieldValue("escpos_paper_density", option)}
                              />
                            </FormGroup>
                          )}
                        />
                        <FastField
                          name="paper.font_size"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Font Size"
                              help="The base font size used when generating your receipt, we recommend between 12-16">
                              <Input {...field} type="number" required={true}/>
                            </FormGroup>
                          )}
                        />
                        <FastField
                          name="escpos_beeps"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Number Of Beeps"
                              help="If your printer has a buzzer, you can enter the number of beeps the printer should make after printing"
                              error={getFieldError(form, "escpos_beeps")}>
                              <Input {...field} value={typeof field.value === "number" ? field.value : ""} type="number" min={0}/>
                            </FormGroup>
                          )}
                        />
                        <FastField
                          name="escpos_characters_per_line"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Characters Per Line"
                              help="Enter the number of characters per line. Typically 80mm thermal printers are 48 characters per-line and 58mm are 32. Adjust this setting if the print doesn't fill the receipt or if lines overflow"
                              error={getFieldError(form, "escpos_paper_width")}>
                              <Input {...field} type="number" required={true} placeholder="Usually 32 or 48"/>
                            </FormGroup>
                          )}
                        />
                      </>
                    )}
                    {values.type !== "escpos" && (
                      <FormGroup
                        help="Your paper setting are used to generate the receipts correctly. If your paper settings do not match your printer's paper size as set on your printer driver, the receipt may be scaled up or down to fit inside.">
                        <div className="flex-line align-items-end">
                          <FastField
                            name="paper.width"
                            render={({ field }: any) => (
                              <FormGroup
                                className="m-b-0"
                                title="Width"
                                small_title={true}
                                no_border={true}>
                                <Input type="number" required={true} className="no-round width100px" placeholder="72" {...field} />
                              </FormGroup>
                            )}
                          />
                          <InputBox className="no-round width32 flex-center p-lr-2 m-r-2">
                            <p className="">mm</p>
                          </InputBox>
                          <FastField
                            name="paper.height"
                            render={({ field }: any) => (
                              <FormGroup
                                className="m-b-0"
                                title="Height"
                                small_title={true}
                                no_border={true}>
                                <Input type="number" required={true} className="no-round width100px" placeholder="210" {...field} />
                              </FormGroup>
                            )}
                          />
                          <InputBox className="no-round width32 flex-center p-lr-2 m-r-2">
                            <p className="">mm</p>
                          </InputBox>
                          <FastField
                            name="paper.margins.top"
                            render={({ field }: any) => (
                              <FormGroup
                                className="m-b-0"
                                title="Margin"
                                small_title={true}
                                no_border={true}>
                                <Input type="number" required={true} className="no-round width100px" placeholder="0" {...field} />
                              </FormGroup>
                            )}
                          />
                          <InputBox className="no-round width32 flex-center p-lr-2 m-r-2">
                            <p className="">mm</p>
                          </InputBox>
                          <FastField
                            name="paper.font_size"
                            render={({ field }: any) => (
                              <FormGroup
                                className="m-b-0"
                                title="Font Size"
                                small_title={true}
                                no_border={true}>
                                <Input type="number" required={true} className="no-round width100px" placeholder="16" {...field} />
                              </FormGroup>
                            )}
                          />
                          <InputBox className="no-round width32 flex-center p-lr-2">
                            <p className="">px</p>
                          </InputBox>
                        </div>
                      </FormGroup>
                    )}
                  </ModalContent>
                )}

                {tab === "2" && (
                  <ModalContent>

                    <FormGroup
                      title="Detail Customization"
                      help="Customize which order details are displayed on the receipt. Rearrange the order using the drag handles">
                      <FastField
                        name="receipt.details"
                        render={({ field }: any) => (
                          <SwitchListSortable
                            height={250}
                            items={field.value}
                            onCheck={(i, item) => {
                              const items = [...field.value];
                              items[i].enabled = !item.enabled;
                              setFieldValue("receipt.details", items);
                            }}
                            onSortEnd={({oldIndex, newIndex}) => {
                              setFieldValue("receipt.details", arrayMove(field.value, oldIndex, newIndex));
                            }}/>
                        )}
                      />
                      <Button
                        size="xs"
                        type="button"
                        className="m-t-3"
                        onClick={() => setFieldValue("receipt.details", PrintDetailList())}>
                        Reset
                      </Button>
                    </FormGroup>

                    <FormGroup
                      title="Dish Customization"
                      help="Customize how the dishes are displayed on your receipts. Useful for cutting down on un-needed information. For example, cooks don't need to know the cost of the dish">
                      <ScrollList height={null}>
                        <FastField
                          name="receipt.dishes.enabled"
                          render={({ field }: any) => (
                            <ScrollListItem
                              className="flex-line centered"
                              onClick={() => setFieldValue("receipt.dishes.enabled", !field.value)}>
                              <Switch
                                id="customization_dishes_enabled"
                                checked={field.value}
                                onChange={(e) => setFieldValue("receipt.dishes.enabled", e.target.checked)}/>
                              <p className="m-l-3">Show Dishes</p>
                            </ScrollListItem>
                          )}
                        />
                        <FastField
                          name="receipt.dishes.prices"
                          render={({ field }: any) => (
                            <ScrollListItem
                              className="flex-line centered"
                              onClick={() => setFieldValue("receipt.dishes.prices", !field.value)}>
                              <Switch
                                id="customization_dishes_prices"
                                checked={field.value}
                                onChange={(e) => setFieldValue("receipt.dishes.prices", e.target.checked)}/>
                              <p className="m-l-3">Show Dish Prices</p>
                            </ScrollListItem>
                          )}
                        />
                        <FastField
                          name="receipt.dishes.option_prices"
                          render={({ field }: any) => (
                            <ScrollListItem
                              className="flex-line centered"
                              onClick={() => setFieldValue("receipt.dishes.option_prices", !field.value)}>
                              <Switch
                                id="customization_dishes_option_prices"
                                checked={field.value}
                                onChange={(e) => setFieldValue("receipt.dishes.option_prices", e.target.checked)}/>
                              <p className="m-l-3">Show Dish Option-set Prices</p>
                            </ScrollListItem>
                          )}
                        />
                        <FastField
                          name="receipt.dishes.combo_choice_names"
                          render={({ field }: any) => (
                            <ScrollListItem
                              className="flex-line centered"
                              onClick={() => setFieldValue("receipt.dishes.combo_choice_names", !field.value)}>
                              <Switch
                                id="customization_dishes_combo_choice_names"
                                checked={field.value}
                                onChange={(e) => setFieldValue("receipt.dishes.combo_choice_names", e.target.checked)}/>
                              <p className="m-l-3">Show Combo Dish Choice Headings</p>
                            </ScrollListItem>
                          )}
                        />
                        <FastField
                          name="receipt.dishes.disable_print_name"
                          render={({ field }: any) => (
                            <ScrollListItem
                              className="flex-line centered"
                              onClick={() => setFieldValue("receipt.dishes.disable_print_name", !field.value)}>
                              <Switch
                                id="customization_dishes_disable_print_name"
                                checked={field.value || false}
                                onChange={(e) => setFieldValue("receipt.dishes.disable_print_name", e.target.checked)}/>
                              <p className="m-l-3">Disable Dish Printing Name</p>
                            </ScrollListItem>
                          )}
                        />
                      </ScrollList>
                    </FormGroup>

                    <FastField
                      name="receipt.totals"
                      render={({ field }: any) => (
                        <FormGroup
                          title="Show Order Totals"
                          help="Disabling this will remove the order totals including any discounts or taxes from the bottom of the receipt">
                          <Switch
                            id="customization_receipt_total"
                            checked={field.value}
                            onChange={(e) => setFieldValue("receipt.totals", e.target.checked)}/>
                        </FormGroup>
                      )}
                    />

                  </ModalContent>
                )}

                {tab === "3" && (
                  <ModalContent>
                    <FormGroup
                      optional={true}
                      title="Receipt Header"
                      help="Customize your receipt header with information such as your store name, business number, etc.">
                      <FastField
                        name="header"
                        render={({ field }: any) => (
                          <HTMLEditor
                            id="receipt-header-html"
                            initialValue={field.value}
                            onChange={(val) => setFieldValue("header", val)}/>
                        )}
                      />
                    </FormGroup>
                    <FormGroup
                      optional={true}
                      title="Receipt Footer"
                      help="Customize your receipt footer with information such as promotions or a thank you message">
                      <FastField
                        name="footer"
                        render={({ field }: any) => (
                          <HTMLEditor
                            id="receipt-footer-html"
                            initialValue={field.value}
                            onChange={(val) => setFieldValue("footer", val)}/>
                        )}
                      />
                    </FormGroup>
                  </ModalContent>
                )}

                <ModalContent>
                  {error && <FormGroup error={error}/>}
                  <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white"/>}
                    {!isSubmitting && "Save"}
                  </Button>
                </ModalContent>

              </div>
            );
          }}
        </RestaurantForm>

      </Modal>
    );
  }

}
