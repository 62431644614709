import * as React from "react";
import { FastField } from "formik";
import {MobxComponent} from "../../../../../../../mobx/components";
import {inject, observer} from "mobx-react";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {UI} from "../../../../../../../core/ui";
import {PaymentBaseFields} from "./base";
import { CurrenciesList } from "@lib/common";
import { FormGroup, Button, RotateLoader, Input, Switch, SelectAdv } from "@lib/components";

interface Props {}
interface State {}
type FormValues = T.Models.Restaurant.Payments.PoliPay;

@inject("store") @observer
export class SettingsFormPaymentsPoliPay extends MobxComponent<Props, State> {

  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.payments.poli_pay || {
      enabled: false,
      label: "",
      services: [],
      merchant_id: "",
      auth_code: "",
      currency: "",
    };
  }

  render() {
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.payments.poli_pay = values;
          const update = { $set: { "settings.payments.poli_pay": r.settings.payments.poli_pay } };
          return { r, update };
        }}
        validators={{}}
        initialValues={this.initialValues}
        onSuccess={() => {}}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <div className="p-4">

              <FastField
                name="enabled"
                render={({ field }: any) => (
                  <FormGroup
                    title="Enabled"
                    help="Allows customers to pay using this method">
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={(e) => setFieldValue("enabled", e.target.checked)}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="merchant_id"
                render={({ field }: any) => (
                  <FormGroup
                    title="Merchant Code"
                    help="Your unique POLi Pay merchant code sent to you via email when you register with POLi Pay">
                    <Input type="text" {...field} autoSave="false" autoCorrect="false"/>
                  </FormGroup>
                )}
              />

              <FastField
                name="auth_code"
                render={({ field }: any) => (
                  <FormGroup
                    title="Authentication Code"
                    help="Your unique POLi Pay authentication code sent to you via email when you register with POLi Pay">
                    <Input type="text" {...field} autoSave="false" autoCorrect="false"/>
                  </FormGroup>
                )}
              />

              <FastField
                name="currency"
                render={({ field }: any) => (
                  <FormGroup
                    title="Currency"
                    help="Select the currency you would like to accept POLi Pay charges in. Make sure you are authorized to accept charges in this currency and that it is supported">
                    <SelectAdv
                      type="single"
                      options={CurrenciesList}
                      value={field.value}
                      onChange={(code: string) => setFieldValue("currency", code)}
                    />
                  </FormGroup>
                )}
              />

              <PaymentBaseFields
                defaultLabel="POLi Pay"
                setFieldValue={setFieldValue}
              />

              {error && <FormGroup error={error}/>}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white"/>}
                {!isSubmitting && "Save"}
              </Button>

            </div>
          );
        }}
      </RestaurantForm>
    );
  }

}
