import * as React from "react";
import { SettingsSection } from "../layout/section";
import { SettingsSectionBlock } from "../layout/block";
import { SettingsFormDesignThemes } from "../forms/design/themes";
import { SettingsFormColors } from "../forms/design/colors";
import { SettingsFormFonts } from "../forms/design/fonts";
import { SettingsFormDesignTopnav } from "../forms/design/topnav";
import { SettingsFormDesignHeader } from "../forms/design/header";
import { SettingsFormDesignMenus } from "../forms/design/menus";
import { SettingsFormDesignFooter } from "../forms/design/footer";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../../mobx/components";
import { toJS } from "mobx";
import ObjectHash from "node-object-hash";
import {SettingsFormDomain} from "../forms/website/domain";
import {SettingsFormWebsiteFavicon} from "../forms/website/favicon";
import {SettingsFormSEO} from "../forms/website/seo";
import {SettingsFormSocialLinks} from "../forms/website/social_links";
import {SettingsSectionHeading} from "../layout/heading";
import {SettingsFormInjections} from "../forms/website/injections";
import {WebsiteFormMisc} from "../forms/website/misc";
import {FaMobileAlt, FaDesktop, FaTabletAlt, FaArrowRight, FaArrowCircleLeft} from "react-icons/fa";
import { Tooltip, styled } from "@lib/components";

const hasher = ObjectHash();

interface Props {

}
interface State {
  active: string;
  design_mode_active: boolean;
  preview_width: number;
}

const DesignModeWrapper = styled("div")`
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 14;
  overflow-x: auto;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 5px;
    max-height: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
`;
const DesignModeSettings = styled("div")<{ hideScroll: boolean }>`
  background: #f8f8f8;
  max-width: 340px;
  min-width: 320px;
  width: 100%;
  overflow-y: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  ::-webkit-scrollbar {
    width: 5px;
    max-height: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
`;
const DesignModeIframe = styled("div")`
  z-index: -1;
  background: white;
  width: 100%;
  min-width: 380px;
`;

@inject("store") @observer
export class RestaurantSettingsWebsite extends MobxComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      active: "",
      design_mode_active: false,
      preview_width: -1,
    };
  }

  setActive = (active: string) => {
    if (this.state.active === active)
      this.setState({ active: "" });
    else
      this.setState({ active });
  }

  setDesignModeActive = (design_mode_active: boolean) => {
    this.setState({
      design_mode_active,
      active: "",
    });
  }

  setPreviewWidth = (preview_width: number) => {
    this.setState({
      preview_width,
    });
  }

  render() {

    const { active, design_mode_active, preview_width } = this.state;

    const r = this.injected.store.restaurant!;
    const { storeURL } = this.injected.store;

    const settings = (
      <>

        <SettingsSectionBlock
          name="Themes"
          active={active === "Themes"}
          onClick={() => this.setActive("Themes")}>
          <SettingsFormDesignThemes/>
        </SettingsSectionBlock>

        <SettingsSectionBlock
          name="Colors"
          active={active === "Colors"}
          onClick={() => this.setActive("Colors")}>
          <SettingsFormColors/>
        </SettingsSectionBlock>
        <SettingsSectionBlock
          name="Fonts"
          active={active === "Fonts"}
          onClick={() => this.setActive("Fonts")}>
          <SettingsFormFonts/>
        </SettingsSectionBlock>
        <SettingsSectionBlock
          name="Top Nav Bar"
          active={active === "Top Nav Bar"}
          onClick={() => this.setActive("Top Nav Bar")}>
          <SettingsFormDesignTopnav/>
        </SettingsSectionBlock>
        <SettingsSectionBlock
          name="Header"
          active={active === "Header"}
          onClick={() => this.setActive("Header")}>
          <SettingsFormDesignHeader/>
        </SettingsSectionBlock>
        <SettingsSectionBlock
          name="Menus"
          active={active === "Menus"}
          onClick={() => this.setActive("Menus")}>
          <SettingsFormDesignMenus/>
        </SettingsSectionBlock>
        <SettingsSectionBlock
          name="Footer"
          active={active === "Footer"}
          onClick={() => this.setActive("Footer")}>
          <SettingsFormDesignFooter/>
        </SettingsSectionBlock>

        <SettingsSectionHeading>
          <p>Additional Settings</p>
        </SettingsSectionHeading>

        <SettingsSectionBlock
          name="Domain"
          active={active === "Domain"}
          onClick={() => this.setActive("Domain")}>
          <SettingsFormDomain/>
        </SettingsSectionBlock>
        <SettingsSectionBlock
          name="Favicon"
          active={active === "Favicon"}
          onClick={() => this.setActive("Favicon")}>
          <SettingsFormWebsiteFavicon/>
        </SettingsSectionBlock>
        <SettingsSectionBlock
          name="Search Engine Optimization"
          active={active === "Search Engine Optimization"}
          onClick={() => this.setActive("Search Engine Optimization")}>
          <SettingsFormSEO/>
        </SettingsSectionBlock>
        <SettingsSectionBlock
          name="Social Media Links"
          active={active === "Social Media Links"}
          onClick={() => this.setActive("Social Media Links")}>
          <SettingsFormSocialLinks/>
        </SettingsSectionBlock>
        <SettingsSectionBlock
          name="Custom CSS & Javascript"
          active={active === "Custom CSS & Javascript"}
          onClick={() => this.setActive("Custom CSS & Javascript")}>
          <SettingsFormInjections/>
        </SettingsSectionBlock>
        <SettingsSectionBlock
          name="Misc."
          active={active === "Misc. Website"}
          onClick={() => this.setActive("Misc. Website")}>
          <WebsiteFormMisc/>
        </SettingsSectionBlock>

      </>
    );

    const website = {
      subdomain: toJS(r.subdomain),
      domain: toJS(r.domain),
      colors: toJS(r.website.colors),
      fonts: toJS(r.website.fonts),
      sections: toJS(r.website.sections),
      inject: toJS(r.website.inject),
      social_links: toJS(r.website.social_links),
      map_zoom: toJS(r.website.map_zoom),
      bottom_cart_button: toJS(r.website.bottom_cart_button),
      contact_form_email: toJS(r.website.contact_form_email),
    };

    const hash = hasher.hash(website);

    return (
      <>

        {!design_mode_active && (
          <SettingsSection>
            <SettingsSectionHeading className="flex-l-r-center">
              <p>Design</p>
              <div className="flex-line centered cursor" onClick={() => this.setDesignModeActive(true)}>
                <p className="underline">Open Design Mode</p>
                <FaArrowRight className="m-l-2"/>
              </div>
            </SettingsSectionHeading>
            {settings}
          </SettingsSection>
        )}

        {design_mode_active && (
          <DesignModeWrapper>
            <DesignModeSettings hideScroll={true}>
              <div className="flex-l-r-center p-4 bg-white">
                <div className="flex-line centered cursor" onClick={() => this.setDesignModeActive(false)}>
                  <FaArrowCircleLeft/>
                  <p className="big font-semi-bold m-l-2">Go Back</p>
                </div>
                <div className="flex-line centered cursor">
                  <Tooltip text="Mobile" width={60} position={"bottom"}>
                    <div className="border-white-10 p-2" onClick={() => this.setPreviewWidth(380)}>
                      <FaMobileAlt/>
                    </div>
                  </Tooltip>
                  <Tooltip text="Tablet" width={60} position={"bottom"}>
                    <div className="border-white-10 p-2" onClick={() => this.setPreviewWidth(768)}>
                      <FaTabletAlt/>
                    </div>
                  </Tooltip>
                  <Tooltip text="Full" width={60} position={"bottom"}>
                    <div className="border-white-10 p-2" onClick={() => this.setPreviewWidth(-1)}>
                      <FaDesktop/>
                    </div>
                  </Tooltip>
                </div>
              </div>
              <SettingsSection>
                <SettingsSectionHeading className="border-white-t-10">
                  <p>Design</p>
                </SettingsSectionHeading>
                {settings}
              </SettingsSection>
            </DesignModeSettings>
            <DesignModeIframe style={{minWidth: preview_width === -1 ? "auto" : `${preview_width}px`}}>
              <iframe
                onLoadStart={() => console.log("load start")}
                onLoad={() => console.log("load end")}
                className="bsm-2"
                src={`${storeURL}?h=${hash}`}
                width={preview_width === -1 ? "100%" : preview_width}
                height="100%"
              />
            </DesignModeIframe>
          </DesignModeWrapper>
        )}
      </>

    );
  }

}
