import {FormikActions, FormikProps} from "formik";
import cloneDeep from "lodash/cloneDeep";
import {UI} from "./ui";
import {logger} from "@lib/common";
import {RootStore} from "../mobx/store";



export interface FormFieldValidators<T> {
  [key: string]: (values: T) => void | {
    [key: string]: string;
  };
}

interface SubmitRestaurantArgs<T> {
  store: RootStore;
  r: T.Models.Restaurant.Schema;
  form: FormikActions<T>;
  setError: (err: string | null) => void;
  setRestaurant: (r: T.Models.Restaurant.Schema) => void;
  process: (r: T.Models.Restaurant.Schema) => Promise<{
    r: T.Models.Restaurant.Schema;
    update: T.ObjectAny;
  }>;
  onSuccess?: (r: T.Models.Restaurant.Schema) => void;
  onError?: () => void;
  onFail?: () => void;
  onSuccessMessage?: string;
  onErrorMessage?: string;
}

interface SubmitWebsiteArgs<T> {
  store: RootStore;
  w: T.Models.Website.Schema;
  form: FormikActions<T>;
  setError: (err: string | null) => void;
  setWebsite: (w: T.Models.Website.Schema) => void;
  process: (w: T.Models.Website.Schema) => Promise<{
    w: T.Models.Website.Schema;
    update: T.ObjectAny;
  }>;
  onSuccess?: (w: T.Models.Website.Schema) => void;
  onError?: () => void;
  onFail?: () => void;
  onSuccessMessage?: string;
  onErrorMessage?: string;
}

export const FormHelpers = {

  async submit_restaurant<T>(args: SubmitRestaurantArgs<T>) {
    const { store, form, setError, setRestaurant, process, onSuccess, onError, onFail, onSuccessMessage, onErrorMessage } = args;
    try {
      const r = cloneDeep(args.r);
      setError(null);

      const processResult = await process(r);

      const apiResult = await store.api.restaurant_update({
        _id: r._id,
        update: processResult.update,
      });

      if (apiResult.outcome) {
        setError(apiResult.message);
        if (onFail) {
          onFail();
        }
      }
      else {
        UI.notification.success(onSuccessMessage || "Settings updated");
        setRestaurant(r);
        if (onSuccess) {
          onSuccess(r);
        }
      }
    }
    catch (e) {
      logger.captureException(e);
      setError(onErrorMessage || "Error updating settings, please try again or contact us");
      if (onError) {
        onError();
      }
    }
    finally {
      form.setSubmitting(false);
    }
  },

  async submit_website<T>(args: SubmitWebsiteArgs<T>) {
    const { store, form, setError, setWebsite, process, onSuccess, onError, onFail, onSuccessMessage, onErrorMessage } = args;
    try {
      const w = cloneDeep(args.w);
      setError(null);

      const processResult = await process(w);

      const apiResult = await store.api.website_update({
        _id: w._id,
        update: processResult.update,
      });

      if (apiResult.outcome) {
        setError(apiResult.message);
        if (onFail) {
          onFail();
        }
      }
      else {
        UI.notification.success(onSuccessMessage || "Settings updated");
        setWebsite(w);
        if (onSuccess) {
          onSuccess(w);
        }
      }
    }
    catch (e) {
      logger.captureException(e);
      setError(onErrorMessage || "Error updating settings, please try again or contact us");
      if (onError) {
        onError();
      }
    }
    finally {
      form.setSubmitting(false);
    }
  },

  validate<T>(values: T, validators: FormFieldValidators<T>) {

    let errors: { [key in keyof T]?: string } = {};

    for (const validator in validators) {
      if (validators.hasOwnProperty(validator)) {
        const validate = validators[validator];
        if (validate) {
          errors = { ...errors, ...validate(values) };
        }
      }
    }

    let isError = false;

    for (const key in errors) {
      if (errors.hasOwnProperty(key) && errors[key as keyof typeof errors]) {
        isError = true;
        break;
      }
    }

    return { errors, isError };
  },

  error<T>(form: FormikProps<T>, field: string): string | undefined | null {
    // @ts-ignore
    if (form.submitCount > 0 && form.errors[field]) {
      // @ts-ignore
      return form.errors[field];
    }
    return null;
  },

};
