import * as React from "react";
import cn from "classnames";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../mobx/components";
import {withTranslation, WithTranslation} from "react-i18next";
import { RestaurantOrdersList } from "./list";
import { RestaurantOrdersBoard } from "./board";
import { RestaurantOrderModal } from "./modal";
import {FaList, FaGripVertical, FaPlus, FaMinus, FaCog, FaQuestionCircle, FaSync, FaEye} from "react-icons/fa";
import { Tooltip, Button } from "@lib/components";
import {runInAction} from "mobx";
import {RestaurantOrdersQuickSettings} from "./quick-settings";
import localStore from "store";

interface Props extends WithTranslation {}
interface State {
  quick_settings_active: boolean;
}

@inject("store") @observer
class RestaurantOrdersClass extends MobxComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      quick_settings_active: false,
    };
    const { store } = this.injected;
    const rov = store.restrictions._.restaurantOrderViews;
    const layout = store.ordersView.layout;
    if (layout === 0 && rov.indexOf("board") === -1) {
      this.changeView(1);
    }
    else if (layout === 1 && rov.indexOf("list") === -1) {
      this.changeView(0);
    }
  }

  changeView = (layout: 0 | 1) => {
    runInAction(() => {

      const { store } = this.injected;

      if (store.ordersView.layout === layout) {
        return;
      }

      store.ordersView.layout = layout;
      store.ordersBoard.lists = {};
      store.orders.items = [];
      store.orders.count = 0;

      const settings = localStore.get("store-ordersView") || {};
      settings.layout = layout;
      localStore.set("store-ordersView", settings);

    });
  }

  changeBoardLayout = (size: 1 | -1) => {
    runInAction(() => {
      const {store} = this.injected;
      const current = store.ordersView.boardSize;
      const next = current + size;
      if (next > 5 || next < 2) {
        return;
      }
      if (!store.restaurant!.settings.services.delivery.enabled && next > 4) {
        return;
      }

      store.ordersView.boardSize = next as 2 | 3 | 4 | 5;

      const settings = localStore.get("store-ordersView") || {};
      settings.boardSize = next.toString();
      localStore.set("store-ordersView", settings);

    });
  }

  changeHideUnconfirmed = () => {
    runInAction(() => {
      const {store} = this.injected;
      const val = !store.ordersView.hideUnconfirmed;
      store.ordersView.hideUnconfirmed = val;
      const settings = localStore.get("store-ordersView") || {};
      settings.hideUnconfirmed = val;
      localStore.set("store-ordersView", settings);

    });
  }

  render() {

    const { store } = this.injected;

    const { restrictions } = store;

    const { layout, boardSize, hideUnconfirmed } = store.ordersView;

    let actualBoardSize = boardSize - (hideUnconfirmed ? 1 : 0);
    actualBoardSize = actualBoardSize < 2 ? 2 : actualBoardSize;
    const containerStyle = layout === 0 ? { maxWidth: `${(actualBoardSize * 280) + ((actualBoardSize - 1) * 20)}px` } : {};
    const containerClass = layout === 0 ? "center" : "";

    let quickSettingsEnabled = false;
    if (restrictions._.restaurantSettingsEnabled) {
      const sd = restrictions.restaurant.settings_detail;
      if (sd && sd.services) {
        quickSettingsEnabled = true;
      }
      else {
        quickSettingsEnabled = !!restrictions.restaurant.settings;
      }
    }

    return (
      <div style={containerStyle} className={containerClass}>

        <div className="m-b-6 flex-l-r-center" style={{ minWidth: "450px" }}>

          <div>
            <h1 className="">Orders</h1>
          </div>

          <div className="flex-line centered">

            {restrictions._.restaurantOrderViews.length > 1 && (
              <div>
                <Tooltip text={"Board"} width={60} position="top">
                  <Button
                    size="xxs"
                    color={layout === 0 ? "primary" : "primary-inverse"}
                    className="p-lr-1 no-round-top-right no-round-bottom-right"
                    onClick={() => this.changeView(0)}>
                    <FaGripVertical/>
                  </Button>
                </Tooltip>
                <Tooltip text={"List"} width={45} position="top">
                  <Button
                    size="xxs"
                    color={layout === 1 ? "primary" : "primary-inverse"}
                    className="p-lr-1 no-round-top-left no-round-bottom-left"
                    onClick={() => this.changeView(1)}>
                    <FaList/>
                  </Button>
                </Tooltip>
              </div>
            )}

            {layout === 0 && (
              <div className="m-l-2">
                <Tooltip text={"Compress Layout"} width={130} position="top">
                  <Button
                    size="xxs"
                    color={"primary-inverse"}
                    className="p-lr-1 no-round-right no-border-r"
                    onClick={() => this.changeBoardLayout(-1)}>
                    <FaMinus/>
                  </Button>
                </Tooltip>
                <Tooltip text={"Expand Layout"} width={110} position="top">
                  <Button
                    size="xxs"
                    color={"primary-inverse"}
                    className="p-lr-1 no-round no-border-r"
                    onClick={() => this.changeBoardLayout(1)}>
                    <FaPlus/>
                  </Button>
                </Tooltip>
                <Tooltip text={hideUnconfirmed ? "Show Un-confirmed" : "Hide Un-confirmed"} width={140} position="top">
                  <Button
                    size="xxs"
                    color={"primary-inverse"}
                    className="p-lr-1 no-round-left"
                    onClick={() => this.changeHideUnconfirmed()}>
                    <FaEye/>
                  </Button>
                </Tooltip>
              </div>
            )}

            <div className="m-l-2">
              {quickSettingsEnabled && (
                <Tooltip text={"Quick Settings"} width={110} position="top">
                  <Button
                    size="xxs"
                    color={"primary-inverse"}
                    className={"p-lr-1 no-round-right no-border-r"}
                    onClick={() => this.setState({ quick_settings_active: true })}>
                    <FaCog/>
                  </Button>
                </Tooltip>
              )}
              {layout === 0 && (
                <Tooltip text={"Refresh"} width={65} position="top">
                  <Button
                    size="xxs"
                    color={"primary-inverse"}
                    className={cn("p-lr-1", "no-round-right no-border-r", quickSettingsEnabled && "no-round-left")}
                    onClick={store.service.order.get_board}>
                    <FaSync/>
                  </Button>
                </Tooltip>
              )}
              <Tooltip text={"Help"} width={50} position={"top"}>
                <a target="_blank" href="https://support.booknorder.co.nz/getting-started/managing-orders-and-bookings">
                  <Button size="xxs" color="primary-inverse" className="p-lr-1 no-round-left">
                    <FaQuestionCircle/>
                  </Button>
                </a>
              </Tooltip>
            </div>

          </div>

        </div>

        {layout === 0 && <RestaurantOrdersBoard/>}

        {layout === 1 && <RestaurantOrdersList/>}

        <RestaurantOrderModal/>

        <RestaurantOrdersQuickSettings
          active={this.state.quick_settings_active}
          close={() => this.setState({ quick_settings_active: false })}
        />

      </div>
    );
  }

}

export const RestaurantOrders = withTranslation()(RestaurantOrdersClass);
