import * as React from "react";
import shortid from "shortid";
import { FaTrashAlt, FaCopy } from "react-icons/fa";
import { SettingsFeesForm } from "./fees.form";
import { cloneDeepSafe } from "@lib/common";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../../../mobx/components";
import { Button, RotateLoader, Modal, ModalContent, Tooltip, ItemListItem, ItemListContent, ItemListWrapper } from "@lib/components";

interface Props {}
interface State {
  panel_id: string | null;
  copy_index: number | null;
  copy_loading: boolean;
  remove_index: number | null;
  remove_loading: boolean;
}

@inject("store") @observer
export class SettingsFees extends MobxComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      panel_id: null,
      copy_index: null,
      copy_loading: false,
      remove_index: null,
      remove_loading: false,
    };
  }

  setPanelID = (panel_id: string | null) => {
    this.setState({ panel_id });
  }

  create = (fee: T.Models.Restaurant.Services.Fee | null): T.Models.Restaurant.Services.Fee => {
    if (fee === null) {
      const _id = shortid.generate();
      return {
        _id: _id,
        name: "",
        fixed_value: 0,
        percent_value: 0,
        match_condition: "any",
        services: [],
        payments: [],
        order_times: [],
        hours: [],
        disabled: false,
      };
    }
    return fee;
  }
  copy = async (index: number | null) => {
    if (index === null) return;
    await this.saveRestaurant({
      successMsg: "Fee copied",
      process: (r) => {
        const item = cloneDeepSafe(r.settings.fees[index]);
        item._id = shortid.generate();
        item.name = `${item.name} - Copy`;
        r.settings.fees.push(item);
        return {
          update: { $set: { "settings.fees": r.settings.fees } },
        };
      },
      before: () => this.setState({ copy_loading: true }),
      onSuccess: () => this.setState({ copy_loading: false, copy_index: null }),
      onFail: () => this.setState({ copy_loading: false }),
      onError: () => this.setState({ copy_loading: false }),
    });
  }
  remove = async (index: number | null) => {
    if (index === null) return;
    await this.saveRestaurant({
      successMsg: "Fee deleted",
      process: (r) => {
        r.settings.fees.splice(index, 1);
        return {
          update: { $set: { "settings.fees": r.settings.fees } },
        };
      },
      before: () => this.setState({ remove_loading: true }),
      onSuccess: () => this.setState({ remove_loading: false, remove_index: null }),
      onFail: () => this.setState({ remove_loading: false }),
      onError: () => this.setState({ remove_loading: false }),
    });
  }

  list = () => {
    const r = this.injected.store.restaurant!;
    if (r.settings.fees.length === 0)
      return null;

    return (
      <div className="m-t-4">
        <ItemListWrapper>
          <ItemListContent className="border-white-10">
            {r.settings.fees.map((item, i) => (
              <ItemListItem key={i} onClick={() => this.setPanelID(item._id)}>
                <div className="p-lr-4 p-tb-2 flex-line centered">
                  <p className="big font-semi-bold">{item.name}</p>
                </div>
                <div>
                  <Tooltip text="Delete" width={65} position="top" disable={i !== 0}>
                    <Button
                      type="button"
                      color="white"
                      className="no-round no-border no-shadow"
                      paddinglr={10}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ remove_index: i });
                      }}>
                      <FaTrashAlt/>
                    </Button>
                  </Tooltip>
                  <Tooltip text="Copy" width={65} position="top" disable={i !== 0}>
                    <Button
                      type="button"
                      color="white"
                      className="no-round no-border no-shadow"
                      paddinglr={10}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ copy_index: i });
                      }}>
                      <FaCopy/>
                    </Button>
                  </Tooltip>
                </div>
              </ItemListItem>
            ))}
          </ItemListContent>
        </ItemListWrapper>
      </div>
    );

  }
  panel = () => {
    const r = this.injected.store.restaurant!;
    const item_id = this.state.panel_id;
    const active = item_id !== null;

    let initialValues = null;
    if (active) {
      if (item_id === "") {
        initialValues = this.create(null);
      }
      else {
        const item = r.settings.fees.find((p) => p._id === item_id);
        initialValues = this.create(item || null);
      }
    }
    return (
      <SettingsFeesForm
        type={item_id === "" ? "create" : "edit"}
        initialValues={initialValues}
        close={() => this.setPanelID(null)}
      />
    );
  }
  modal_copy = () => {
    const r = this.injected.store.restaurant!;
    const { copy_index, copy_loading } = this.state;
    const item = copy_index !== null ? r.settings.fees[copy_index] : null;
    return (
      <Modal
        width="sm"
        close={() => this.setState({copy_index: null})}
        closeButton={false}
        active={!!item}>
        <ModalContent className="flex-l-r-center">
          <h4 className="">Copy Fee</h4>
          <p className="big underline">{item && item.name}</p>
        </ModalContent>
        <ModalContent className="flex-right">
          <Button type="button" className="m-r-2 width100 max100px" onClick={() => this.setState({copy_index: null})}>Cancel</Button>
          <Button type="button" className="width100 max100px" color="primary" onClick={() => this.copy(copy_index)}>
            {copy_loading && <RotateLoader size={2} color="white"/>}
            {!copy_loading && "Copy"}
          </Button>
        </ModalContent>
      </Modal>
    );
  }
  modal_delete = () => {
    const r = this.injected.store.restaurant!;
    const { remove_index, remove_loading } = this.state;
    const item = remove_index !== null ? r.settings.fees[remove_index] : null;
    return (
      <Modal
        width="sm"
        close={() => this.setState({remove_index: null})}
        closeButton={false}
        active={remove_index !== null}>
        <ModalContent className="flex-l-r-center">
          <h4 className="">Delete Fee</h4>
          <p className="big underline">{item && item.name}</p>
        </ModalContent>
        <ModalContent>
          <p className="lhp big">Are you sure you want to delete this fee. Once deleted, it cannot be restored</p>
        </ModalContent>
        <ModalContent className="flex-right">
          <Button type="button" className="m-r-2 width100 max100px" onClick={() => this.setState({remove_index: null})}>Cancel</Button>
          <Button type="button" className="width100 max100px" color="primary" onClick={() => this.remove(remove_index)}>
            {remove_loading && <RotateLoader size={2} color="white"/>}
            {!remove_loading && "Delete"}
          </Button>
        </ModalContent>
      </Modal>
    );
  }

  render() {
    const printer_id = this.state.panel_id;
    const active = printer_id !== null;
    return (
      <div className="p-4">
        <p className="lhp m-b-3">Conditional fees can be used to add additional charges if certain conditions are matched</p>
        <Button
          color="primary"
          size="xs"
          onClick={() => active ?
            this.setState({ panel_id: null }) :
            this.setState({ panel_id: "" })}>
          Create Fee
        </Button>
        {this.list()}
        {this.panel()}
        {this.modal_copy()}
        {this.modal_delete()}
      </div>
    );
  }

}
