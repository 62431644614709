import * as React from "react";
import {FastField} from "formik";
import {Locales, Currencies, CurrenciesList, TimezonesList} from "@lib/common";
import {inject, observer} from "mobx-react";
import {MobxComponent} from "../../../../../../../mobx/components";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {UI} from "../../../../../../../core/ui";
import {FormGroup, Button, RotateLoader, Input, Switch, FieldTaxes, SelectAdv, ButtonGroup} from "@lib/components";

interface Props {}
interface State {}
interface FormValues {
  name: string;
  locale: string;
  timezone: string;
  formats: {
    date: string;
    time: string;
  };
  currency: T.Models.Restaurant.Schema["settings"]["region"]["currency"];
  taxes: T.Business.Taxes;
  tax_in_prices: boolean;
}

@inject("store") @observer
export class SettingsFormGeneral extends MobxComponent<Props, State> {

  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    const r = this.injected.store.restaurant!;
    this.state = {};
    this.initialValues = {
      name: r.name,
      locale: r.settings.region.locale,
      timezone: r.settings.region.timezone,
      formats: r.settings.region.formats || {
        date: "DD/MM/YYYY",
        time: "h:mm a", // HH:mm
      },
      currency: r.settings.region.currency,
      taxes: r.settings.region.tax.rates,
      tax_in_prices: r.settings.region.tax.in_prices,
    };
  }

  render() {
    return (
      <RestaurantForm<FormValues>
        initialValues={this.initialValues}
        submit={async (r, values) => {
          values.formats.date = values.formats.date.toUpperCase();
          r.name = values.name;
          r.settings.region.locale = values.locale;
          r.settings.region.timezone = values.timezone;
          r.settings.region.formats = values.formats;
          r.settings.region.currency = values.currency;
          r.settings.region.tax.rates = values.taxes;
          r.settings.region.tax.in_prices = values.tax_in_prices;
          const update = {
            $set: {
              "name": values.name,
              "settings.region.locale": values.locale,
              "settings.region.timezone": values.timezone,
              "settings.region.formats": values.formats,
              "settings.region.currency": values.currency,
              "settings.region.tax.rates": values.taxes,
              "settings.region.tax.in_prices": values.tax_in_prices,
            },
          };
          return { r, update };
        }}
        validators={{
          name: (values) => {
            if (!values.name)
              return { name: "Required - please enter a name for your store location" };
            return undefined;
          },
          locale: (values) => {
            if (!values.locale)
              return { locale: "Required - search and select your locale" };
            return undefined;
          },
          timezone: (values) => {
            if (!values.timezone)
              return { timezone: "Required - search and select your timezone" };
            return undefined;
          },
          currency: (values) => {
            if (!values.currency)
              return { currency: "Required - search and select your currency" };
            return undefined;
          },
          taxes: (values) => {
            if (values.taxes) {
              for (const tax of values.taxes) {
                if (!tax.rate || !tax.name) {
                  return { taxes: `One of your taxes is missing a name or tax rate. Please ensure all the fields are completed` };
                }
              }
            }
            return undefined;
          },
        }}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error, getFieldError }) => {
          const { isSubmitting, setFieldValue, setFieldTouched } = form;
          return (
            <div className="p-4">

              <FastField
                name="name"
                render={({ field }: any) => (
                  <FormGroup
                    title="Name"
                    help="The name of this restaurant location, used throughout the system"
                    error={getFieldError(form, "name")}>
                    <Input type="text" {...field} required={true}/>
                  </FormGroup>
                )}
              />

              <FastField
                name="locale"
                render={({ field }: any) => (
                  <FormGroup
                    title="System Locale"
                    help="Determines your default store language and how certain dates / currencies are formatted"
                    error={getFieldError(form, "locale")}>
                    <SelectAdv
                      type="single"
                      options={Locales}
                      value={field.value}
                      onChange={(option: string) => setFieldValue("locale", option)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="timezone"
                render={({ field }: any) => (
                  <FormGroup
                    title="Timezone"
                    help="Select the timezone this restaurant location resides in to ensure accurate timings"
                    error={getFieldError(form, "timezone")}>
                    <SelectAdv
                      type="single"
                      options={TimezonesList.map((t) => ({ label: t, value: t }))}
                      value={field.value}
                      onChange={(option: string) => setFieldValue("timezone", option)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="formats.time"
                render={({ field }: any) => (
                  <FormGroup
                    title="Time Formatting"
                    help="Determines how times are formatted for this store">
                    <ButtonGroup
                      size={"xs"}
                      selected={field.value}
                      options={[
                        { value: "h:mm a", name: "12 Hour (9:00pm)" },
                        { value: "HH:mm", name: "24 Hour (21:00)" },
                      ]}
                      onSelect={(v) => setFieldValue("formats.time", v.value)}
                      width={150}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="formats.date"
                render={({ field }: any) => (
                  <FormGroup
                    title="Date Formatting"
                    help="Determines how dates are formatted. DD is the day, MM is the month and YYYY is year. Ensure characters are in uppercase. From example, DD/MM/YYYY will display dates as 21/03/2019">
                    <Input
                      type="text"
                      required={true}
                      placeholder="DD-MM-YYYY or MM/DD/YYYY"
                      {...field}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="currency"
                render={({ field }: any) => (
                  <FormGroup
                    title="Currency"
                    help="Select your store currency. This affects financial calculations and how your prices are displayed"
                    error={getFieldError(form, "currency")}>
                    <SelectAdv
                      type="single"
                      options={CurrenciesList}
                      value={field.value ? field.value.code : null}
                      onChange={(option: string) => {
                        const currency = Currencies[option as keyof typeof Currencies];
                        setFieldValue("currency", {
                          code: currency.code,
                          symbol: currency.symbol_native,
                          precision: currency.decimal_digits,
                        });
                      }}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="taxes"
                render={({ field }: any) => (
                  <FormGroup
                    title="Sales Taxes"
                    help="Taxes are calculated from top to bottom if compounded"
                    optional={true}
                    error={getFieldError(form, "taxes")}>
                    <div className="m-t-2">
                      <FieldTaxes
                        taxes={field.value}
                        onChange={(taxes) => {
                          if (!field.value || field.value.length === 0) {
                            setFieldTouched("taxes", false);
                          }
                          setFieldValue("taxes", taxes);
                        }}/>
                    </div>
                  </FormGroup>
                )}
              />

              <FastField
                name="tax_in_prices"
                render={({ field }: any) => (
                  <FormGroup
                    title="Tax In Prices"
                    help="Enable this if all your store prices are already inclusive of tax. If disabled, taxes will be calculated and added to an order's total cost">
                    <Switch
                      id="tax_in_prices"
                      checked={field.value}
                      onChange={(e) => setFieldValue("tax_in_prices", e.target.checked)}/>
                  </FormGroup>
                )}
              />

              {error && <FormGroup error={error}/>}

              <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <RotateLoader size={2} color="white"/>}
                {!isSubmitting && "Save"}
              </Button>

            </div>
          );
        }}
      </RestaurantForm>
    );
  }

}
