import * as React from "react";
import {FastField} from "formik";
import { ServiceOrderTimeFields, ServiceAutoStatusFields, ServiceHoursField } from "./base";
import {MobxComponent} from "../../../../../../../mobx/components";
import {inject, observer} from "mobx-react";
import {RestaurantForm} from "../../../../../../../mobx/components/restaurant-form";
import {UI} from "../../../../../../../core/ui";
import { FormGroup, Button, RotateLoader, TabSelect, Input, Switch } from "@lib/components";

interface Props {}
interface State {
  tab: string;
}
type FormValues = T.Models.Restaurant.Schema["settings"]["services"]["pickup"];

@inject("store") @observer
export class SettingsFormServicesPickup extends MobxComponent<Props, State> {

  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {
      tab: "0",
    };
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.services.pickup;
  }

  render() {
    const { tab } = this.state;
    return (
      <div>
        <TabSelect
          id="accounts-tab-options"
          className="border-white-tb-10"
          hasBorder={true}
          screenWidth={this.injected.store.view.screen_width}
          onChange={(v) => this.setState({ tab: v.value })}
          value={tab}
          values={[
            { label: "General", value: "0" },
            { label: "Order Timing", value: "1" },
            { label: "Available Hours", value: "2" },
            { label: "Wait Times & Auto Statuses", value: "3" },
          ]}/>
        <RestaurantForm<FormValues>
          submit={async (r, values) => {
            r.settings.services.pickup = values;
            const update = { $set: { "settings.services.pickup": r.settings.services.pickup } };
            return { r, update };
          }}
          validators={{}}
          initialValues={this.initialValues}
          onSuccess={() => {}}
          onError={() => UI.notification.error("An error occurred")}
          onSuccessMessage="Settings Updated"
          onErrorMessage="">
          {({ form, error, getFieldError }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
              <div className="p-4">

                <FormGroup>
                  {tab === "0" && (
                    <div>
                      <FastField
                        name="enabled"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Enabled"
                            help="Allow customers to place orders that can be collected at your store location">
                            <Switch
                              id="enable-pickup-switch"
                              checked={field.value}
                              onChange={(e) => setFieldValue("enabled", e.target.checked)}/>
                          </FormGroup>
                        )}
                      />
                      <FastField
                        name="notes"
                        render={({ field }: any) => (
                          <FormGroup
                            optional={true}
                            title="Pickup Notes"
                            help="This will be shown to customers if they choose this order method">
                            <Input type="text" {...field}/>
                          </FormGroup>
                        )}
                      />
                    </div>
                  )}
                  {tab === "1" && <ServiceOrderTimeFields service="pickup" form={form}/>}
                  {tab === "2" && <ServiceHoursField service="pickup" error={(field) => getFieldError(form, field) as string | null | undefined}/>}
                  {tab === "3" && <ServiceAutoStatusFields service="pickup" values={form.values}/>}
                </FormGroup>

                {error && <FormGroup error={error}/>}

                <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <RotateLoader size={2} color="white"/>}
                  {!isSubmitting && "Save"}
                </Button>

              </div>
            );
          }}
        </RestaurantForm>
      </div>
    );

  }
}
