import * as React from "react";
import { UI } from "../../../../core/ui";
import { logger, validators } from "@lib/common";
import { Link } from "../../../components/router/Link";
import { BoxSection, Button, Input, FormGroupIcon } from "@lib/components";
import {MobxComponent} from "../../../../mobx/components";
import {inject, observer} from "mobx-react";
import { FaEnvelope, FaLock } from "react-icons/fa";
const no_border = true;

interface Props {
  load: (load: boolean) => void;
}
interface State {
  email?: string;
  password?: string;
}

@inject("store") @observer
export class AuthLoginForm extends MobxComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    // VARS
    e.preventDefault();
    const { store } = this.injected;
    try {
      let email = this.state.email;
      const password = this.state.password;

      // VALIDATE VARS
      if (!email || !password) {
        return UI.notification.error("Please fill out all the fields");
      }

      // VALIDATE EMAIL
      const email_valid = validators.email(email);
      if (email_valid.error) {
        return UI.notification.error("Invalid e-mail address");
      }

      // API CALL
      email = email_valid.value;
      this.props.load(true);
      const data = await store.api.user_login({ email, password });

      // ERRORS
      if (data.outcome) {
        this.props.load(false);
        return UI.notification.error(data.message);
      }
      this.props.load(false);

      const loginPath = store.router.s.query.redirect || "/";
      await store.service.login(data.token, loginPath as string);

    }
    catch (e) {
      logger.captureException(e);
      UI.notification.error("Something went wrong, try again or contact us", { timeout: 6000 });
      this.props.load(false);
    }
    return null;
  }

  onChange = <T extends keyof State>(e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = UI.helpers.handleChange(e);
    this.setState(newState as { [P in T]: State[P]; });
  }

  render() {
    const { onSubmit, onChange } = this;
    return (
      <form onSubmit={onSubmit}>

        <BoxSection>

          <FormGroupIcon icon={<FaEnvelope/>} no_border={no_border}>
            <Input
              type="email"
              name="email"
              placeholder="E-Mail"
              value={this.state.email}
              onChange={onChange}
              required={true}
            />
          </FormGroupIcon>

          <FormGroupIcon icon={<FaLock/>} no_border={no_border}>
            <Input
              type="password"
              name="password"
              placeholder="Password"
              value={this.state.password}
              onChange={onChange}
              required={true}
            />
          </FormGroupIcon>

          <Button color="primary" type="submit" full={true}>
            Login
          </Button>

        </BoxSection>

        <BoxSection className="text-center">
          <p className="m-b-2">Don"t have an account? <Link to="/register">Sign Up</Link></p>
          <p>Forgot your password? <Link to="/reset-password">Reset Password</Link></p>
        </BoxSection>

      </form>
    );
  }

}
